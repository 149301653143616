import React, {useRef} from 'react';

import {useDrag, useDrop} from 'react-dnd';

import {Accordion, AccordionSection} from '../../../../../Accordion';
import {Button} from '../../../../../Button';
import {IconView} from '../../../../../IconView';
import {Input} from '../../../../../Input';
import {Trans} from '../../../../../ScopedTrans';
import {TextArea} from '../../../../../TextArea';
import {Typography} from '../../../../../Typography';
import {View} from '../../../../../View';
import {XStack} from '../../../../../XStack';
import {YStack} from '../../../../../YStack';

export type Props = {
  section: AccordionSection;
  handleTitleChange: (newValue: string, id?: string) => void;
  handleDeleteSection: (id?: string) => void;
  handleContentChange: (newValue: string, id?: string) => void;
  moveItem: (dragIndex: number, hoverIndex: number) => void;
  index: number;
};

interface DragItem {
  index: number;
  id: string;
}

export const ItemTypes = 'ACCORDION_LIST';

export const AccordionSectionContent = ({
  section,
  index,
  handleDeleteSection,
  handleTitleChange,
  handleContentChange,
  moveItem,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop<DragItem>({
    accept: ItemTypes,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref || !ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as {y: number}).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      moveItem(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const [{isDragging}, drag] = useDrag({
    type: ItemTypes,
    item: () => {
      return {id: section.id, index};
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <Accordion
      key={section.id}
      ref={ref}
      defaultActiveSections={index === 0 ? [1] : []}
      variant="outlined"
      containerProps={{padding: '$8', opacity}}
      sections={[
        {
          title: (
            <View flexDirection="row" gap="$2" width="$18" flex={1} justifyContent="space-between" alignItems="center">
              <XStack gap="$2" justifyContent="flex-start" alignItems="center" flex={1}>
                <View ref={drag} hoverStyle={{cursor: 'grab'}}>
                  <IconView icon="DragDropIcon" />
                </View>
                <Typography fontSize="$16" flex={1} maxWidth="90%">
                  {section.title}
                </Typography>
              </XStack>

              <Button
                height="$1"
                hierarchy="tertiary-gray"
                prefix={<IconView icon="Delete02Icon" color="$gray-600" />}
                onPress={(e) => {
                  e.stopPropagation();
                  handleDeleteSection(section.id);
                }}
              />
            </View>
          ),
          content: (
            <YStack gap="$4">
              <YStack gap="$3">
                <Typography>
                  <Trans i18nKey="header" />
                </Typography>
                <Input
                  placeholder={section.title + ''}
                  onChangeText={(value) => handleTitleChange(value, section.id)}
                />
              </YStack>
              <YStack gap="$2">
                <Typography>
                  <Trans i18nKey="content" />
                </Typography>
                <TextArea
                  placeholder={section.content + ''}
                  onChangeText={(value) => handleContentChange(value, section.id)}
                />
              </YStack>
            </YStack>
          ),
        },
      ]}
    />
  );
};
