import React, {ReactNode, useState} from 'react';

import {ColorTokens} from 'tamagui';

import {IconListType} from '../../lib';
import {Button} from '../Button';
import {IconView} from '../IconView';
import {CoreTrans} from '../ScopedTrans';
import {Typography} from '../Typography';
import {View, ViewProps} from '../View';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

type MessageType = {
  color: ColorTokens;
  icon: IconListType;
  backgroundColor: ColorTokens;
};

export const MessageBarType: Record<'info' | 'success' | 'danger' | 'warning', MessageType> = {
  info: {
    color: '$fg-brand-primary',
    icon: 'InformationCircleIcon',
    backgroundColor: '$bg-primary-alt',
  },
  success: {
    color: '$fg-success-primary',
    icon: 'Tick02Icon',
    backgroundColor: '$bg-primary-alt',
  },
  danger: {
    color: '$fg-error-primary',
    icon: 'Alert02Icon',
    backgroundColor: '$bg-primary-alt',
  },
  warning: {
    color: '$fg-warning-primary',
    icon: 'Alert01Icon',
    backgroundColor: '$bg-primary-alt',
  },
} as const;

export type MessageBarProps = ViewProps & {
  title?: string;
  description?: string;
  type?: keyof typeof MessageBarType;
  onAction?: () => void;
  onDismiss?: () => void;
  callToActionLabel?: ReactNode;
  dissmissLabel?: ReactNode;
};

export const MessageBar = ({
  title,
  description,
  type = 'info',
  onAction,
  onDismiss,
  callToActionLabel,
  dissmissLabel,
  ...props
}: MessageBarProps) => {
  const [isDismissed, setIsDismissed] = useState(false);

  const color = MessageBarType[type].color;
  const backgroundColor = MessageBarType[type].backgroundColor;
  const icon = MessageBarType[type].icon;

  const onActionAction = () => {
    onAction?.();
  };

  const onDismissClick = () => {
    setIsDismissed(true);
    onDismiss?.();
  };
  return (
    <View
      display={isDismissed ? 'none' : 'flex'}
      padding="$3"
      borderRadius="$rounded-xl"
      gap="$3"
      borderColor="$border-primary"
      backgroundColor={backgroundColor}
      borderWidth="$0.5"
      {...props}>
      <XStack alignItems="center" gap="$3">
        <View padding={6} alignItems="center" justifyContent="center" borderRadius={1000} backgroundColor={color}>
          <IconView strokeWidth={1.5} size={16} icon={icon} color="white" />
        </View>
        <Typography marginHorizontal="$0.5" color="$text-secondary" fontWeight="600">
          {title}
        </Typography>
      </XStack>
      <YStack gap="$2">
        <Typography color="$text-tertiary" variant="body2" fontWeight="400">
          {description}
        </Typography>
        {(onAction || onDismiss) && (
          <XStack gap="$6" padding="$0" alignItems="center">
            {onAction && (
              <Button onPress={onActionAction} fontWeight="700" hierarchy="tertiary-gray">
                {callToActionLabel ? callToActionLabel : <CoreTrans i18nKey="action.ok" />}
              </Button>
            )}
            {onDismiss && (
              <Button onPress={onDismissClick} padding={0} fontWeight="700" hierarchy="tertiary-gray">
                {dissmissLabel ? dissmissLabel : <CoreTrans i18nKey="action.cancel" />}
              </Button>
            )}
          </XStack>
        )}
      </YStack>
    </View>
  );
};
