import React from 'react';

import {BidirectionalIcon} from '../BidirectionalIcon';
import {IconView} from '../IconView';
import {Pressable} from '../Pressable';
import {CoreTrans} from '../ScopedTrans';
import {Select} from '../Select';
import {Typography} from '../Typography';
import {XStack} from '../XStack';

export interface PaginatorProps {
  current?: number;
  pageSize?: number;
  total?: number;
  pageSizeOptions?: number[];
  onChange: (current: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
}

export const Paginator: React.FC<PaginatorProps> = ({
  current = 0,
  total = 0,
  pageSize = 10,
  pageSizeOptions = [5, 10, 25, 50],
  onChange,
  onPageSizeChange,
}) => {
  const totalPages = Math.ceil(total / pageSize);

  return (
    <XStack alignItems="center" backgroundColor="$accentLight" height="$38" paddingHorizontal="$5">
      <XStack alignItems="center" gap="$1.5">
        <Pressable marginHorizontal="$0.5" isDisabled={current < 2} onPress={() => onChange(current - 1)}>
          <BidirectionalIcon icon="ArrowLeft01Icon" size={17} type="standard" />
        </Pressable>
        <Typography fontWeight="600" variant="body2">
          <CoreTrans i18nKey="pagination.page" />
        </Typography>
        <Select
          trigger={
            <XStack
              minWidth="$1"
              gap="$1"
              alignItems="center"
              justifyContent="space-around"
              borderWidth="$0.5"
              borderColor="$border-secondary"
              backgroundColor="$bg-primary"
              borderRadius="$4"
              paddingHorizontal="$2">
              <Typography variant="body2">{current}</Typography>
              <IconView icon="ArrowDown01Icon" type="standard" />
            </XStack>
          }
          value={current}
          onChange={onChange}
          options={Array.from({length: totalPages}, (_, i) => ({
            label: String(i + 1),
            value: i + 1,
          }))}
        />
        <Typography fontWeight="600" variant="body2">
          <CoreTrans i18nKey="pagination.of" /> {totalPages}
        </Typography>
        <Pressable marginHorizontal="$0.5" isDisabled={current >= totalPages} onPress={() => onChange(current + 1)}>
          <BidirectionalIcon icon="ArrowRight01Icon" size={17} type="standard" />
        </Pressable>
      </XStack>
      {!!pageSizeOptions?.length && (
        <XStack alignItems="center">
          <Select
            trigger={
              <XStack
                minWidth="$1"
                gap="$1"
                alignItems="center"
                justifyContent="space-around"
                borderWidth="$0.5"
                borderColor="$border-secondary"
                backgroundColor="$bg-primary"
                borderRadius="$4"
                paddingHorizontal="$2">
                <Typography variant="body2">{pageSize}</Typography>
                <IconView icon="ArrowDown01Icon" type="standard" />
              </XStack>
            }
            marginHorizontal="$0.5"
            value={pageSize}
            onChange={onPageSizeChange}
            options={pageSizeOptions?.map((item) => ({value: item, label: `${item}`}))}
          />
          <Typography variant="caption">
            <CoreTrans i18nKey="pagination.perPage" />
          </Typography>
        </XStack>
      )}
    </XStack>
  );
};
