import React, {ReactNode} from 'react';

import {Select as BaseSelect, ListItemProps} from 'tamagui';

type Props = {
  triggerProps?: ListItemProps | undefined;
  children: ReactNode;
};

export const TriggerContainer = ({triggerProps, children}: Props) => (
  <BaseSelect.Trigger
    padding={0}
    backgroundColor="transparent"
    borderColor="transparent"
    pressStyle={{backgroundColor: 'transparent', borderColor: 'transparent'}}
    hoverStyle={{backgroundColor: 'transparent', borderColor: 'transparent'}}
    focusStyle={{backgroundColor: 'transparent', borderColor: 'transparent'}}
    focusVisibleStyle={{
      outlineWidth: 0,
    }}
    position="relative"
    zIndex={100000000}
    {...triggerProps}>
    {children}
  </BaseSelect.Trigger>
);
