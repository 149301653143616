import {
  COLORS,
  BLUE,
  BLUE_DARK,
  BLUE_LIGHT,
  BRAND,
  ERROR,
  FUCHSIA,
  GRAY_BLUE,
  GREEN,
  INDIGO,
  ORANGE,
  ORANGE_DARK,
  PINK,
  PURPLE,
  SUCCESS,
  WARNING,
  YELLOW,
  CYAN,
  GRAY_COOL,
  GRAY_IRON,
  GRAY_MODERN,
  GRAY_WARM,
  GRAY_NEUTRAL,
  GRAY_TRUE,
  GREEN_LIGHT,
  MOSS,
  ROSE,
  TEAL,
  VIOLET,
} from './colors';
import {
  DARK_COLORS,
  DARK_GRAY,
  DARK_GRAY_ALPHA,
  DARK_ALPHA,
  DARK_BACKGROUND,
  DARK_BORDER,
  DARK_FOREGROUND,
  DARK_TEXT,
  DARK_UTILITY_BLUE,
  DARK_UTILITY_BLUE_DARK,
  DARK_UTILITY_BLUE_LIGHT,
  DARK_UTILITY_BRAND,
  DARK_UTILITY_DARK_GRAY,
  DARK_UTILITY_ERROR,
  DARK_UTILITY_FUCHSIA,
  DARK_UTILITY_GRAY_BLUE,
  DARK_UTILITY_GREEN,
  DARK_UTILITY_INDIGO,
  DARK_UTILITY_ORANGE,
  DARK_UTILITY_ORANGE_DARK,
  DARK_UTILITY_PINK,
  DARK_UTILITY_PURPLE,
  DARK_UTILITY_SUCCESS,
  DARK_UTILITY_WARNING,
  DARK_UTILITY_YELLOW,
  DARK_AVATAR,
  DARK_BREADCRUMB,
  DARK_BUTTON_PRIMARY,
  DARK_BUTTON_SECONDARY,
  DARK_BUTTON_TERTIARY,
  DARK_EDITOR,
  DARK_FOOTER_BADGE,
  DARK_HEADER_ABSTRACT,
  DARK_ICON,
  DARK_NAV,
  DARK_SLIDER,
  DARK_SOCIAL_ICON,
  DARK_THUMBNAIL,
  DARK_TOGGLE,
} from './dark';
import {
  LIGHT_COLORS,
  LIGHT_GRAY,
  LIGHT_ALPHA,
  LIGHT_BACKGROUND,
  LIGHT_BORDER,
  LIGHT_FOREGROUND,
  LIGHT_TEXT,
  LIGHT_UTILITY_BLUE,
  LIGHT_UTILITY_BLUE_DARK,
  LIGHT_UTILITY_BLUE_LIGHT,
  LIGHT_UTILITY_BRAND,
  LIGHT_UTILITY_ERROR,
  LIGHT_UTILITY_FUCHSIA,
  LIGHT_UTILITY_GRAY_BLUE,
  LIGHT_UTILITY_GREEN,
  LIGHT_UTILITY_INDIGO,
  LIGHT_UTILITY_LIGHT_GRAY,
  LIGHT_UTILITY_ORANGE,
  LIGHT_UTILITY_ORANGE_DARK,
  LIGHT_UTILITY_PINK,
  LIGHT_UTILITY_PURPLE,
  LIGHT_UTILITY_SUCCESS,
  LIGHT_UTILITY_WARNING,
  LIGHT_UTILITY_YELLOW,
  LIGHT_AVATAR,
  LIGHT_BREADCRUMB,
  LIGHT_BUTTON_PRIMARY,
  LIGHT_BUTTON_SECONDARY,
  LIGHT_BUTTON_TERTIARY,
  LIGHT_EDITOR,
  LIGHT_FOOTER_BADGE,
  LIGHT_HEADER_ABSTRACT,
  LIGHT_ICON,
  LIGHT_NAV,
  LIGHT_SLIDER,
  LIGHT_SOCIAL_ICON,
  LIGHT_THUMBNAIL,
  LIGHT_TOGGLE,
} from './light';
export const ShowCaseColors = {
  LIGHT_COLORS,
  LIGHT_GRAY,
  LIGHT_ALPHA,
  LIGHT_BACKGROUND,
  LIGHT_BORDER,
  LIGHT_FOREGROUND,
  LIGHT_TEXT,
  LIGHT_UTILITY_BLUE,
  LIGHT_UTILITY_BLUE_DARK,
  LIGHT_UTILITY_BLUE_LIGHT,
  LIGHT_UTILITY_BRAND,
  LIGHT_UTILITY_ERROR,
  LIGHT_UTILITY_FUCHSIA,
  LIGHT_UTILITY_GRAY_BLUE,
  LIGHT_UTILITY_GREEN,
  LIGHT_UTILITY_INDIGO,
  LIGHT_UTILITY_LIGHT_GRAY,
  LIGHT_UTILITY_ORANGE,
  LIGHT_UTILITY_ORANGE_DARK,
  LIGHT_UTILITY_PINK,
  LIGHT_UTILITY_PURPLE,
  LIGHT_UTILITY_SUCCESS,
  LIGHT_UTILITY_WARNING,
  LIGHT_UTILITY_YELLOW,
  LIGHT_AVATAR,
  LIGHT_BREADCRUMB,
  LIGHT_BUTTON_PRIMARY,
  LIGHT_BUTTON_SECONDARY,
  LIGHT_BUTTON_TERTIARY,
  LIGHT_EDITOR,
  LIGHT_FOOTER_BADGE,
  LIGHT_HEADER_ABSTRACT,
  LIGHT_ICON,
  LIGHT_NAV,
  LIGHT_SLIDER,
  LIGHT_SOCIAL_ICON,
  LIGHT_THUMBNAIL,
  LIGHT_TOGGLE,
  DARK_COLORS,
  DARK_GRAY,
  DARK_GRAY_ALPHA,
  DARK_ALPHA,
  DARK_BACKGROUND,
  DARK_BORDER,
  DARK_FOREGROUND,
  DARK_TEXT,
  DARK_UTILITY_BLUE,
  DARK_UTILITY_BLUE_DARK,
  DARK_UTILITY_BLUE_LIGHT,
  DARK_UTILITY_BRAND,
  DARK_UTILITY_DARK_GRAY,
  DARK_UTILITY_ERROR,
  DARK_UTILITY_FUCHSIA,
  DARK_UTILITY_GRAY_BLUE,
  DARK_UTILITY_GREEN,
  DARK_UTILITY_INDIGO,
  DARK_UTILITY_ORANGE,
  DARK_UTILITY_ORANGE_DARK,
  DARK_UTILITY_PINK,
  DARK_UTILITY_PURPLE,
  DARK_UTILITY_SUCCESS,
  DARK_UTILITY_WARNING,
  DARK_UTILITY_YELLOW,
  DARK_AVATAR,
  DARK_BREADCRUMB,
  DARK_BUTTON_PRIMARY,
  DARK_BUTTON_SECONDARY,
  DARK_BUTTON_TERTIARY,
  DARK_EDITOR,
  DARK_FOOTER_BADGE,
  DARK_HEADER_ABSTRACT,
  DARK_ICON,
  DARK_NAV,
  DARK_SLIDER,
  DARK_SOCIAL_ICON,
  DARK_THUMBNAIL,
  DARK_TOGGLE,
};
export const SOLID_COLORS = {
  ...COLORS,
  ...BLUE,
  ...BLUE_DARK,
  ...BLUE_LIGHT,
  ...BRAND,
  ...ERROR,
  ...FUCHSIA,
  ...GRAY_BLUE,
  ...GREEN,
  ...INDIGO,
  ...ORANGE,
  ...ORANGE_DARK,
  ...PINK,
  ...PURPLE,
  ...SUCCESS,
  ...WARNING,
  ...YELLOW,
  ...CYAN,
  ...GRAY_COOL,
  ...GRAY_IRON,
  ...GRAY_MODERN,
  ...GRAY_WARM,
  ...GRAY_NEUTRAL,
  ...GRAY_TRUE,
  ...GREEN_LIGHT,
  ...MOSS,
  ...ROSE,
  ...TEAL,
  ...VIOLET,
};
export const LIGHT_COLORS_THEME = {
  ...LIGHT_COLORS,
  ...LIGHT_GRAY,
  ...LIGHT_ALPHA,
  ...LIGHT_BACKGROUND,
  ...LIGHT_BORDER,
  ...LIGHT_FOREGROUND,
  ...LIGHT_TEXT,
  ...LIGHT_UTILITY_BLUE,
  ...LIGHT_UTILITY_BLUE_DARK,
  ...LIGHT_UTILITY_BLUE_LIGHT,
  ...LIGHT_UTILITY_BRAND,
  ...LIGHT_UTILITY_ERROR,
  ...LIGHT_UTILITY_FUCHSIA,
  ...LIGHT_UTILITY_GRAY_BLUE,
  ...LIGHT_UTILITY_GREEN,
  ...LIGHT_UTILITY_INDIGO,
  ...LIGHT_UTILITY_LIGHT_GRAY,
  ...LIGHT_UTILITY_ORANGE,
  ...LIGHT_UTILITY_ORANGE_DARK,
  ...LIGHT_UTILITY_PINK,
  ...LIGHT_UTILITY_PURPLE,
  ...LIGHT_UTILITY_SUCCESS,
  ...LIGHT_UTILITY_WARNING,
  ...LIGHT_UTILITY_YELLOW,
  ...LIGHT_AVATAR,
  ...LIGHT_BREADCRUMB,
  ...LIGHT_BUTTON_PRIMARY,
  ...LIGHT_BUTTON_SECONDARY,
  ...LIGHT_BUTTON_TERTIARY,
  ...LIGHT_EDITOR,
  ...LIGHT_FOOTER_BADGE,
  ...LIGHT_HEADER_ABSTRACT,
  ...LIGHT_ICON,
  ...LIGHT_NAV,
  ...LIGHT_SLIDER,
  ...LIGHT_SOCIAL_ICON,
  ...LIGHT_THUMBNAIL,
  ...LIGHT_TOGGLE,
  ...SOLID_COLORS,
};
export const DARK_COLORS_THEME = {
  ...DARK_COLORS,
  ...DARK_GRAY,
  ...DARK_GRAY_ALPHA,
  ...DARK_ALPHA,
  ...DARK_BACKGROUND,
  ...DARK_BORDER,
  ...DARK_FOREGROUND,
  ...DARK_TEXT,
  ...DARK_UTILITY_BLUE,
  ...DARK_UTILITY_BLUE_DARK,
  ...DARK_UTILITY_BLUE_LIGHT,
  ...DARK_UTILITY_BRAND,
  ...DARK_UTILITY_DARK_GRAY,
  ...DARK_UTILITY_ERROR,
  ...DARK_UTILITY_FUCHSIA,
  ...DARK_UTILITY_GRAY_BLUE,
  ...DARK_UTILITY_GREEN,
  ...DARK_UTILITY_INDIGO,
  ...DARK_UTILITY_ORANGE,
  ...DARK_UTILITY_ORANGE_DARK,
  ...DARK_UTILITY_PINK,
  ...DARK_UTILITY_PURPLE,
  ...DARK_UTILITY_SUCCESS,
  ...DARK_UTILITY_WARNING,
  ...DARK_UTILITY_YELLOW,
  ...DARK_AVATAR,
  ...DARK_BREADCRUMB,
  ...DARK_BUTTON_PRIMARY,
  ...DARK_BUTTON_SECONDARY,
  ...DARK_BUTTON_TERTIARY,
  ...DARK_EDITOR,
  ...DARK_FOOTER_BADGE,
  ...DARK_HEADER_ABSTRACT,
  ...DARK_ICON,
  ...DARK_NAV,
  ...DARK_SLIDER,
  ...DARK_SOCIAL_ICON,
  ...DARK_THUMBNAIL,
  ...DARK_TOGGLE,
  ...SOLID_COLORS,
};
