import {GEN_PATH_ROOT_KEY} from '../../../../config';
import {StateTypes} from '../../../../types';
import {PredefinedPath} from '../../models';

import {determineType} from './determineType';
import {generatePathsFromList} from './generatePathsFromList';

export function generatePathsFromObject(
  input: Record<string, any>,
  parentKey = GEN_PATH_ROOT_KEY,
  result: PredefinedPath[] = [],
  processed = new Set(),
) {
  if (typeof input !== 'object' || input === null) {
    throw new Error('Input must be a non-null object');
  }

  const processNestedObject = (obj: Record<string, any>, prefix: string) => {
    Object.entries(obj).forEach(([key, value]) => {
      const currentKey = `${prefix}.${key}`;

      if (typeof value === 'object' && value !== null) {
        if (Array.isArray(value)) {
          if (value.length > 0 && typeof value[0] === 'object') {
            result.push({
              jsonPath: currentKey,
              name: currentKey,
              type: determineType(value),
            });
            result.push(...generatePathsFromList(value, currentKey));
          } else {
            result.push({
              jsonPath: currentKey,
              name: currentKey,
              type: StateTypes.array,
            });
          }
        } else {
          generatePathsFromObject(value, currentKey, result, processed);
        }
      } else {
        if (!processed.has(currentKey)) {
          processed.add(currentKey);
          const type = determineType(value);
          result.push({
            jsonPath: currentKey,
            name: currentKey,
            type,
          });
        }
      }
    });
  };
  processNestedObject(input, parentKey);

  return result;
}
