import i18n from 'i18next';
import {jwtDecode} from 'jwt-decode';
import ky from 'ky';
import {KyInstance} from 'ky/distribution/types/ky';

import {useAccessToken, useAuth} from '../../context/AuthContext';

let apiInstance: KyInstance;
let baseUrl: string | undefined;

const controlHeaders = {refreshToken: 'refreshed-token', resetToken: 'reset-token'};

function deleteControlHeaders(request: Request) {
  Object.values(controlHeaders).forEach((header) => request.headers.delete(header));
}

function setAuthorizationHeaders(request: Request, token, tenant, prefixUrl) {
  const accessToken = request.headers.get(controlHeaders.refreshToken) || token;
  const restToken = request.headers.get(controlHeaders.resetToken);

  tenant = tenant || request.headers.get('x-tenant');
  let tokenId = 'Bearer';
  if (tenant && request.url.startsWith(prefixUrl)) {
    tokenId = tenant;
    request.headers.set('x-tenant', tenant);
  }

  if (!restToken && accessToken) {
    request.headers.set('Authorization', `${tokenId} ${accessToken}`);
  } else {
    request.headers.delete('Authorization');
  }
}

function setRefreshedTokenHeader(request: Request, token) {
  if (token) {
    request.headers.set(controlHeaders.refreshToken, token);
  } else {
    request.headers.set(controlHeaders.resetToken, 'true');
  }
}

const isTokenExpired = (token: string) => {
  try {
    const {exp} = jwtDecode(token);
    if (!exp) return true;
    const currentTime = Math.floor(Date.now() / 1000);
    return exp <= currentTime;
  } catch (error) {
    return true;
  }
};

function setLocalizationHeader(request: Request) {
  request.headers.set('Accept-Language', i18n.language);
}

export const useAppBaseAPI = (prefixUrl: string, tenant: string) => {
  const token = useAccessToken();
  const {refreshToken} = useAuth();

  if (!apiInstance || baseUrl !== prefixUrl) {
    baseUrl = prefixUrl;
    apiInstance = ky.create({
      prefixUrl,
      hooks: {
        beforeRequest: [
          async (request) => {
            const isExpired = isTokenExpired(token || '');

            if (isExpired) {
              const res = await refreshToken();
              setRefreshedTokenHeader(request, res?.accessToken);
              setAuthorizationHeaders(request, res?.accessToken, tenant, prefixUrl);
            } else {
              setAuthorizationHeaders(request, token, tenant, prefixUrl);
            }

            setLocalizationHeader(request);
            deleteControlHeaders(request);
          },
        ],
      },
      retry: {limit: 2, statusCodes: [401]},
    });
  }

  return apiInstance;
};
