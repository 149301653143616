import React from 'react';

import {View} from 'react-native';

import {Avatar, AvatarProps, AvatarSizes, avatarSizes} from '../Avatar';
import {Typography, TypographyProps} from '../Typography';

export type AvatarGroupProps = {
  size: AvatarProps['size'];
  avatars: {source: AvatarProps['source']}[];
  maxAvatars?: number;
};

export const AvatarGroup: React.FC<AvatarGroupProps> = ({avatars = [], size = 'md', maxAvatars = 20}) => {
  const remainingAvatars = avatars.length - maxAvatars;
  const displayedAvatars = avatars.slice(0, maxAvatars);
  const avatarSize = avatarSizes[size];

  if (maxAvatars <= 0) {
    return null;
  }
  return (
    <View style={{flexDirection: 'row', alignItems: 'center'}}>
      {displayedAvatars.map((avatar, index) => (
        <View key={index} style={{marginLeft: index > 0 ? -avatarSize.size / 2 : 0}}>
          <Avatar {...avatar} size={size} />
        </View>
      ))}
      {remainingAvatars > 0 && (
        <View style={{marginLeft: -avatarSize.size / 2}}>
          <Avatar
            size={size}
            fallback={<Typography variant={typographyVariants[size]}>+{remainingAvatars}</Typography>}
          />
        </View>
      )}
    </View>
  );
};

const typographyVariants: Record<AvatarSizes, TypographyProps['variant']> = {
  xs: 'caption',
  sm: 'body2',
  md: 'body1',
  lg: 'h6',
  xl: 'h6',
  '2xl': 'h5',
};
