import React from 'react';

import {IconType} from '../../../../types';
import {Button, ButtonProps} from '../../../Button';
import {IconView} from '../../../IconView';
import {Tooltip} from '../../../Tooltip';
import {Typography} from '../../../Typography';
import {View} from '../../../View';
import {XStack} from '../../../XStack';

export type SelectButtonsOptions = {
  options: {icon: IconType; lable: string; optionValue: string; action: ButtonProps['onPress']}[];
  value?: string | number;
};

export const SelectButtons = ({options, value}: SelectButtonsOptions) => {
  return (
    <XStack gap="$1">
      {options.map(({icon, lable, optionValue, action}) => {
        return (
          <View flex={1} key={lable}>
            <Tooltip
              allowFlip
              content={
                <View backgroundColor="$gray-900" padding="$1" borderRadius="$rounded">
                  <Typography color="$white">{lable}</Typography>
                </View>
              }
              placement="top">
              <Button
                backgroundColor="$gray-100"
                borderColor="$transparent"
                flex={1}
                prefix={<IconView icon={icon} color={optionValue === value ? '$accent' : '$gray-600'} />}
                onPress={(e) => {
                  action?.(e);
                }}
                hoverStyle={{backgroundColor: '$gray-300'}}
              />
            </Tooltip>
          </View>
        );
      })}
    </XStack>
  );
};
