import React from 'react';

import {Trans, useTranslation} from 'react-i18next';

import {BuilderPropsGroup, LocalizedTextType, PropsBuilder} from '../../../../../types';
import {coreScopedKey} from '../../../../../utils';
import {Select} from '../../../../Select';
import {XStack} from '../../../../XStack';
import {EditSectionElement} from '../../../components';
import {PropsBuilderContainer} from '../../../components/PropsBuilderContainer';
import {StateAwareInput} from '../../../components/StateAwareInput';

import {ImageElementProps} from './types';

export const _ImagePropsBuilder: PropsBuilder<ImageElementProps> = ({value: propsValue, onChange, options}) => {
  const {uri: url, resizeMode} = propsValue;

  const {t} = useTranslation();

  const onUpdateProps = (key: string, value: number | string | LocalizedTextType) => {
    onChange(key, value);
  };

  return (
    <PropsBuilderContainer>
      <EditSectionElement title={<Trans i18nKey="settings.basic.url" />}>
        <XStack alignItems="center" justifyContent="space-between">
          <StateAwareInput
            value={url}
            onChangeText={(value) => onUpdateProps('uri', value)}
            width="80%"
            options={options}
          />
        </XStack>
      </EditSectionElement>

      <EditSectionElement title={<Trans i18nKey="settings.layout.resizeMode" />}>
        <Select
          value={resizeMode}
          options={[
            {label: t('settings.layout.contain'), value: 'contain'},
            {label: t('settings.layout.cover'), value: 'cover'},
            {label: t('settings.layout.stretch'), value: 'stretch'},
            {label: t('settings.layout.repeat'), value: 'repeat'},
            {label: t('settings.layout.center'), value: 'center'},
          ]}
          onChange={(value) => onUpdateProps('resizeMode', value)}
          placeholder={t('settings.layout.cover')}
          width="80%"
        />
      </EditSectionElement>
    </PropsBuilderContainer>
  );
};

export const ImagePropsBuilder: BuilderPropsGroup<ImageElementProps> = {
  title: 'content',
  sections: [
    {
      title: coreScopedKey('blocks.ImageElement'),
      Content: _ImagePropsBuilder,
    },
  ],
};
