export const STATE_PATTERN = /\{\{(?:([^}]+)\.)?([^}]+)\}\}/g;
export const LIST_KEY_PATH_PATTERN = /\{\{\$\.(.*?)\}\}|\{\{$\}\}/g;

export const GLOBAL_SCOPE_KEY = 'global';
export const GEN_PATH_ROOT_KEY = '$';

export const STATE_SYNTAX = {
  PREFIX: '{{',
  SUFFIX: '}}',
};
