import React, {ReactNode} from 'react';

import {View, ViewProps} from '../View';
import {XStack} from '../XStack';

export * from './getOverdueHighlightColor';

interface Props {
  actionsRawWidth?: any;
  actions?: ReactNode;
  sideBarColor?: any;
  hideSideBar?: boolean;
  contentProps?: ViewProps;
  children?: ReactNode;
}

export const DataItemCard: React.FC<Props> = ({
  actionsRawWidth,
  children,
  actions,
  sideBarColor,
  hideSideBar,
  contentProps = {},
}) => {
  return (
    <XStack width="100%" overflow="hidden" borderRadius="$2">
      {!hideSideBar && <View backgroundColor={sideBarColor} height="100%" width={1} />}

      <XStack
        padding={2}
        flex={1}
        borderColor="$border-primary"
        borderWidth={1}
        borderTopRightRadius="$2"
        borderBottomRightRadius="$2"
        flexWrap="wrap"
        {...contentProps}>
        {children}

        {actions && (
          <XStack alignItems="flex-start" justifyContent="flex-end" width={actionsRawWidth} padding={2}>
            {actions}
          </XStack>
        )}
      </XStack>
    </XStack>
  );
};
