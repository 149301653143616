import React from 'react';

import {AlertDialog, XStack, YStack} from 'tamagui';

import {Button} from '../Button';
import {IconView} from '../IconView';
import {CoreTrans} from '../ScopedTrans';
import {View} from '../View';

import {AlertTypeConfig} from './config';
import {AlertTypesConfig} from './types';

export const Alert = ({type, show, trigger, content, onAccept, onCancel, title}: AlertTypesConfig) => {
  const color = AlertTypeConfig[type || '']?.color;
  const backgroundColor = AlertTypeConfig[type || '']?.backgroundColor;
  const icon = AlertTypeConfig[type || '']?.icon;
  const lightColor = AlertTypeConfig[type || '']?.lightColor;

  return (
    <AlertDialog open={show} native>
      <AlertDialog.Trigger asChild>{trigger}</AlertDialog.Trigger>
      <AlertDialog.Portal>
        <AlertDialog.Overlay
          key="overlay"
          animation="quick"
          opacity={0.5}
          enterStyle={{opacity: 0}}
          exitStyle={{opacity: 0}}
        />
        <AlertDialog.Content
          bordered
          elevate
          key="content"
          animation={[
            'quick',
            {
              opacity: {
                overshootClamping: true,
              },
            },
          ]}
          enterStyle={{x: 0, y: -20, opacity: 0, scale: 0.9}}
          exitStyle={{x: 0, y: 10, opacity: 0, scale: 0.95}}
          x={0}
          scale={1}
          opacity={1}
          y={0}>
          <YStack
            alignItems="center"
            minHeight={290}
            width={500}
            space
            $sm={{
              width: '$20',
            }}>
            <YStack alignItems="center" flex={1}>
              <View
                alignItems="center"
                justifyContent="center"
                height={70}
                width={70}
                borderRadius={50}
                marginVertical="$1"
                backgroundColor={lightColor || '$accentLight'}>
                <View
                  alignItems="center"
                  justifyContent="center"
                  height={54}
                  width={54}
                  borderRadius={50}
                  backgroundColor={backgroundColor}>
                  <IconView icon={icon} color={color || '$accent'} size={28} />
                </View>
              </View>
              <AlertDialog.Title marginVertical="$0.5">{title}</AlertDialog.Title>
              <AlertDialog.Description lineHeight="$6">{content}</AlertDialog.Description>
            </YStack>

            <XStack gap="$0.5" width="100%" justifyContent="flex-end">
              <AlertDialog.Cancel asChild>
                <Button hierarchy="secondary-color" flex={1} borderColor="$border-primary" onPress={onCancel}>
                  <CoreTrans i18nKey="action.cancel" />
                </Button>
              </AlertDialog.Cancel>
              <AlertDialog.Action asChild>
                <Button flex={1} onPress={onAccept} theme="active">
                  <CoreTrans i18nKey="action.accept" />
                </Button>
              </AlertDialog.Action>
            </XStack>
          </YStack>
        </AlertDialog.Content>
      </AlertDialog.Portal>
    </AlertDialog>
  );
};
