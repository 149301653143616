import React from 'react';

import {AnimatePresence, Tabs as BaseTabs} from 'tamagui';

import {IconView} from '../../IconView';
import {Typography} from '../../Typography';
import {View} from '../../View';
import {TabsRovingIndicator} from '../components/TabsRovingIndicator';
import {TabsProps, TabsState, TabsTabProps} from '../types';

type Props = {
  tabs: TabsProps['tabs'];
  appDirection: 'ltr' | 'rtl';
  currentTab: number;
  handleOnInteraction: TabsTabProps['handleOnInteraction'];
  activeAt: TabsState['activeAt'];
};

export const BaseTabsList = ({tabs, appDirection, currentTab, handleOnInteraction, ...props}: Props) => {
  return (
    <BaseTabs.List width="100%" disablePassBorderRadius loop={false} direction={appDirection} gap="$1" {...props}>
      {tabs?.map((tab, index) => {
        const isActiveTab = currentTab === index;
        const color = isActiveTab ? '$fg-primary' : '$fg-secondary';

        return (
          <BaseTabs.Tab
            key={index}
            direction={appDirection}
            unstyled
            value={'' + index}
            onInteraction={handleOnInteraction}
            backgroundColor={isActiveTab ? '$bg-active' : '$bg-disabled'}
            paddingVertical="$2"
            paddingHorizontal="$3"
            borderRadius={15}
            borderWidth="$0.5"
            borderColor={isActiveTab ? '$border-primary' : '$border-secondary'}
            overflow="hidden">
            {tab.icon && (
              <View marginHorizontal="$0.5">
                <IconView size={19} icon={tab.icon} color={color} />
              </View>
            )}
            <Typography color={color}>{tab.label}</Typography>
            <AnimatePresence>{isActiveTab && <TabsRovingIndicator key={index} />}</AnimatePresence>
          </BaseTabs.Tab>
        );
      })}
    </BaseTabs.List>
  );
};
