import React from 'react';

import {View} from '../../../../../View';

export const Container = ({children}) => {
  return (
    <View margin="$0.5" gap="$3">
      {children}
    </View>
  );
};
