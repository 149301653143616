import React, {useDeferredValue} from 'react';

import {Platform} from 'react-native';
import {ScrollView} from 'tamagui';

import {Button} from '../../Button';
import {IconView} from '../../IconView';
import {Input} from '../../Input';
import {Typography} from '../../Typography';
import {XStack} from '../../XStack';
import {YStack} from '../../YStack';
import {AutocompleteList} from '../components/AutocompleteList';
import {componentClassName} from '../config';
import {AutocompleteInputContentProps} from '../types';

export const AutocompleteInputContent = ({
  disableSearch,
  placeholderSearch,
  options,
  allowNew,
  onAddNew,
  onChangeSelection,
  isSelected,
  showSelectList,
  searchTerm,
  width,
  handelTextChange,
}: AutocompleteInputContentProps) => {
  const deferredTerm = useDeferredValue(searchTerm);

  const filteredOptions = deferredTerm?.length
    ? options.filter((i) => typeof i?.label === 'string' && i?.label.toLowerCase().includes(deferredTerm.toLowerCase()))
    : options;

  if (Platform.OS === 'web') {
    return showSelectList ? (
      <YStack
        borderRadius="$true"
        borderWidth="$0.5"
        borderColor="$border-primary"
        backgroundColor="$bg-primary"
        maxHeight={300}
        overflow="scroll">
        <ScrollView
          keyboardShouldPersistTaps="always"
          maxHeight={300}
          className={componentClassName}
          width="100%"
          marginTop={!disableSearch ? '$0.5' : undefined}
          marginBottom="$0.5">
          <AutocompleteList
            allowNew
            width={width}
            options={filteredOptions}
            onChangeSelection={onChangeSelection}
            isSelected={isSelected}
          />
          {allowNew && !filteredOptions?.length && deferredTerm && (
            <XStack className={componentClassName} justifyContent="flex-start" marginBottom="$0.5" marginLeft="$0.5">
              <XStack
                alignItems="center"
                onPress={() => {
                  onAddNew(deferredTerm);
                }}
                cursor="pointer"
                padding="$0.5">
                <IconView icon="PlusSignSquareIcon" />
                <Typography marginHorizontal="$0.5" className={componentClassName}>
                  {deferredTerm}
                </Typography>
              </XStack>
            </XStack>
          )}
        </ScrollView>
      </YStack>
    ) : null;
  }

  return (
    <YStack>
      {!disableSearch && (
        <XStack padding="$1" width="100%">
          <Input placeholder={placeholderSearch} width="100%" onChangeText={handelTextChange} />
        </XStack>
      )}
      <ScrollView>
        <AutocompleteList
          options={filteredOptions}
          onChangeSelection={onChangeSelection}
          isSelected={isSelected}
          allowNew
        />
      </ScrollView>
      {allowNew && !filteredOptions?.length && deferredTerm && (
        <XStack justifyContent="flex-start" marginBottom="$0.5" marginLeft="$0.5">
          <Button onPress={() => onAddNew(deferredTerm)} chromeless prefix={<IconView icon="PlusSignSquareIcon" />}>
            {deferredTerm}
          </Button>
        </XStack>
      )}
    </YStack>
  );
};
