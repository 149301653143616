import React from 'react';

import {PageContainer} from '../ScreenPage';
import {Spinner} from '../Spinner';
import {View, ViewProps} from '../View';

type Props = {
  isLoading?: boolean;
  overlayMode?: boolean;
} & ViewProps;

export const SectionLoader: React.FC<Props> = ({isLoading = true, overlayMode, padding = 5, ...props}) => {
  if (!isLoading) {
    return null;
  }

  let content = (
    <View alignItems="center" justifyContent="center" padding={padding} {...props}>
      <Spinner color="$gray-600" />
    </View>
  );

  if (overlayMode) {
    content = (
      <View
        flex={1}
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
        position="absolute"
        top={0}
        left={0}
        backgroundColor="$overlayBackground"
        zIndex={999999999}>
        {content}
      </View>
    );
  }

  return content;
};

export const PageLoader = () => (
  <PageContainer>
    <SectionLoader />
  </PageContainer>
);
