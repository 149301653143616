import {GLOBAL_SCOPE_KEY} from '../../../../../../config';
import {AppContentPage} from '../../../../../../types';
import {MigrationFunction} from '../../../../models';

export const migrations: MigrationFunction<AppContentPage>[] = [
  (page, version) => {
    return {
      ...page,
      specVersion: version,
    };
  },
  (page, version) => {
    return {
      ...page,
      state: page.state || {[GLOBAL_SCOPE_KEY]: []},
      specVersion: version,
    };
  },
];
