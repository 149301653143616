import {useEffect, useMemo, useState} from 'react';

export interface ScopesSelector {
  selectAllScopes: () => void;
  isScopeSelected: (scope: string) => boolean;
  onFilterScopes: (scope: string, checked: boolean) => void;
  filtererdScopes: string[];
  showOneScope: (scope: string) => void;
}

export function useFilterScopes(scopes: string[]): ScopesSelector {
  const [selectedScopes, setSelectedScopes] = useState(new Set());
  useEffect(() => {
    if (scopes.length) {
      setSelectedScopes(new Set(scopes));
    }
  }, [scopes.length]);
  const selectAllScopes = () => setSelectedScopes(new Set(scopes));

  const isScopeSelected = (scope: string) => selectedScopes.has(scope);

  const onFilterScopes = (scope: string, checked: boolean) => {
    setSelectedScopes((prev) => {
      checked ? prev.add(scope) : prev.delete(scope);
      return new Set(prev);
    });
  };
  const filtererdScopes = useMemo(() => scopes.filter(isScopeSelected), [scopes, selectedScopes]);
  const showOneScope = (scope: string) => setSelectedScopes(() => new Set(scope));

  return {onFilterScopes, selectAllScopes, isScopeSelected, filtererdScopes, showOneScope};
}
