import {config} from '@tamagui/config';
import {createTamagui} from 'tamagui';

import {Theme} from '../../types';

import {DARK_COLORS_THEME, LIGHT_COLORS_THEME} from './constant';
import {fonts} from './fonts';
import {tintTamaguiTheme} from './themes';
import {tokens} from './tokens';

export const tamaguiConfig = createTamagui({
  ...config,
  shorthands: {},
  fonts,
  media: {
    ...config.media,
    esm: {minWidth: 661, maxWidth: 800},
    emd: {minWidth: 801, maxWidth: 1020},
    elg: {minWidth: 1021, maxWidth: 1280},
    exl: {minWidth: 1280},
  },
  themes: {
    ...config.themes,
    [Theme.light]: {
      ...config.themes.light,
      ...LIGHT_COLORS_THEME,
    },
    [Theme.dark]: {
      ...config.themes.dark,
      ...DARK_COLORS_THEME,
    },
    [Theme.light_translucent]: {
      background: 'white',
    },
    [Theme.dark_translucent]: {
      background: '#242526',
    },
  },
  tokens,
});

export type TamaConfigType = typeof tamaguiConfig;

export interface CustomTamaguiConfig {
  accentColor?: string;
}

export const createCustomTamaguiConfig = ({accentColor}: CustomTamaguiConfig) => {
  const baseConfig = tamaguiConfig;

  // themes
  if (accentColor) {
    tintTamaguiTheme(baseConfig, accentColor);
  }

  return baseConfig;
};

declare module 'tamagui' {
  interface TamaguiCustomConfig extends TamaConfigType {}
}
