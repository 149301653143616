import React, {forwardRef} from 'react';

import {BlockDefinition, BlockCategory} from '../../../../../types';
import {coreScopedKey} from '../../../../../utils';
import {View} from '../../../../View';
import {LayoutPropsBuilder} from '../../../components';
import {useBuilderRenderer} from '../../../hooks/useBuilderRenderer';
import {useStateResolver} from '../../../hooks/useStateResolver';

import {defaultProps} from './defaultProps';
import {LoopPropsBuilder} from './PropsBuilder';
import {ConditionElementProps} from './types';
import {evaluateQuery} from './utils/evaluateQuery';

export const Component = ({children, editMode, elements, nodes, query, ...props}: ConditionElementProps, ref) => {
  const {renderUI} = useBuilderRenderer(elements, editMode);
  const {resolve} = useStateResolver(editMode);

  const queryWithResolvedState = resolve(query);
  const isTruthy = evaluateQuery(queryWithResolvedState);

  if (!isTruthy && !editMode) {
    return null;
  }

  return (
    <View minHeight="$12" flexDirection="row" flexWrap="wrap" {...props} ref={ref}>
      {editMode ? children : renderUI(nodes)}
    </View>
  );
};

export const ConditionComponent = forwardRef(Component);

export const ConditionElement: BlockDefinition<ConditionElementProps> = {
  name: coreScopedKey('blocks.ConditionElement'),
  version: 1,
  id: 'ConditionElement',
  props: defaultProps,
  children: [],
  Component: ConditionComponent,
  category: BlockCategory.META,
  Icon: 'ViewIcon',
  propsBuilder: [LoopPropsBuilder, LayoutPropsBuilder],
};
