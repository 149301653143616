import {nanoid} from 'nanoid';

import {Category, ConditionElementProps} from './types';

export const defaultProps: ConditionElementProps = {
  query: [
    {
      id: nanoid(),
      type: Category.group,
      data: [
        {
          id: nanoid(),
          type: Category.condition,
          data: {leftInput: '', operator: 'equals', rightInput: ''},
        },
      ],
    },
  ],
  children: null,
};
