import React from 'react';

import {EmptyIllustration} from '../../EmptyIllustration';
import {IconView} from '../../IconView';
import {LocalizedText} from '../../LocalizedText';
import {Typography} from '../../Typography';
import {XStack} from '../../XStack';
import {YStack} from '../../YStack';
import {componentClassName} from '../config';
import {AutocompleteListProps} from '../types';

export const AutocompleteList = ({options, isSelected, onChangeSelection, allowNew, width}: AutocompleteListProps) => {
  return (
    <YStack width={width || '100%'}>
      {options.length || allowNew ? (
        options.map((item) => {
          return (
            <XStack
              alignItems="center"
              className={componentClassName}
              key={item.value}
              onPress={() => onChangeSelection(item)}
              cursor="pointer"
              padding="$0.5">
              <IconView accent={isSelected(item)} icon={isSelected(item) ? 'CheckmarkSquare02Icon' : 'SquareIcon'} />
              <Typography
                marginHorizontal="$0.5"
                color={isSelected(item) ? '$accent' : ''}
                className={componentClassName}>
                <LocalizedText value={item.label} />
              </Typography>
            </XStack>
          );
        })
      ) : (
        <EmptyIllustration />
      )}
    </YStack>
  );
};
