import React from 'react';

import * as HugeIcons from '@medad-sep/hugeicons-react-native';

import {IconProps} from '../../types';

export type IconListType = keyof typeof HugeIcons;

export {IconsList} from './IconsList';

type Props = IconProps & {
  name: IconListType;
  fill?: string;
};

export const Icon = ({name, size = 20, ...props}: Props) => {
  if (name && !HugeIcons[name]) {
    name = 'HexagonIcon';
  }

  const SelectedIcon = HugeIcons[name] as any;

  if (!SelectedIcon) return null;

  return <SelectedIcon strokeWidth={1.8} size={size} {...props} />;
};
