import React from 'react';

import {XStack, YStack} from 'tamagui';

import {IconView} from '../../../components/IconView';
import {Pressable} from '../../../components/Pressable';
import {Typography} from '../../../components/Typography';
import {dayjs} from '../../../utils/extendedDayjs';
import {Dialog} from '../../Dialog';
import {CalendarEvent, CalendarViews, CalendarViewsType} from '../types';

import {EventDetails} from './EventDetails';

interface EventItemProps {
  eventItem: CalendarEvent;
  currentView?: CalendarViewsType;
}

export const EventItem = ({eventItem, currentView}: EventItemProps) => {
  const isDayView = !currentView || currentView === CalendarViews.day;
  const isWeekView = currentView === CalendarViews.week;

  return (
    <Dialog
      title={eventItem.title}
      content={<EventDetails eventItem={eventItem} />}
      trigger={
        <Pressable
          height="100%"
          backgroundColor="$background"
          $md={{
            marginRight: '$0.5',
            marginVertical: '$0.5',
            borderLeftWidth: 5,
            position: 'relative',
            top: 30,
            height: '$8',
          }}
          overflow="hidden"
          borderRadius="$true"
          borderColor={eventItem.colors?.main ?? '$accent'}
          borderWidth={1}
          flexDirection="column"
          justifyContent="space-between"
          alignItems="flex-start"
          padding={4}
          margin={2}>
          <YStack flex={1} width="100%" borderRadius="$true">
            <XStack alignItems="center">
              {isDayView && <IconView icon="Task01Icon" color={eventItem.colors?.dark as any} />}

              <Typography
                flexWrap="wrap"
                marginHorizontal="$0.5"
                fontWeight="400"
                variant="body2"
                flex={1}
                numberOfLines={isWeekView ? 3 : 2}
                $sm={{color: '$textColor'}}>
                {eventItem.title}
              </Typography>
            </XStack>

            {isDayView && (
              <>
                <Typography color={eventItem.colors?.dark as any} variant="caption" $sm={{color: '$textColor'}}>
                  {dayjs(eventItem.start || eventItem.date).format('hh:mm A')}
                  {eventItem.end && ' - ' + dayjs(eventItem.end).format('hh:mm A')}
                </Typography>

                <Typography color="black" fontWeight="400" flex={1} numberOfLines={3} $sm={{color: '$textColor'}}>
                  {eventItem.description}
                </Typography>
              </>
            )}
          </YStack>
          <XStack space={1} alignItems="center"></XStack>
        </Pressable>
      }
    />
  );
};
