import React from 'react';

import {Trans} from 'react-i18next';

import {PropsBuilder} from '../../../../../types';
import {ColorInput} from '../../../../ColorInput';
import {ViewProps} from '../../../../View';
import {EditSectionElement} from '../../EditSectionElement';
import {SliderInputValuePicker} from '../../SliderInputValuePicker';

import {Container} from './components/Container';

export const Style: PropsBuilder<ViewProps> = ({value, onChange, onReset}) => {
  const {backgroundColor, opacity} = value;

  return (
    <Container>
      <EditSectionElement title={<Trans i18nKey="settings.style.opacity" />} onReset={() => onReset(['opacity'])}>
        <SliderInputValuePicker
          max={1}
          step={0.01}
          value={opacity as any}
          propName="opacity"
          onUpdate={onChange}
          defaultValue={1}
        />
      </EditSectionElement>
      <EditSectionElement
        title={<Trans i18nKey="settings.style.backgroundColor" />}
        onReset={() => onReset(['backgroundColor'])}>
        <ColorInput
          inputProps={{width: '80%'}}
          value={backgroundColor}
          onChange={(value) => onChange('backgroundColor', value)}
        />
      </EditSectionElement>
    </Container>
  );
};
