import React, {useCallback, useEffect} from 'react';

import {useTranslation} from 'react-i18next';

import {i18nOptions} from '@localization/options';
import {IconView, Select} from '@medad-sep/core';
import {cacheService} from '@shared/services/cache';

const UserLangKey = 'USER_LANG';

const languagesList = Object.keys(i18nOptions.resources as any);

export function LanguageSelector(): JSX.Element {
  const {t, i18n} = useTranslation();

  const onLangChange = useCallback((lang) => {
    cacheService.set(UserLangKey, lang);
    location.reload();
  }, []);

  useEffect(() => {
    const userLang = cacheService.get<string>(UserLangKey);
    if (userLang) i18n.changeLanguage(userLang);
  }, []);

  return (
    <Select
      inline
      value={i18n.language}
      onChange={onLangChange}
      trigger={<IconView icon="LanguageSquareIcon" size={17} color="$gray-600" />}
      options={languagesList?.map((lang) => ({value: lang, label: t(`language.${lang}`)}))}
    />
  );
}
