import React, {forwardRef} from 'react';

import {BlockCategory, BlockDefinition} from '../../../../../types';
import {coreScopedKey} from '../../../../../utils';
import {Table, TableProps} from '../../../../Table';
import {LayoutPropsBuilder} from '../../../components';

import {defaultProps} from './defaultProps';
import {TablePropsBuilder} from './PropsBuilder';

export type TableElementProps = TableProps<any>;
export const Component = ({...props}: TableElementProps, ref) => {
  return <Table {...props} ref={ref} />;
};
export const TableComponent = forwardRef(Component);
export const TableElement: BlockDefinition<TableElementProps> = {
  name: coreScopedKey('blocks.TableElement'),
  version: 1,
  id: 'TableElement',
  props: defaultProps,
  Component: TableComponent,
  category: BlockCategory.NODE,
  Icon: 'TableIcon',
  propsBuilder: [TablePropsBuilder, LayoutPropsBuilder],
};
