import React from 'react';

import {View} from 'react-native';
import {ColorTokens, getToken, useTheme} from 'tamagui';

import {useAppConfig} from '../../context';
import {Icon, IconListType} from '../../lib';
import {IconProps, IconType} from '../../types';
import {isReactComponent} from '../../utils';

type Props = IconProps & {
  icon?: IconType;
  defaultIcon?: IconListType;
  fill?: boolean;
  fillColor?: ColorTokens;
  accent?: boolean;
  color?: ColorTokens;
};

export const IconView = ({
  icon,
  defaultIcon,
  color = '$text-primary',
  accent,
  fill,
  fillColor = '$transparent',
  type = 'rounded',
  variant = 'stroke',
  ...props
}: Props) => {
  const appConfig = useAppConfig();
  const theme = useTheme();

  const filledColor = fillColor == '$accent' ? appConfig.accentColor : theme[fillColor]?.val ?? 'transparent';

  let iconColor = getToken(color as any) || (color && theme[color]?.val) || color;

  if (accent || iconColor == '$accent') {
    iconColor = appConfig?.accentColor as any;
  }

  if (!iconColor) {
    iconColor = theme['$gray-600']?.val;
  }

  let content: any = null;

  if (!icon || typeof icon === 'string') {
    content = (
      <View {...props}>
        <Icon
          name={(icon || defaultIcon || 'HexagonIcon') as any}
          size={props.size}
          type={type}
          variant={variant}
          color={iconColor}
          fill={filledColor}
        />
      </View>
    );
  }

  if (isReactComponent(icon)) {
    const IconComponent = icon as any;
    content = <IconComponent {...props} color={iconColor} />;
  }

  if (content && fill) {
    content = (
      <View style={{height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center'}}>{content}</View>
    );
  }

  return content;
};
