import {SPACE_PRIMITIVES} from './spacePrimitives';

export const CONTAINER_PADDING_TOKENS = {
  'container-padding-mobile': SPACE_PRIMITIVES.$4,
  'container-padding-desktop': SPACE_PRIMITIVES.$8,
};

export const SPACE_TOKENS = {
  ...SPACE_PRIMITIVES,
  ...CONTAINER_PADDING_TOKENS,
  'spacing-none': SPACE_PRIMITIVES.$0,
  'spacing-xxs': SPACE_PRIMITIVES['$0.5'],
  'spacing-xs': SPACE_PRIMITIVES.$1,
  'spacing-sm': SPACE_PRIMITIVES['$1.5'],
  'spacing-md': SPACE_PRIMITIVES.$2,
  'spacing-lg': SPACE_PRIMITIVES.$3,
  'spacing-xl': SPACE_PRIMITIVES.$4,
  'spacing-2xl': SPACE_PRIMITIVES.$5,
  'spacing-3xl': SPACE_PRIMITIVES.$6,
  'spacing-4xl': SPACE_PRIMITIVES.$8,
  'spacing-5xl': SPACE_PRIMITIVES.$10,
  'spacing-6xl': SPACE_PRIMITIVES.$12,
  'spacing-7xl': SPACE_PRIMITIVES.$16,
  'spacing-8xl': SPACE_PRIMITIVES.$20,
  'spacing-9xl': SPACE_PRIMITIVES.$24,
  'spacing-10xl': SPACE_PRIMITIVES.$32,
  'spacing-11xl': SPACE_PRIMITIVES.$40,
};
