import React from 'react';

import {IconProps} from '../../types';
import {IconView} from '../IconView';
import {CoreTrans} from '../ScopedTrans';
import {Typography} from '../Typography';
import {View, ViewProps} from '../View';

interface Props {
  hasNoData?: boolean;
  hideLabel?: boolean;
  padding?: ViewProps['padding'];
}

export const NoDataMessage: React.FC<Props & IconProps> = ({hasNoData, hideLabel, padding, ...iconProps}) => {
  if (!hasNoData) {
    return null;
  }

  return (
    <View alignItems="center" justifyContent="center" padding={padding || 50} style={{opacity: 0.2}}>
      <IconView icon="PackageOpenIcon" size={48} color="$utility-gray-900" strokeWidth={1} {...iconProps} />
      {!hideLabel && (
        <Typography margin="$0.5">
          <CoreTrans i18nKey="noData" />
        </Typography>
      )}
    </View>
  );
};
