import {Local} from './Local';

export const ar: Local = {
  translation: {
    validation: {
      requiredField: 'الحقل مطلوب',
      mustBePositive: 'يجب ان يكون موجوب',
    },
    environments: {
      PRE_PRODUCTION: 'بيئة ما قبل الإنتاج',
      PRODUCTION: 'بيئة الإنتاج',
    },
    general: {
      enable: 'تفعيل',
      enableAll: 'تفعيل الكل',
      homePage: 'تعيين كصفحة رئيسية',
      home: 'الرئيسية',
    },
    notifications: 'الإشعارات',
    tags: 'كلمات دليلية',
    clearFilters: 'إعادة تعيين',
    filter: 'فلتر',
    sort: 'ترتيب',
    login: 'تسجيل دخول',
    logout: 'تسجيل خروج',
    generalErrorMsg: 'عذرا، هناك خطأ ما.',
    successMsg: 'تم, بنجاح',
    actions: 'الأجراءات',
    action: {
      new: 'جديد',
      edit: 'تعديل',
      delete: 'حذف',
      add: 'إضافة',
      save: 'حفظ',
      undo: 'تراجع',
      redo: 'إعادة',
      apply: 'نشر',
      cancel: 'الغاء',
      preview: 'معاينة',
      set: 'وضع',
      accept: 'موافق',
      close: 'إغلاق',
    },
    dashboard: 'لوحة القيادة',
    designer: 'المصمم',
    'api-resource': 'لوحة تحكم واجهة برمجة التطبيقات',
    'global-variables': 'المتغيرات العالمية',
    configurations: 'الإعدادات',
    services: 'الخدمات',
    configurationsOf: 'إعدادات',
    properties: 'الخصائص',
    layers: 'الطبقات',
    builder: {
      states: 'القيم',
      createScope: 'اضافة مجال',
      deleteScopeMsg:
        'سيؤدي حذف هذا النطاق إلى إزالة حالاته في كل مكان يتم استخدامه فيه. هل أنت متأكد أنك تريد المتابعة؟',
      deleteStateMsg:
        'سيؤدي حذف هذه الحالة إلى إزالة قيمتها في كل مكان يتم استخدامها فيه. هل أنت متأكد أنك تريد المتابعة؟',
    },
    settings: {
      settings: 'الاعدادات',
      pageSettings: 'إعدادات الصفحة',
      pageTitle: 'عنوان الصفحة',
      pageIcon: 'رمز الصفحة',
      addWidget: 'إضافة قطعة',
      insertRow: 'أدخل صفًا جديدًا',
      clickToEdit: 'انقر فوق أحد المكونات لبدء التحرير.',
      spacing: 'المساحات',
      measures: 'الابعاد',
      alignment: 'تنسيق',
      noSectionsMsgTitle: 'قم بإنشاء قسم أولاً',
      noSectionsMsg:
        'لبدء التحرير، يجب عليك أولاً إنشاء قسم. قم بالمرور فوق المحرر وسترى زر (+)، اضغط على الزر وستكون جاهزًا للانطلاق أو من الزر أدناه',
      layout: {
        label: 'التنسيق',
        width: 'العرض',
        minWidth: 'الحد الأدنى للعرض',
        maxWidth: 'أقصى عرض',
        height: 'الطول',
        minHeight: 'الحد الأدنى للارتفاع',
        maxHeight: 'أقصى طول',
        margin: 'الهامش',
        padding: 'التبطين',
        resizeMode: 'وضع تغيير الحجم',
        contain: 'يحتوي',
        cover: 'يغطي',
        stretch: 'تمتد',
        repeat: 'تكرر',
        center: 'فى الوسط',
        left: 'اليسار',
        right: 'اليمين',
        top: 'الاعلى',
        bottom: 'الأسفل',
        gap: 'الفجوة',
      },
      style: {
        label: 'الشكل',
        opacity: 'الشفافية',
        color: 'اللون',
        background: 'الخلفية',
        backgroundColor: 'لون الخلفية',
      },
      typography: {
        label: 'النصوص',
        initialText: 'يظهر المحتوى الخاص بك هنا، قم بتحرير أنماط النص والمحتوى أو قم بإزالة هذا النص من الشريط الجانبي',
        fontSize: 'الحجم',
        fontWeight: 'السمك',
        textAlign: 'محاذاة',
        start: 'البداية',
        left: 'اليسار',
        right: 'اليمين',
        center: 'الوسط',
        thin: 'رفيع',
        normal: 'طبيعي',
        bold: 'سميك',
        lineHeight: 'ارتفاع الخط',
      },
      basic: {
        label: 'أساسي',
        text: 'محتوى النص',
        url: 'الرابط',
      },
      button: {
        empty: 'فارغ',
        filled: 'مملوء',
        outlined: 'محدد',
        danger: 'خطر',
        info: 'معلومات',
        neutral: 'محايد',
        primary: 'أساسي',
        success: 'نجاح',
        warning: 'تحذير',
      },
      accordion: {
        newSection: 'قسم جديد',
      },
      borders: {
        label: 'الحدود',
        borderWidth: 'عرض الحدود',
        borderRadius: 'نصف قطر الحدود',
        borderColor: 'لون الحدود',
        width: 'العرض',
        color: 'اللون',
        radius: 'نصف القطر',
      },
      flex: {
        label: 'المرونة',
        direction: 'الاتجاه',
        wrap: 'اللف',
        align: 'المحاذاة',
        alignSelf: 'محاذاة الذات',
        justify: 'ملأ السطر',
        row: 'صف',
        column: 'عمود',
        nowrap: 'ممنوع اللف',
        wrapReverse: 'لف بالعكس',
        flexStart: 'البداية',
        flexEnd: 'النهاية',
        center: 'الوسط',
        stretch: 'ممتد',
        spaceEvenly: 'تباعد بالتساوي',
        spaceBetween: 'الفضاء بين',
        spaceAround: 'الفضاء حول',
        grow: 'النمو',
      },
    },
    viewportSizes: {
      mobile: 'هاتف محمول',
      tablet: 'جهاز لوحى',
      desktop: 'جهاز مكتبى',
    },
    appConfig: {
      title: 'التطبيق',
      primaryColor: 'اللون الاساسى',
      accentColor: 'اللون المكمل',
      logo: 'الشعار',
      icon: 'الأيقونة',
      icons: 'الأيقونات',
      splashScreen: 'شاشة البداية',
      brandColors: 'لون العلامة التجارية',
      generalSettings: 'الإعدادات الأساسية',
    },
    deploySettings: {
      title: 'إعدادات النشر',
      buildApp: 'بناء التطبيق',
      deployApp: 'نشر التطبيق',
      downloadApp: 'تحميل التطبيق',
      previousPage: 'الصفحة السابقة',
      nextPage: 'الصفحة التالية',
      android: 'أندرويد',
      ios: 'IOS',
      all: 'الكل',
      none: 'لا يوجد',
      showError: 'عرض الخطأ',
      buildError: 'خطأ النشر',
      failedToFetchConfig: 'فشل في الحصول على إعدادات التكوين',
      loadingConfig: 'جاري تحميل إعدادات التكوين',
      error: 'الخطأ',
      configuration: 'الإعدادات',
      version: 'الإصدار',
      steps: 'الخطوات',
      status: 'الحالة',
      buildHistory: 'تاريخ البناء',
      testConnection: 'اختبار الاتصال',
      EASConfiguration: 'إعدادات EAS',
      platform: 'المنصة',
      selectPlatform: 'حددالمنصة',
      newBuildsOccurMessage: 'يعتمد الحد الأقصى لعدد البناء على خطة حساب EAS الخاصة بك.',
      somethingWentWrong: 'حدث خطأ ما',
      easConnectedSuccessfully: 'تم الاتصال بـ EAS بنجاح',
      doneSuccessfully: 'تم بنجاح',
      createdOn: 'تم الإنشاء في',
      usernameOrPasswordIncorrect: 'اسم المستخدم أو كلمة المرور غير صحيحة',
      easToken: 'الرمز السري ل EAS',
      testApp: 'تجربة التطبيق',
      ascAppId: 'معرف التطبيق',
    },
    appletConfig: {
      enable: 'تفعيل/عدم تفعيل',
      componentsCustomizations: 'تخصيصات المكونات',
      pleaseEnableConnector: 'للاطلاع على الخدمات المتاحة، يُرجى تفعيل هذا الموصل',
      general: 'عام',
    },
    homeConfig: {
      title: 'تخصيصات المكونات',
      enable: 'تفعيل/عدم تفعيل',
    },
    servicesAuthorization: {
      title: 'تحكم الخدمات',
    },
    generalConfig: {
      title: 'عام',
    },
    apiSettings: {
      noCollectionSelected: 'لم يتم اختيار أي طلب API لهذه المجموعة',
      noCollectionSelectedDes: 'يرجى إنشاء طلب API أولاً',
      apiUrl: 'الرابط',
      method: 'الطريقة',
      headers: 'الرؤوس',
      params: 'المعلمات',
      queryParams: 'معلمات الاستعلام',
      addHeader: 'إضافة رأس',
      key: 'المفتاح',
      value: 'القيمة',
      body: 'الجسم',
      testAndPreview: 'اختبار ومعاينة',
      responsePath: 'مسار الاستجابة',
      testAndMapApi: 'اختبار وربط نتيجة ال API',
      testApiCall: 'اختبار استدعاء API',
      recommended: 'موصى به',
      addJsonPath: 'إضافة مسار JSON',
      predefinedPath: 'مسار محدد مسبقاً',
      jsonPath: 'مسار JSON',
      name: 'الاسم',
      type: 'النوع',
      enterCollectionTitle: 'أدخل عنوان المجموعة...',
      apiDetails: 'تفاصيل API',
      editAPICall: 'تعديل استدعاء API',
      addCollection: 'إضافة مجموعة',
      editCollection: 'تعديل المجموعة',
      defaultValue: 'القيمة الافتراضية',
      collections: 'المجموعات',
      setValue: 'API تعيين القيمة من',
      editAPIName: 'تعديل المجموعة',
      addQueryParam: 'إضافة معلمة استعلام',
      apiUpdated: 'تم تحديث طلب API',
      apiDeleted: 'تم حذف طلب API',
      deleteAPIRequest: 'حذف API',
      deleteAPIRequestMsg: 'هل أنت متأكد أنك تريد حذف API',
      deleteCollection: 'حذف المجموعة',
      deleteCollectionMsg: 'هل أنت متأكد أنك تريد حذف هذه المجموعة؟',
    },
    globalVariables: {
      name: 'المتغيرات العالمية',
      scopes: 'المجالات',
      createScope: 'اضافة مجال',
      createVariable: 'اضافة متغير',
      editVariable: 'تعديل المتغير',
      secret: 'سر',
      deleteVariableMsg:
        'سيؤدي حذف هذا المتغير إلى إزالته من كل مكان يتم استخدامه فيه. هل أنت متأكد من أنك تريد المتابعة؟',
      variableName: 'اسم المتغير',
      variableValue: 'قيمة المتغير',
      variableType: 'نوع المتغير',
      scopeName: 'اسم المجال',
      editScope: 'تعديل المجال',
      deleteScopeMsg: 'سيؤدي حذف هذا المجال إلى إزالته من كل مكان يتم استخدامه فيه. هل أنت متأكد من أنك تريد المتابعة؟',
      editVariableSuccess: 'تم تعديل المتغير بنجاح',
      deleteVariableSuccess: 'تم ازالة المتغير بنجاح',
      createVariableSuccess: 'تم انشاء المتغير بنجاح',
      editScopeSuccess: 'تم تعديل المجال بنجاح',
      createScopeSuccess: 'تم انشاء المجال بنجاح',
      deleteScopeSuccess: 'تم ازالة المجال بنجاح',
      nameRequired: 'الاسم مطلوب',
      valueRequired: 'القيمة مطلوبة',
    },
    name: 'الاسم',
    value: 'القيمة',
    public: 'عام',
    private: 'خاص',
    elements: 'عناصر',
    widgets: 'القطع',
    content: 'المحتوى',
    header: 'عنوان',
    design: 'التصميم',
    variant: 'الشكل',
    type: 'النوع',
    pages: 'الصفحات',
    section: 'القسم',
    container: 'حاوية',
    addElement: 'اضافة عنصر',
    changeIcon: 'تغيير الايقونة',
    selectRequiredRoles: 'حدد الأدوار المطلوبة',
    servicePage: 'صفحة خدمة',
    contentPage: 'صفحة محتوى',
    selectService: 'اختر خدمة',
    launcher: 'المنصة',
    launcherTop: 'أعلى المنصة',
    launcherBottom: 'أسفل المنصة',
    auxiliaryPages: 'صفحات مساعدة',
    auxiliaryPage: 'صفحة مساعدة',
    pagesGroup: 'مجموعة صفحات',
    upload: 'رفع',
    placeholder: 'نص توضيحي',
    sessions: {
      title: 'الجلسة',
      enable: 'مفعل',
      disable: 'غير مفعل',
      setSession: 'تعيين الحد الزمني للجلسة',
    },
    home: 'الرئيسية',
    highlighted: 'مميزة',
  },
};
