import React, {useState} from 'react';

import {STATE_SYNTAX} from '../../../../config';
import {Options, State} from '../../../../types';
import {Input, InputProps} from '../../../Input';
import {TextArea} from '../../../TextArea';
import {View} from '../../../View';

import {StatePopover} from './StatePopover';

type StateAwareInputProps = InputProps & {
  value: string;
  textArea?: boolean;
  onChangeText?: (value: string) => void;
  onStateSelected?: (value: {scopeId: string; stateId: string}) => void;
  types?: State['type'][];
  options?: Options;
};

export const StateAwareInput = ({
  value,
  textArea = false,
  onChangeText,
  onStateSelected,
  types,
  options,
  ...props
}: StateAwareInputProps) => {
  const [caretPosition, setCaretPosition] = useState(0);
  const [showPopover, setShowPopover] = useState(false);

  const handleInputChange = (e) => {
    const value = e.target?.value;
    onChangeText?.(value);
    const lastTwoChars = value.slice(-2);

    if (lastTwoChars === STATE_SYNTAX.PREFIX) {
      setShowPopover(true);
      setCaretPosition(e.target?.selectionStart);
    } else {
      setShowPopover(false);
    }
  };

  return (
    <View position="relative" width={props.width}>
      <StatePopover
        setShowPopover={setShowPopover}
        showPopover={showPopover}
        types={types}
        value={value}
        caretPosition={caretPosition}
        onChangeText={onChangeText}
        onStateSelected={onStateSelected}
        options={options}
      />

      {!textArea ? (
        <Input value={value} onChange={handleInputChange} {...props} />
      ) : (
        <TextArea
          textAreaProps={{
            padding: '$4',
            color: '$gray-600',
            lineHeight: 20,
            fontWeight: '500',
            fontSize: 14,
          }}
          padding="$0"
          value={value}
          onChange={handleInputChange}
          {...props}
        />
      )}
    </View>
  );
};
