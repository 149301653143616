import React, {forwardRef} from 'react';

import {BlockDefinition, BlockCategory} from '../../../../../types';
import {coreScopedKey} from '../../../../../utils';
import {LocalizedText} from '../../../../LocalizedText';
import {Typography} from '../../../../Typography';
import {LayoutPropsBuilder} from '../../../components';

import {defaultProps} from './defaultProps';
import {TextPropsBuilder} from './PropsBuilder';
import {TextElementProps} from './types';

export const Component = ({value, ...props}: TextElementProps, ref) => {
  return (
    <Typography {...props} ref={ref}>
      <LocalizedText value={value} />
    </Typography>
  );
};
export const TextComponent = forwardRef(Component);

export const TextElement: BlockDefinition<TextElementProps> = {
  version: 2,
  id: 'TextElement',
  name: coreScopedKey('blocks.TextElement'),
  props: defaultProps,
  category: BlockCategory.NODE,
  Component: TextComponent,
  Icon: 'TextFontIcon',
  propsBuilder: [TextPropsBuilder, LayoutPropsBuilder],
  migrate: (block) => block,
};
