import React, {useMemo, useState} from 'react';

import {dayjs} from '../../utils/extendedDayjs';
import {Datepicker} from '../DatePicker';
import {IconView} from '../IconView';
import {Input} from '../Input';
import {Popover} from '../Popover';

import {DateInputProps} from './models';
import {formatInpuValue} from './utils';

export const DateInput: React.FC<DateInputProps> = ({
  placeholder,
  value = '',
  isDisabled,
  disableDatePicker,
  calendarType = 'gregory',
  mode = 'single',
  format = 'YYYY-MM-DD',
  minimumDate,
  maximumDate,
  excludeDates,
  allowedDates,
  onBlur,
  onChange,
  filterDate,
  ...props
}) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [formattedDate, setFormattedDate] = useState('');

  const formattedValue = useMemo(() => formatInpuValue(value, format), [value, format]);

  return (
    <Popover
      open={showDatePicker}
      onOpenChange={setShowDatePicker}
      closable
      contentProps={{padding: 0, borderRadius: 30}}
      trigger={
        <Input
          disabled={isDisabled}
          alignItems="center"
          justifyContent="center"
          width="100%"
          placeholder={placeholder}
          editable={false}
          maxWidth="$20"
          selectTextOnFocus={false}
          onBlur={onBlur}
          {...props}
          value={formattedValue || formattedDate}
          suffix={props.suffix || <IconView icon="Calendar04Icon" />}
        />
      }>
      <Datepicker
        onChange={(date) => {
          onChange?.(date);

          let formattedDate;
          if (mode !== 'range') {
            formattedDate = dayjs(date).format(format);
            setShowDatePicker(false);
          } else {
            formattedDate = dayjs(date[0]).format(format) + ' - ' + dayjs(date[1]).format(format);
          }

          setFormattedDate(formattedDate);
        }}
        maximumDate={maximumDate ? new Date(maximumDate) : undefined}
        minimumDate={minimumDate ? new Date(minimumDate) : undefined}
        mode={mode}
        filterDate={filterDate}
        excludeDates={excludeDates}
        allowedDates={allowedDates}
        isDisabled={disableDatePicker}
        type={calendarType}
      />
    </Popover>
  );
};
