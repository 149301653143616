import React, {forwardRef, ReactNode, ComponentProps, useState} from 'react';

import {Group as GroupContainer} from 'tamagui';

import {Button, ButtonProps} from '../Button';

type ButtonGroupsProps = ComponentProps<typeof GroupContainer> & {
  groupButtons: ButtonProps[];
  groupContent?: (selectedButton: ButtonProps) => ReactNode;
  defaultButton?: ButtonProps;
};

const _ButtonGroups = ({groupButtons, groupContent, defaultButton, ...rest}: ButtonGroupsProps, ref) => {
  const [selectedButton, setSelectedButton] = useState<ButtonProps>(defaultButton || groupButtons[0]);
  const onPress = (button) => () => {
    setSelectedButton(button);
    button.onPress && button.onPress();
  };
  return (
    <GroupContainer ref={ref} {...rest} orientation="horizontal">
      {groupButtons.map((child, index) => (
        <Button key={index} onPress={onPress(child)} {...child} hierarchy="secondary-gray" />
      ))}
      {groupContent && groupContent(selectedButton)}
    </GroupContainer>
  );
};

export const ButtonGroups = forwardRef(_ButtonGroups);
