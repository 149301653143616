import {GEN_PATH_ROOT_KEY} from '../../../../config';
import {PredefinedPath} from '../../models';

import {determineType} from './determineType';

export function generatePathsFromList(input: unknown[], keyPath = GEN_PATH_ROOT_KEY) {
  const result: PredefinedPath[] = [];
  const keyValues = new Map();

  function processObjectArray(array: unknown, parentKey = '') {
    Array.isArray(array) &&
      array.forEach((item) => {
        if (typeof item === 'object') {
          Object.entries(item).forEach(([key, value]) => {
            const currentKey = parentKey ? `${parentKey}.${key}` : key;

            if (typeof value === 'object' && value !== null) {
              if (Array.isArray(value)) {
                if (value.length > 0 && typeof value[0] === 'object') {
                  processObjectArray(value, currentKey);
                } else {
                  if (!keyValues.has(currentKey)) keyValues.set(currentKey, value);
                }
              } else {
                if (!keyValues.has(currentKey)) keyValues.set(currentKey, value);
                processObjectArray([value], currentKey);
              }
            } else {
              if (!keyValues.has(currentKey)) keyValues.set(currentKey, value);
            }
          });
        }
      });
  }

  function createResult() {
    keyValues.forEach((values, key) => {
      const type = determineType(values);

      result.push({
        jsonPath: `${keyPath}.${key}`,
        name: `${keyPath}.${key}`,
        type: type,
      });
    });
  }

  processObjectArray(input);
  createResult();

  return result;
}
