import React, {ReactNode} from 'react';

import {ViewProps} from '../View';
import {XStack} from '../XStack';

export interface BreadcrumbTypes extends ViewProps {
  prefix?: ReactNode;
  children: ReactNode;
}

export const Breadcrumb = ({prefix, children, ...props}: BreadcrumbTypes) => {
  return (
    <XStack
      borderColor="$border-secondary"
      borderBottomWidth="$0.5"
      borderTopWidth="$0.5"
      paddingHorizontal="$2"
      $sm={{paddingHorizontal: '$4'}}
      height="$3.5"
      gap="$1.5"
      alignItems="center"
      {...props}>
      {prefix}
      {children}
    </XStack>
  );
};
