import {
  COLORS,
  BLUE,
  GREEN,
  BRAND,
  BLUE_DARK,
  BLUE_LIGHT,
  ERROR,
  FUCHSIA,
  GRAY_BLUE,
  INDIGO,
  ORANGE,
  ORANGE_DARK,
  PINK,
  PURPLE,
  SUCCESS,
  WARNING,
  YELLOW,
} from './colors';

export const DARK_GRAY = {
  'gray-25': '#fafafa',
  'gray-50': '#f7f7f7',
  'gray-100': '#f0f0f1',
  'gray-200': '#ececed',
  'gray-300': '#cecfd2',
  'gray-400': '#94979c',
  'gray-500': '#85888e',
  'gray-600': '#61656c',
  'gray-700': '#373a41',
  'gray-800': '#242426',
  'gray-900': '#19191b',
  'gray-950': '#111112',
};

export const DARK_COLORS = {
  highlightColor: 'gray',
  placeholder: COLORS.white,
  background: '#18191A',
  textColor: COLORS.white,
  neutral: '#292929',
  border: '#383A3C',
  accentLight: 'lightgray',
  'tooltip-supporting-text': DARK_GRAY['gray-300'],
  'screen-mockup-border': DARK_GRAY['gray-700'],
  'app-store-badge-border': COLORS.white,
};

export const DARK_GRAY_ALPHA = {
  'gray-alpha-25': '#fffffffa',
  'gray-alpha-50': '#fffffff5',
  'gray-alpha-100': '#fffffff0',
  'gray-alpha-200': '#ffffffeb',
  'gray-alpha-300': '#ffffffcc',
  'gray-alpha-400': '#ffffff8f',
  'gray-alpha-500': '#ffffff80',
  'gray-alpha-600': '#ffffff59',
  'gray-alpha-700': '#ffffff29',
  'gray-alpha-800': '#ffffff14',
  'gray-alpha-900': '#ffffff0a',
  'gray-alpha-950': '#ffffff00',
};

export const DARK_ALPHA = {
  'alpha-black-10': '#ffffff1a',
  'alpha-black-100': '#ffffff',
  'alpha-black-20': '#ffffff33',
  'alpha-black-30': '#ffffff4d',
  'alpha-black-40': '#ffffff66',
  'alpha-black-50': '#ffffff80',
  'alpha-black-60': '#ffffff99',
  'alpha-black-70': '#ffffffb2',
  'alpha-black-80': '#ffffffcc',
  'alpha-black-90': '#ffffffe5',
  'alpha-white-10': '#0c0e121a',
  'alpha-white-100': DARK_GRAY['gray-950'],
  'alpha-white-20': '#0c0e1233',
  'alpha-white-30': '#0c0e124d',
  'alpha-white-40': '#0c0e1266',
  'alpha-white-50': '#0c0e1280',
  'alpha-white-60': '#0c0e1299',
  'alpha-white-70': '#0c0e12b2',
  'alpha-white-80': '#0c0e12cc',
  'alpha-white-90': '#0c0e12e5',
};

export const DARK_TEXT = {
  'text-brand-primary': DARK_GRAY['gray-50'],
  'text-brand-secondary': DARK_GRAY['gray-300'],
  'text-brand-tertiary': DARK_GRAY['gray-400'],
  'text-brand-tertiary-alt': DARK_GRAY['gray-50'],
  'text-disabled': DARK_GRAY['gray-500'],
  'text-error-primary': ERROR['error-400'],
  'text-placeholder': DARK_GRAY['gray-500'],
  'text-placeholder-subtle': DARK_GRAY['gray-700'],
  'text-primary': DARK_GRAY['gray-50'],
  'text-primary-on-brand': DARK_GRAY['gray-50'],
  'text-quaternary': DARK_GRAY['gray-400'],
  'text-quaternary-on-brand': DARK_GRAY['gray-400'],
  'text-secondary': DARK_GRAY['gray-300'],
  'text-secondary-hover': DARK_GRAY['gray-200'],
  'text-secondary-on-brand': DARK_GRAY['gray-300'],
  'text-success-primary': SUCCESS['success-400'],
  'text-tertiary': DARK_GRAY['gray-400'],
  'text-tertiary-hover': DARK_GRAY['gray-300'],
  'text-tertiary-on-brand': DARK_GRAY['gray-400'],
  'text-warning-primary': WARNING['warning-400'],
  'text-white': COLORS.white,
};

export const DARK_FOREGROUND = {
  'fg-brand-primary': BRAND['brand-500'],
  'fg-brand-primary-alt': DARK_GRAY['gray-300'],
  'fg-brand-secondary': BRAND['brand-500'],
  'fg-disabled': DARK_GRAY['gray-500'],
  'fg-disabled-subtle': DARK_GRAY['gray-600'],
  'fg-error-primary': ERROR['error-500'],
  'fg-error-secondary': ERROR['error-400'],
  'fg-primary': COLORS.white,
  'fg-quaternary': DARK_GRAY['gray-400'],
  'fg-quaternary-hover': DARK_GRAY['gray-300'],
  'fg-quinary': DARK_GRAY['gray-500'],
  'fg-quinary-hover': DARK_GRAY['gray-400'],
  'fg-secondary': DARK_GRAY['gray-300'],
  'fg-secondary-hover': DARK_GRAY['gray-200'],
  'fg-senary': DARK_GRAY['gray-600'],
  'fg-success-primary': SUCCESS['success-500'],
  'fg-success-secondary': SUCCESS['success-400'],
  'fg-tertiary': DARK_GRAY['gray-400'],
  'fg-tertiary-hover': DARK_GRAY['gray-300'],
  'fg-warning-primary': WARNING['warning-500'],
  'fg-warning-secondary': WARNING['warning-400'],
  'fg-white': COLORS.white,
};

export const DARK_BORDER = {
  'border-brand': BRAND['brand-400'],
  'border-brand-alt': DARK_GRAY['gray-700'],
  'border-disabled': DARK_GRAY['gray-700'],
  'border-disabled-subtle': DARK_GRAY['gray-800'],
  'border-error': ERROR['error-400'],
  'border-error-subtle': ERROR['error-400'],
  'border-primary': DARK_GRAY['gray-700'],
  'border-secondary': DARK_GRAY['gray-800'],
  'border-tertiary': DARK_GRAY['gray-800'],
};

export const DARK_BACKGROUND = {
  'bg-active': DARK_GRAY['gray-800'],
  'bg-brand-primary': BRAND['brand-500'],
  'bg-brand-primary-alt': DARK_GRAY['gray-950'],
  'bg-brand-secondary': BRAND['brand-600'],
  'bg-brand-section': DARK_GRAY['gray-950'],
  'bg-brand-section-subtle': DARK_GRAY['gray-900'],
  'bg-brand-solid': BRAND['brand-600'],
  'bg-brand-solid-hover': BRAND['brand-500'],
  'bg-disabled': DARK_GRAY['gray-800'],
  'bg-disabled-subtle': DARK_GRAY['gray-900'],
  'bg-error-primary': ERROR['error-500'],
  'bg-error-secondary': ERROR['error-600'],
  'bg-error-solid': ERROR['error-600'],
  'bg-overlay': DARK_GRAY['gray-800'],
  'bg-primary': DARK_GRAY['gray-900'],
  'bg-primary-solid': DARK_GRAY['gray-950'],
  'bg-primary-alt': DARK_GRAY['gray-950'],
  'bg-primary-hover': DARK_GRAY['gray-800'],
  'bg-quaternary': DARK_GRAY['gray-700'],
  'bg-secondary': DARK_GRAY['gray-950'],
  'bg-secondary-solid': DARK_GRAY['gray-600'],
  'bg-secondary-alt': DARK_GRAY['gray-900'],
  'bg-secondary-hover': DARK_GRAY['gray-800'],
  'bg-secondary-subtle': DARK_GRAY['gray-900'],
  'bg-success-primary': SUCCESS['success-500'],
  'bg-success-secondary': SUCCESS['success-600'],
  'bg-success-solid': SUCCESS['success-600'],
  'bg-tertiary': DARK_GRAY['gray-800'],
  'bg-warning-primary': WARNING['warning-500'],
  'bg-warning-secondary': WARNING['warning-600'],
  'bg-warning-solid': WARNING['warning-600'],
};

export const DARK_NAV = {
  'nav-item-bg': DARK_GRAY['gray-900'],
  'nav-item-bg-primary-active': BRAND['brand-500'],
  'nav-item-bg-primary-active-hover': BRAND['brand-600'],
  'nav-item-bg-secondary-active': BRAND['brand-900'],
  'nav-item-bg-secondary-active-hover': BRAND['brand-800'],
  'nav-item-bg-hover': DARK_GRAY['gray-800'],
  'nav-item-button-primary-icon-fg': DARK_GRAY['gray-400'],
  'nav-item-button-primary-icon-fg-active': DARK_GRAY['gray-300'],
  'nav-item-icon-fg': DARK_GRAY['gray-400'],
  'nav-item-icon-fg-primary-active': COLORS.white,
  'nav-item-icon-fg-primary-active-hover': DARK_GRAY['gray-300'],
  'nav-item-icon-fg-hover': DARK_GRAY['gray-400'],
  'nav-item-text': DARK_GRAY['gray-200'],
  'nav-item-text-primary-active': BRAND['brand-50'],
  'nav-item-text-primary-active-hover': BRAND['brand-100'],
  'nav-item-text-secondary-active': BRAND['brand-100'],
  'nav-item-text-secondary-active-hover': DARK_GRAY['gray-400'],
  'nav-item-text-hover': DARK_GRAY['gray-50'],
};

export const DARK_AVATAR = {
  'avatar-bg': DARK_GRAY['gray-800'],
  'avatar-contrast-border': '#ffffff1f',
  'avatar-profile-photo-border': DARK_GRAY['gray-950'],
  'avatar-styles-bg-neutral': '#e0e0e0',
};

export const DARK_BREADCRUMB = {
  'breadcrumb-bg-hover': DARK_GRAY['gray-800'],
  'breadcrumb-brand-bg-hover': DARK_GRAY['gray-800'],
  'breadcrumb-brand-fg-hover': COLORS.white,
  'breadcrumb-brand-icon-fg-hover': COLORS.white,
  'breadcrumb-fg': DARK_GRAY['gray-300'],
  'breadcrumb-fg-hover': COLORS.white,
  'breadcrumb-icon-fg': DARK_GRAY['gray-400'],
  'breadcrumb-icon-fg-hover': COLORS.white,
};

export const DARK_BUTTON_PRIMARY = {
  'button-primary-bg': BRAND['brand-600'],
  'button-primary-bg-hover': BRAND['brand-700'],
  'button-primary-fg': COLORS.white,
  'button-primary-fg-hover': COLORS.white,
  'button-primary-error-bg': ERROR['error-600'],
  'button-primary-error-bg-hover': ERROR['error-700'],
  'button-primary-error-fg': COLORS.white,
  'button-primary-error-fg-hover': COLORS.white,
};

export const DARK_BUTTON_SECONDARY = {
  'button-secondary-bg': DARK_GRAY['gray-900'],
  'button-secondary-bg-hover': DARK_GRAY['gray-800'],
  'button-secondary-border': DARK_GRAY['gray-700'],
  'button-secondary-border-hover': DARK_GRAY['gray-700'],
  'button-secondary-fg': DARK_GRAY['gray-300'],
  'button-secondary-fg-hover': DARK_GRAY['gray-100'],
  'button-secondary-color-bg': DARK_GRAY['gray-900'],
  'button-secondary-color-bg-hover': DARK_GRAY['gray-800'],
  'button-secondary-color-border': DARK_GRAY['gray-700'],
  'button-secondary-color-border-hover': DARK_GRAY['gray-700'],
  'button-secondary-color-fg': DARK_GRAY['gray-300'],
  'button-secondary-color-fg-hover': DARK_GRAY['gray-100'],
  'button-secondary-error-bg': ERROR['error-950'],
  'button-secondary-error-bg-hover': ERROR['error-900'],
  'button-secondary-error-border': ERROR['error-800'],
  'button-secondary-error-border-hover': ERROR['error-700'],
  'button-secondary-error-fg': ERROR['error-200'],
  'button-secondary-error-fg-hover': ERROR['error-100'],
};

export const DARK_BUTTON_TERTIARY = {
  'button-tertiary-bg-hover': DARK_GRAY['gray-800'],
  'button-tertiary-fg': DARK_GRAY['gray-400'],
  'button-tertiary-fg-hover': DARK_GRAY['gray-200'],
  'button-tertiary-color-bg-hover': DARK_GRAY['gray-800'],
  'button-tertiary-color-fg': DARK_GRAY['gray-300'],
  'button-tertiary-color-fg-hover': DARK_GRAY['gray-100'],
  'button-tertiary-error-bg-hover': ERROR['error-900'],
  'button-tertiary-error-fg': ERROR['error-300'],
  'button-tertiary-error-fg-hover': ERROR['error-200'],
};

export const DARK_FOOTER_BADGE = {
  'footer-badge-bg': '#ffffff1a',
  'footer-badge-border': '#ffffff4d',
  'footer-badge-fg': COLORS.white,
  'footer-button-fg': DARK_GRAY['gray-300'],
  'footer-button-fg-hover': DARK_GRAY['gray-100'],
};

export const DARK_HEADER_ABSTRACT = {
  'header-abstract-100-bg': DARK_GRAY['gray-800'],
  'header-abstract-200-bg': DARK_GRAY['gray-700'],
  'header-abstract-300-bg': DARK_GRAY['gray-600'],
  'header-abstract-50-bg': DARK_GRAY['gray-900'],
};

export const DARK_ICON = {
  'icon-dark-fg-brand': BRAND['brand-200'],
  'icon-dark-fg-error': ERROR['error-200'],
  'icon-dark-fg-gray': DARK_GRAY['gray-200'],
  'icon-dark-fg-success': SUCCESS['success-200'],
  'icon-dark-fg-warning': WARNING['warning-200'],
  'icon-light-fg-brand': BRAND['brand-200'],
  'icon-light-fg-error': ERROR['error-200'],
  'icon-light-fg-gray': DARK_GRAY['gray-200'],
  'icon-light-fg-success': SUCCESS['success-200'],
  'icon-light-fg-warning': WARNING['warning-200'],
  'icon-modern-border': DARK_GRAY['gray-700'],
  'icon-fg-brand': DARK_GRAY['gray-400'],
  'icon-fg-brand-on-brand': DARK_GRAY['gray-400'],
};

export const DARK_TOGGLE = {
  'toggle-border': COLORS.transparent,
  'toggle-button-fg-disabled': DARK_GRAY['gray-600'],
  'toggle-slim-border-pressed': COLORS.transparent,
  'toggle-slim-border-pressed-hover': COLORS.transparent,
};

export const DARK_SOCIAL_ICON = {
  'social-icon-fg-angellist': COLORS.white,
  'social-icon-fg-apple': COLORS.white,
  'social-icon-fg-github': COLORS.white,
  'social-icon-fg-instagram': COLORS.white,
  'social-icon-fg-tumblr': COLORS.white,
  'social-icon-fg-x': COLORS.white,
};

export const DARK_SLIDER = {
  'slider-handle-bg': BRAND['brand-500'],
  'slider-handle-border': DARK_GRAY['gray-900'],
};

export const DARK_THUMBNAIL = {
  'thumbnail-badge-brand-fg': DARK_GRAY['gray-300'],
  'thumbnail-badge-success-fg': DARK_GRAY['gray-300'],
};

export const DARK_EDITOR = {
  'editor-icon-fg': DARK_GRAY['gray-400'],
  'editor-icon-fg-active': COLORS.white,
};

export const DARK_UTILITY_YELLOW = {
  'utility-yellow-100': YELLOW['yellow-900'],
  'utility-yellow-200': YELLOW['yellow-800'],
  'utility-yellow-300': YELLOW['yellow-700'],
  'utility-yellow-400': YELLOW['yellow-600'],
  'utility-yellow-50': YELLOW['yellow-950'],
  'utility-yellow-500': YELLOW['yellow-500'],
  'utility-yellow-600': YELLOW['yellow-400'],
  'utility-yellow-700': YELLOW['yellow-300'],
};

export const DARK_UTILITY_BRAND = {
  'utility-brand-100': BRAND['brand-900'],
  'utility-brand-100-alt': DARK_GRAY['gray-800'],
  'utility-brand-200': BRAND['brand-800'],
  'utility-brand-200-alt': DARK_GRAY['gray-700'],
  'utility-brand-300': BRAND['brand-700'],
  'utility-brand-300-alt': DARK_GRAY['gray-700'],
  'utility-brand-400': BRAND['brand-600'],
  'utility-brand-400-alt': DARK_GRAY['gray-600'],
  'utility-brand-50': BRAND['brand-950'],
  'utility-brand-500': BRAND['brand-500'],
  'utility-brand-500-alt': DARK_GRAY['gray-500'],
  'utility-brand-50-alt': DARK_GRAY['gray-900'],
  'utility-brand-600': BRAND['brand-400'],
  'utility-brand-600-alt': DARK_GRAY['gray-400'],
  'utility-brand-700': BRAND['brand-300'],
  'utility-brand-700-alt': DARK_GRAY['gray-300'],
  'utility-brand-800': BRAND['brand-200'],
  'utility-brand-800-alt': DARK_GRAY['gray-200'],
  'utility-brand-900': BRAND['brand-100'],
  'utility-brand-900-alt': DARK_GRAY['gray-100'],
};

export const DARK_UTILITY_BLUE_LIGHT = {
  'utility-blue-light-100': BLUE_LIGHT['blue-light-900'],
  'utility-blue-light-200': BLUE_LIGHT['blue-light-800'],
  'utility-blue-light-300': BLUE_LIGHT['blue-light-700'],
  'utility-blue-light-400': BLUE_LIGHT['blue-light-600'],
  'utility-blue-light-50': BLUE_LIGHT['blue-light-950'],
  'utility-blue-light-500': BLUE_LIGHT['blue-light-500'],
  'utility-blue-light-600': BLUE_LIGHT['blue-light-400'],
  'utility-blue-light-700': BLUE_LIGHT['blue-light-300'],
};

export const DARK_UTILITY_BLUE = {
  'utility-blue-100': BLUE['blue-900'],
  'utility-blue-200': BLUE['blue-800'],
  'utility-blue-300': BLUE['blue-700'],
  'utility-blue-400': BLUE['blue-600'],
  'utility-blue-50': BLUE['blue-950'],
  'utility-blue-500': BLUE['blue-500'],
  'utility-blue-600': BLUE['blue-400'],
  'utility-blue-700': BLUE['blue-300'],
};

export const DARK_UTILITY_BLUE_DARK = {
  'utility-blue-dark-100': BLUE_DARK['blue-dark-900'],
  'utility-blue-dark-200': BLUE_DARK['blue-dark-800'],
  'utility-blue-dark-300': BLUE_DARK['blue-dark-700'],
  'utility-blue-dark-400': BLUE_DARK['blue-dark-600'],
  'utility-blue-dark-50': BLUE_DARK['blue-dark-950'],
  'utility-blue-dark-500': BLUE_DARK['blue-dark-500'],
  'utility-blue-dark-600': BLUE_DARK['blue-dark-400'],
  'utility-blue-dark-700': BLUE_DARK['blue-dark-300'],
};

export const DARK_UTILITY_ERROR = {
  'utility-error-100': ERROR['error-900'],
  'utility-error-200': ERROR['error-800'],
  'utility-error-300': ERROR['error-700'],
  'utility-error-400': ERROR['error-600'],
  'utility-error-50': ERROR['error-950'],
  'utility-error-500': ERROR['error-500'],
  'utility-error-600': ERROR['error-400'],
  'utility-error-700': ERROR['error-300'],
};

export const DARK_UTILITY_FUCHSIA = {
  'utility-fuchsia-100': FUCHSIA['fuchsia-900'],
  'utility-fuchsia-200': FUCHSIA['fuchsia-800'],
  'utility-fuchsia-300': FUCHSIA['fuchsia-700'],
  'utility-fuchsia-400': FUCHSIA['fuchsia-600'],
  'utility-fuchsia-50': FUCHSIA['fuchsia-950'],
  'utility-fuchsia-500': FUCHSIA['fuchsia-500'],
  'utility-fuchsia-600': FUCHSIA['fuchsia-400'],
  'utility-fuchsia-700': FUCHSIA['fuchsia-300'],
};

export const DARK_UTILITY_GRAY_BLUE = {
  'utility-gray-blue-100': GRAY_BLUE['gray-blue-900'],
  'utility-gray-blue-200': GRAY_BLUE['gray-blue-800'],
  'utility-gray-blue-300': GRAY_BLUE['gray-blue-700'],
  'utility-gray-blue-400': GRAY_BLUE['gray-blue-600'],
  'utility-gray-blue-50': GRAY_BLUE['gray-blue-950'],
  'utility-gray-blue-500': GRAY_BLUE['gray-blue-500'],
  'utility-gray-blue-600': GRAY_BLUE['gray-blue-400'],
  'utility-gray-blue-700': GRAY_BLUE['gray-blue-300'],
};

export const DARK_UTILITY_DARK_GRAY = {
  'utility-gray-100': DARK_GRAY['gray-800'],
  'utility-gray-200': DARK_GRAY['gray-700'],
  'utility-gray-300': DARK_GRAY['gray-700'],
  'utility-gray-400': DARK_GRAY['gray-600'],
  'utility-gray-50': DARK_GRAY['gray-900'],
  'utility-gray-500': DARK_GRAY['gray-500'],
  'utility-gray-600': DARK_GRAY['gray-400'],
  'utility-gray-700': DARK_GRAY['gray-300'],
  'utility-gray-800': DARK_GRAY['gray-200'],
  'utility-gray-900': DARK_GRAY['gray-100'],
};

export const DARK_UTILITY_GREEN = {
  'utility-green-100': GREEN['green-900'],
  'utility-green-200': GREEN['green-800'],
  'utility-green-300': GREEN['green-700'],
  'utility-green-400': GREEN['green-600'],
  'utility-green-50': GREEN['green-950'],
  'utility-green-500': GREEN['green-500'],
  'utility-green-600': GREEN['green-400'],
  'utility-green-700': GREEN['green-300'],
};

export const DARK_UTILITY_INDIGO = {
  'utility-indigo-100': INDIGO['indigo-900'],
  'utility-indigo-200': INDIGO['indigo-800'],
  'utility-indigo-300': INDIGO['indigo-700'],
  'utility-indigo-400': INDIGO['indigo-600'],
  'utility-indigo-50': INDIGO['indigo-950'],
  'utility-indigo-500': INDIGO['indigo-500'],
  'utility-indigo-600': INDIGO['indigo-400'],
  'utility-indigo-700': INDIGO['indigo-300'],
};

export const DARK_UTILITY_ORANGE = {
  'utility-orange-100': ORANGE['orange-900'],
  'utility-orange-200': ORANGE['orange-800'],
  'utility-orange-300': ORANGE['orange-700'],
  'utility-orange-400': ORANGE['orange-600'],
  'utility-orange-50': ORANGE['orange-950'],
  'utility-orange-500': ORANGE['orange-500'],
  'utility-orange-600': ORANGE['orange-400'],
  'utility-orange-700': ORANGE['orange-300'],
};

export const DARK_UTILITY_ORANGE_DARK = {
  'utility-orange-dark-100': ORANGE_DARK['orange-dark-900'],
  'utility-orange-dark-200': ORANGE_DARK['orange-dark-800'],
  'utility-orange-dark-300': ORANGE_DARK['orange-dark-700'],
  'utility-orange-dark-400': ORANGE_DARK['orange-dark-600'],
  'utility-orange-dark-50': ORANGE_DARK['orange-dark-950'],
  'utility-orange-dark-500': ORANGE_DARK['orange-dark-500'],
  'utility-orange-dark-600': ORANGE_DARK['orange-dark-400'],
  'utility-orange-dark-700': ORANGE_DARK['orange-dark-300'],
};

export const DARK_UTILITY_PINK = {
  'utility-pink-100': PINK['pink-900'],
  'utility-pink-200': PINK['pink-800'],
  'utility-pink-300': PINK['pink-700'],
  'utility-pink-400': PINK['pink-600'],
  'utility-pink-50': PINK['pink-950'],
  'utility-pink-500': PINK['pink-500'],
  'utility-pink-600': PINK['pink-400'],
  'utility-pink-700': PINK['pink-300'],
};

export const DARK_UTILITY_PURPLE = {
  'utility-purple-100': PURPLE['purple-900'],
  'utility-purple-200': PURPLE['purple-800'],
  'utility-purple-300': PURPLE['purple-700'],
  'utility-purple-400': PURPLE['purple-600'],
  'utility-purple-50': PURPLE['purple-950'],
  'utility-purple-500': PURPLE['purple-500'],
  'utility-purple-600': PURPLE['purple-400'],
  'utility-purple-700': PURPLE['purple-300'],
};
export const DARK_UTILITY_WARNING = {
  'utility-warning-100': WARNING['warning-900'],
  'utility-warning-200': WARNING['warning-800'],
  'utility-warning-300': WARNING['warning-700'],
  'utility-warning-400': WARNING['warning-600'],
  'utility-warning-50': WARNING['warning-950'],
  'utility-warning-500': WARNING['warning-500'],
  'utility-warning-600': WARNING['warning-400'],
  'utility-warning-700': WARNING['warning-300'],
};

export const DARK_UTILITY_SUCCESS = {
  'utility-success-100': SUCCESS['success-900'],
  'utility-success-200': SUCCESS['success-800'],
  'utility-success-300': SUCCESS['success-700'],
  'utility-success-400': SUCCESS['success-600'],
  'utility-success-50': SUCCESS['success-950'],
  'utility-success-500': SUCCESS['success-500'],
  'utility-success-600': SUCCESS['success-400'],
  'utility-success-700': SUCCESS['success-300'],
};
