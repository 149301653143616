import React from 'react';

import {TouchableOpacity, StyleProp, ViewStyle, FlexStyle} from 'react-native';

import {Typography} from '../Typography';
import {View, ViewProps} from '../View';

export type PressableProps = {
  isDisabled?: boolean;
  onPress?: () => void;
  styles?: StyleProp<ViewStyle>;
  alignItems?: FlexStyle['alignItems'];
  flexDirection?: FlexStyle['flexDirection'];
  justifyContent?: FlexStyle['justifyContent'];
  flex?: FlexStyle['flex'];
  activeOpacity?: number;
  children: any;
} & ViewProps;

export const Pressable = ({
  isDisabled,
  alignItems,
  justifyContent,
  flexDirection,
  onPress,
  children,
  activeOpacity,
  styles,
  ...props
}: PressableProps) => {
  const handlePress = () => {
    if (!isDisabled) {
      onPress && onPress();
    }
  };

  return (
    <View {...props}>
      <TouchableOpacity
        onPress={handlePress}
        disabled={isDisabled}
        activeOpacity={activeOpacity}
        style={[
          {
            backgroundColor: 'transparent',
            alignItems: alignItems || 'center',
            justifyContent,
            flexDirection: flexDirection || 'row',
            flex: 1,
            height: '100%',
            gap: 6,
          },
          styles,
        ]}>
        {typeof children === 'string' ? <Typography>{children}</Typography> : children}
      </TouchableOpacity>
    </View>
  );
};
