import React from 'react';

import {XStack, YStack} from 'tamagui';

import {ProgressBar} from '../ProgressBar';
import {CoreTrans} from '../ScopedTrans';
import {Typography} from '../Typography';
import {View, ViewProps} from '../View';

export type FileUploadProgressProps = {
  progress: number;
  fileName: string;
  fileSize: number; // in bytes
  error?: string;
};

export const FileUploadProgress = ({
  fileName,
  fileSize,
  progress,
  error = '',
  ...props
}: FileUploadProgressProps & ViewProps) => {
  const isErrored = !!error;
  const isLoaded = progress === 100;
  const isUploading = !isErrored && progress > 0 && progress < 100;
  const sizeInMB = (fileSize / (1024 * 1024)).toFixed(2);
  return (
    <View
      padding="$4"
      gap="$4"
      backgroundColor="$bg-primary"
      borderRadius={6}
      borderWidth={1}
      borderColor={isErrored ? '$border-error' : '$border-secondary'}
      {...props}>
      <XStack gap="$1" justifyContent="space-between">
        <Typography numberOfLines={1} fontWeight="700" variant="body2" color="$text-secondary">
          {fileName}
        </Typography>
        <Typography fontWeight="400" fontSize="$14" minWidth={50} color="$text-secondary">
          {sizeInMB} <CoreTrans i18nKey="fileUploader.MB" />
        </Typography>
      </XStack>
      <YStack>
        <ProgressBar value={progress} animation={null} color={isErrored ? '$bg-error-solid' : '$fg-brand-primary'} />
        <XStack gap="$1" justifyContent="space-between">
          <Typography numberOfLines={1} fontWeight="700" variant="body2" color="$text-secondary">
            {isUploading && <CoreTrans i18nKey="fileUploader.uploading" />}
            {isLoaded && !isErrored && <CoreTrans i18nKey="fileUploader.fileUploadedSuccessfully" />}
            {isErrored && error}
          </Typography>
          <Typography numberOfLines={1} fontWeight="700" fontSize="$14" color="$text-secondary">
            {progress.toFixed(2)} %
          </Typography>
        </XStack>
      </YStack>
    </View>
  );
};
