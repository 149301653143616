import {Query, Category, RelationOperator} from '../types';

import {evaluateCondition} from './evaluateCondition';

export function evaluateQuery(query: Query): boolean {
  let result: null | boolean = null;
  let currentRelation: RelationOperator | null | boolean = null;

  for (const item of query) {
    if (item.type === Category.condition) {
      const conditionResult = evaluateCondition(item.data);

      if (result === null) {
        result = conditionResult;
      } else if (currentRelation === 'AND') {
        result = result && conditionResult;
      } else if (currentRelation === 'OR') {
        result = result || conditionResult;
      }
    } else if (item.type === Category.relation) {
      currentRelation = item.data;
    } else if (item.type === Category.group) {
      const groupResult = evaluateQuery(item.data);

      if (result === null) {
        result = groupResult;
      } else if (currentRelation === 'AND') {
        result = result && groupResult;
      } else if (currentRelation === 'OR') {
        result = result || groupResult;
      }
    }
  }

  return result ?? false;
}
