import React from 'react';

import {useDropzone} from 'react-dropzone';
import {YStack} from 'tamagui';

import {IconView} from '../IconView';
import {CoreTrans} from '../ScopedTrans';
import {Typography} from '../Typography';
import {View} from '../View';

import {DropzoneUploadProps, UnifiedFile} from './types';

export * from './types';

export const DropzoneUpload = ({onFilesSelected, dropzoneOptions}: DropzoneUploadProps) => {
  const onDrop = async (acceptedFiles: File[]) => {
    const unifiedFiles: UnifiedFile[] = acceptedFiles.map((file) => ({
      uri: file,
      name: file.name,
      type: file.type,
      size: file.size,
    }));
    onFilesSelected?.(unifiedFiles);
  };
  const {
    getRootProps = () => {},
    getInputProps = () => {},
    isDragActive = false,
  } = useDropzone({
    onDrop,
    ...dropzoneOptions,
  });

  return (
    <>
      <View
        flexDirection="column"
        borderRadius="$rounded-lg"
        borderColor="$gray-200"
        borderWidth={1}
        overflow="hidden"
        borderStyle="dashed">
        <div
          {...getRootProps()}
          style={{
            flex: 1,
            backgroundColor: '$gray-50',
            cursor: 'pointer',
          }}>
          <input {...getInputProps()} />
          <View alignItems="center" justifyContent="center" padding="$8">
            <YStack justifyContent="center" alignItems="center" gap={10}>
              <IconView icon="Csv02Icon" size={48} color="$gray-400" />
              <YStack>
                <Typography color="$gray-600">
                  {isDragActive ? (
                    <CoreTrans i18nKey="fileUploader.dropItHere" />
                  ) : (
                    <CoreTrans i18nKey="fileUploader.dragAndDropOrClickHere" />
                  )}
                </Typography>
              </YStack>
            </YStack>
          </View>
        </div>
      </View>
    </>
  );
};
