export const COLORS = {
  accent: 'gray',
  disabled: '#DDE4EE',
  subText: '#4B5563',
  transparent: '#ffffff00',
  borderHover: '#64748B',
  grey: '#73738C',
  lightWhite: '#ffffff30',
  black: '#000000',
  white: '#FFFFFF',
};

export const BLUE = {
  'blue-25': '#F5FAFF',
  'blue-50': '#EFF8FF',
  'blue-100': '#D1E9FF',
  'blue-200': '#B2DDFF',
  'blue-300': '#84CAFF',
  'blue-400': '#53B1FD',
  'blue-500': '#2E90FA',
  'blue-600': '#1570EF',
  'blue-700': '#175CD3',
  'blue-800': '#1849A9',
  'blue-900': '#194185',
  'blue-950': '#102A56',
};

export const GREEN = {
  'green-25': '#F6FEF9',
  'green-50': '#EDFCF2 ',
  'green-100': '#D3F8DF',
  'green-200': '#AAF0C4',
  'green-300': '#73E2A3',
  'green-400': '#3CCB7F',
  'green-500': '#16B364',
  'green-600': '#099250',
  'green-700': '#087443',
  'green-800': '#095C37',
  'green-900': '#084C2E',
  'green-950': '#052E1C',
};

export const BRAND = {
  'brand-25': '#f2f5fa',
  'brand-50': '#e9eef7',
  'brand-100': '#d0dcef',
  'brand-200': '#b4c8e6',
  'brand-300': '#93b1dd',
  'brand-400': '#6898d4',
  'brand-500': '#0079ca',
  'brand-600': '#006fb8',
  'brand-700': '#0063a5',
  'brand-800': '#00568f',
  'brand-900': '#004675',
  'brand-950': '#003152',
};

export const ERROR = {
  'error-25': '#FFFBFA',
  'error-50': '#FEF3F2',
  'error-100': '#FEE4E2',
  'error-200': '#FECDCA',
  'error-300': '#FDA29B',
  'error-400': '#F97066',
  'error-500': '#F04438',
  'error-600': '#D92D20',
  'error-700': '#B42318',
  'error-800': '#912018',
  'error-900': '#7A271A',
  'error-950': '#55160C',
};

export const WARNING = {
  'warning-25': '#FFFCF5',
  'warning-50': '#FFFAEB',
  'warning-100': '#FEF0C7',
  'warning-200': '#FEDF89',
  'warning-300': '#FEC84B',
  'warning-400': '#FDB022',
  'warning-500': '#F79009',
  'warning-600': '#DC6803',
  'warning-700': '#B54708',
  'warning-800': '#93370D',
  'warning-900': '#7A2E0E',
  'warning-950': '#4E1D09',
};

export const SUCCESS = {
  'success-25': '#F6FEF9',
  'success-50': '#ECFDF3',
  'success-100': '#DCFAE6',
  'success-200': '#ABEFC6',
  'success-300': '#75E0A7',
  'success-400': '#47CD89',
  'success-500': '#17B26A',
  'success-600': '#079455',
  'success-700': '#067648',
  'success-800': '#085D3A',
  'success-900': '#074D31',
  'success-950': '#053321',
};

export const GRAY_BLUE = {
  'gray-blue-25': '#FCFCFD',
  'gray-blue-50': '#F8F9FC',
  'gray-blue-100': '#EAECF5',
  'gray-blue-200': '#D5D9EB',
  'gray-blue-300': '#B3B8DB',
  'gray-blue-400': '#717BBC',
  'gray-blue-500': '#4E5BA6',
  'gray-blue-600': '#3E4784',
  'gray-blue-700': '#363F72',
  'gray-blue-800': '#293056',
  'gray-blue-900': '#101323',
  'gray-blue-950': '#0D0F1C',
};

export const GRAY_COOL = {
  'gray-cool-25': '#FCFCFD',
  'gray-cool-50': '#F9F9FB',
  'gray-cool-100': '#EFF1F5',
  'gray-cool-200': '#DCDFEA',
  'gray-cool-300': '#B9C0D4',
  'gray-cool-400': '#7D89B0',
  'gray-cool-500': '#5D6B98',
  'gray-cool-600': '#4A5578',
  'gray-cool-700': '#404968',
  'gray-cool-800': '#30374A',
  'gray-cool-900': '#111322',
  'gray-cool-950': '#0E101B',
};

export const GRAY_MODERN = {
  'gray-modern-25': '#FCFCFD',
  'gray-modern-50': '#F8FAFC',
  'gray-modern-100': '#EEF2F6',
  'gray-modern-200': '#E3E8EF',
  'gray-modern-300': '#CDD5DF',
  'gray-modern-400': '#9AA4B2',
  'gray-modern-500': '#697586',
  'gray-modern-600': '#4B5565',
  'gray-modern-700': '#364152',
  'gray-modern-800': '#202939',
  'gray-modern-900': '#121926',
  'gray-modern-950': '#0D121C',
};

export const GRAY_NEUTRAL = {
  'gray-neutral-25': '#FCFCFD',
  'gray-neutral-50': '#F9FAFB',
  'gray-neutral-100': '#F3F4F6',
  'gray-neutral-200': '#E5E7EB',
  'gray-neutral-300': '#D2D6DB',
  'gray-neutral-400': '#9DA4AE',
  'gray-neutral-500': '#6C737F',
  'gray-neutral-600': '#4D5761',
  'gray-neutral-700': '#384250',
  'gray-neutral-800': '#1F2A37',
  'gray-neutral-900': '#111927',
  'gray-neutral-950': '#0D121C',
};

export const GRAY_IRON = {
  'gray-iron-25': '#FCFCFC',
  'gray-iron-50': '#FAFAFA',
  'gray-iron-100': '#F4F4F5',
  'gray-iron-200': '#E4E4E7',
  'gray-iron-300': '#D1D1D6',
  'gray-iron-400': '#A0A0AB',
  'gray-iron-500': '#70707B',
  'gray-iron-600': '#51525C',
  'gray-iron-700': '#3F3F46',
  'gray-iron-800': '#26272B',
  'gray-iron-900': '#1A1A1E',
  'gray-iron-950': '#131316',
};

export const GRAY_TRUE = {
  'gray-true-25': '#FCFCFC',
  'gray-true-50': '#F7F7F7',
  'gray-true-100': '#F5F5F5',
  'gray-true-200': '#E5E5E5',
  'gray-true-300': '#D6D6D6',
  'gray-true-400': '#A3A3A3',
  'gray-true-500': '#737373',
  'gray-true-600': '#525252',
  'gray-true-700': '#424242',
  'gray-true-800': '#292929',
  'gray-true-900': '#141414',
  'gray-true-950': '#0F0F0F',
};

export const GRAY_WARM = {
  'gray-warm-25': '#FDFDFC',
  'gray-warm-50': '#FAFAF9',
  'gray-warm-100': '#F5F5F4',
  'gray-warm-200': '#E7E5E4',
  'gray-warm-300': '#D7D3D0',
  'gray-warm-400': '#A9A29D',
  'gray-warm-500': '#79716B',
  'gray-warm-600': '#57534E',
  'gray-warm-700': '#44403C',
  'gray-warm-800': '#292524',
  'gray-warm-900': '#1C1917',
  'gray-warm-950': '#171412',
};

export const MOSS = {
  'moss-25': '#FAFDF7',
  'moss-50': '#F5FBEE',
  'moss-100': '#E6F4D7',
  'moss-200': '#CEEAB0',
  'moss-300': '#ACDC79',
  'moss-400': '#86CB3C',
  'moss-500': '#669F2A',
  'moss-600': '#4F7A21',
  'moss-700': '#3F621A',
  'moss-800': '#335015',
  'moss-900': '#2B4212',
  'moss-950': '#1A280B',
};

export const GREEN_LIGHT = {
  'green-light-25': '#FAFEF5',
  'green-light-50': '#F3FEE7',
  'green-light-100': '#E4FBCC',
  'green-light-200': '#D0F8AB',
  'green-light-300': '#A6EF67',
  'green-light-400': '#85E13A',
  'green-light-500': '#66C61C',
  'green-light-600': '#4CA30D',
  'green-light-700': '#3B7C0F',
  'green-light-800': '#326212',
  'green-light-900': '#2B5314',
  'green-light-950': '#15290A',
};

export const TEAL = {
  'teal-25': '#F6FEFC',
  'teal-50': '#F0FDF9',
  'teal-100': '#CCFBEF',
  'teal-200': '#99F6E0',
  'teal-300': '#5FE9D0',
  'teal-400': '#2ED3B7',
  'teal-500': '#15B79E',
  'teal-600': '#0E9384',
  'teal-700': '#107569',
  'teal-800': '#125D56',
  'teal-900': '#134E48',
  'teal-950': '#0A2926',
};

export const CYAN = {
  'cyan-25': '#F5FEFF',
  'cyan-50': '#ECFDFF',
  'cyan-100': '#CFF9FE',
  'cyan-200': '#A5F0FC',
  'cyan-300': '#67E3F9',
  'cyan-400': '#22CCEE',
  'cyan-500': '#06AED4',
  'cyan-600': '#088AB2',
  'cyan-700': '#0E7090',
  'cyan-800': '#155B75',
  'cyan-900': '#164C63',
  'cyan-950': '#0D2D3A',
};

export const BLUE_LIGHT = {
  'blue-light-25': '#F5FBFF',
  'blue-light-50': '#F0F9FF',
  'blue-light-100': '#E0F2FE',
  'blue-light-200': '#B9E6FE',
  'blue-light-300': '#7CD4FD',
  'blue-light-400': '#36BFFA',
  'blue-light-500': '#0BA5EC',
  'blue-light-600': '#0086C9',
  'blue-light-700': '#026AA2',
  'blue-light-800': '#065986',
  'blue-light-900': '#0B4A6F',
  'blue-light-950': '#062C41',
};

export const BLUE_DARK = {
  'blue-dark-25': '#F5F8FF',
  'blue-dark-50': '#EFF4FF',
  'blue-dark-100': '#D1E0FF',
  'blue-dark-200': '#B2CCFF',
  'blue-dark-300': '#84ADFF',
  'blue-dark-400': '#528BFF',
  'blue-dark-500': '#2970FF',
  'blue-dark-600': '#155EEF',
  'blue-dark-700': '#004EEB',
  'blue-dark-800': '#0040C1',
  'blue-dark-900': '#00359E',
  'blue-dark-950': '#002266',
};

export const INDIGO = {
  'indigo-25': '#F5F8FF',
  'indigo-50': '#EEF4FF',
  'indigo-100': '#E0EAFF',
  'indigo-200': '#C7D7FE',
  'indigo-300': '#A4BCFD',
  'indigo-400': '#8098F9',
  'indigo-500': '#6172F3',
  'indigo-600': '#444CE7',
  'indigo-700': '#3538CD',
  'indigo-800': '#2D31A6',
  'indigo-900': '#2D3282',
  'indigo-950': '#1F235D',
};

export const VIOLET = {
  'violet-25': '#FBFAFF',
  'violet-50': '#F5F3FF',
  'violet-100': '#ECE9FE',
  'violet-200': '#DDD6FE',
  'violet-300': '#C3B5FD',
  'violet-400': '#A48AFB',
  'violet-500': '#875BF7',
  'violet-600': '#7839EE',
  'violet-700': '#6927DA',
  'violet-800': '#5720B7',
  'violet-900': '#491C96',
  'violet-950': '#2E125E',
};

export const PURPLE = {
  'PURPLE-25': '#FAFAFF',
  'purple-50': '#F4F3FF',
  'purple-100': '#EBE9FE',
  'purple-200': '#D9D6FE',
  'purple-300': '#BDB4FE',
  'purple-400': '#9B8AFB',
  'purple-500': '#7A5AF8',
  'purple-600': '#6938EF',
  'purple-700': '#5925DC',
  'purple-800': '#4A1FB8',
  'purple-900': '#3E1C96',
  'purple-950': '#27115F',
};

export const FUCHSIA = {
  'fuchsia-25': '#FEFAFF',
  'fuchsia-50': '#FDF4FF',
  'fuchsia-100': '#FBE8FF',
  'fuchsia-200': '#F6D0FE',
  'fuchsia-300': '#EEAAFD',
  'fuchsia-400': '#E478FA',
  'fuchsia-500': '#D444F1',
  'fuchsia-600': '#BA24D5',
  'fuchsia-700': '#9F1AB1',
  'fuchsia-800': '#821890',
  'fuchsia-900': '#6F1877',
  'fuchsia-950': '#47104C',
};

export const PINK = {
  'pink-25': '#FEF6FB',
  'pink-50': '#FDF2FA',
  'pink-100': '#FCE7F6',
  'pink-200': '#FCCEEE',
  'pink-300': '#FAA7E0',
  'pink-400': '#F670C7',
  'pink-500': '#EE46BC',
  'pink-600': '#DD2590',
  'pink-700': '#C11574',
  'pink-800': '#9E165F',
  'pink-900': '#851651',
  'pink-950': '#4E0D30',
};

export const ROSE = {
  'rose-25': '#FFF5F6',
  'rose-50': '#FFF1F3',
  'rose-100': '#FFE4E8',
  'rose-200': '#FECDD6',
  'rose-300': '#FEA3B4',
  'rose-400': '#FD6F8E',
  'rose-500': '#F63D68',
  'rose-600': '#E31B54',
  'rose-700': '#C01048',
  'rose-800': '#A11048',
  'rose-900': '#89123E',
  'rose-950': '#510B24',
};

export const ORANGE_DARK = {
  'orange-dark-25': '#FFF9F5',
  'orange-dark-50': '#FFF4ED',
  'orange-dark-100': '#FFE6D5',
  'orange-dark-200': '#FFD6AE',
  'orange-dark-300': '#FF9C66',
  'orange-dark-400': '#FF692E',
  'orange-dark-500': '#FF4405',
  'orange-dark-600': '#E62E05',
  'orange-dark-700': '#BC1B06',
  'orange-dark-800': '#97180C',
  'orange-dark-900': '#771A0D',
  'orange-dark-950': '#57130A',
};

export const ORANGE = {
  'ORANGE-25': '#FEFAF5',
  'orange-50': '#FEF6EE',
  'orange-100': '#FDEAD7',
  'orange-200': '#F9DBAF',
  'orange-300': '#F7B27A',
  'orange-400': '#F38744',
  'orange-500': '#EF6820',
  'orange-600': '#E04F16',
  'orange-700': '#B93815',
  'orange-800': '#932F19',
  'orange-900': '#772917',
  'orange-950': '#511C10',
};

export const YELLOW = {
  'YELLOW-25': '#FEFDF0',
  'yellow-50': '#FEFBE8',
  'yellow-100': '#FEF7C3',
  'yellow-200': '#FEEE95',
  'yellow-300': '#FDE272',
  'yellow-400': '#FAC515',
  'yellow-500': '#EAAA08',
  'yellow-600': '#CA8504',
  'yellow-700': '#A15C07',
  'yellow-800': '#854A0E',
  'yellow-900': '#713B12',
  'yellow-950': '#542C0D',
};
