import React from 'react';

import {useTranslation} from 'react-i18next';

import {BuilderPropsGroup, PropsBuilder} from '../../../../../types';
import {coreScopedKey} from '../../../../../utils';
import {Input} from '../../../../Input';
import {MessageBar} from '../../../../MessageBar';
import {CoreTrans} from '../../../../ScopedTrans';
import {YStack} from '../../../../YStack';
import {EditSectionElement} from '../../../components';
import {PropsBuilderContainer} from '../../../components/PropsBuilderContainer';
import {StateAwareInput} from '../../../components/StateAwareInput';

import {LoopElementProps} from './types';

export const _LoopsPropsBuilder: PropsBuilder<LoopElementProps> = ({value: propsValue, onChange}) => {
  const {name, value} = propsValue;
  const {t} = useTranslation();
  const onUpdateProps = (key: string, value: string) => {
    onChange(key, value);
  };

  return (
    <PropsBuilderContainer>
      <EditSectionElement title={<CoreTrans i18nKey="builder.loop.name" />}>
        <Input
          value={name}
          onChangeText={(value) => {
            onUpdateProps('name', value);
          }}
        />
      </EditSectionElement>
      <EditSectionElement title={<CoreTrans i18nKey="builder.loop.dataSource" />}>
        <YStack gap="$3">
          <StateAwareInput
            value={value}
            types={['array']}
            onChangeText={(value) => {
              onUpdateProps('value', value);
            }}
            onStateSelected={(state) => {
              onUpdateProps('stateScopeId', state.scopeId);
              onUpdateProps('stateId', state.stateId);
            }}
          />

          <MessageBar
            type="info"
            title={t(coreScopedKey('note'))}
            description={t(coreScopedKey('builder.loop.rule'))}
          />
        </YStack>
      </EditSectionElement>
    </PropsBuilderContainer>
  );
};

export const LoopPropsBuilder: BuilderPropsGroup<LoopElementProps> = {
  title: 'content',
  sections: [
    {
      title: coreScopedKey('blocks.LoopElement'),
      Content: _LoopsPropsBuilder,
    },
  ],
};
