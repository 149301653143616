import {LIST_KEY_PATH_PATTERN} from '../../../config';
import {Block} from '../../../types';
import {CommonElements} from '../elements';

export const resolvePredefinedKeys = (item: unknown, structure?: Block[] | Block | string) => {
  if (typeof structure === 'string') {
    return structure.replace(LIST_KEY_PATH_PATTERN, (_, path) => {
      const keys = path.split('.');
      let value = item;

      if (typeof value === 'string' || typeof value === 'number') {
        return String(value);
      }

      for (const key of keys) {
        if (value && typeof value === 'object' && key in value) {
          value = value[key];
        } else {
          return structure;
        }
      }

      return value !== undefined ? String(value) : '';
    });
  }

  if (Array.isArray(structure)) {
    return structure.map((subItem) => resolvePredefinedKeys(item, subItem));
  }

  if (typeof structure === 'object' && structure !== null && structure.definitionId !== CommonElements.LoopElement.id) {
    return Object.entries(structure).reduce(
      (resolved, [key, value]) => ({
        ...resolved,
        [key]: resolvePredefinedKeys(item, value),
      }),
      {},
    );
  }

  return structure;
};
