import {SPACE_PRIMITIVES} from './spacePrimitives';

export const WIDTH_TOKENS = {
  'width-xxs': SPACE_PRIMITIVES.$80,
  'width-xs': SPACE_PRIMITIVES.$96,
  'width-sm': SPACE_PRIMITIVES.$120,
  'width-md': SPACE_PRIMITIVES.$140,
  'width-lg': SPACE_PRIMITIVES.$160,
  'width-xl': SPACE_PRIMITIVES.$192,
  'width-2xl': SPACE_PRIMITIVES.$256,
  'width-3xl': SPACE_PRIMITIVES.$320,
  'width-4xl': SPACE_PRIMITIVES.$360,
  'width-5xl': SPACE_PRIMITIVES.$400,
  'width-6xl': SPACE_PRIMITIVES.$480,
  'container-max-width-desktop': SPACE_PRIMITIVES.$320,
  'paragraph-max-width': SPACE_PRIMITIVES.$180,
};
