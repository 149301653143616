import React from 'react';

import {Linking, Platform} from 'react-native';
import {Anchor, AnchorProps, Button, ButtonProps} from 'tamagui';

export type Props = ButtonProps & AnchorProps;

export const LinkButton: React.FC<Props> = ({href, children, ...props}) => {
  return (
    <Anchor target="_blank" href={href}>
      <Button
        onPress={() => {
          if (href && Platform.OS !== 'web') {
            Linking.openURL(href);
          }
        }}
        {...props}>
        {children}
      </Button>
    </Anchor>
  );
};
