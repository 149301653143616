import React, {useMemo} from 'react';

import {useContentElementsResolver} from '../../../context';
import {ViewportSizes, AppContentPage} from '../../../types';
import {ErrorBoundary} from '../../ErrorBoundary';
import {PageContainer} from '../../ScreenPage';
import {StateProvider} from '../../StateProvider';
import {View, ViewProps} from '../../View';
import {useBuilderRenderer} from '../hooks/useBuilderRenderer';
import {useStateResolver} from '../hooks/useStateResolver';

type Props = ViewProps & {
  page: AppContentPage | null;
  elements?: Record<string, any>;
  viewportSize?: ViewportSizes;
};

export const PagePreview = ({
  page,
  viewportSize = ViewportSizes.MOBILE,
  elements,
  ...props
}: Props): JSX.Element | null => {
  return (
    <StateProvider state={page?.state}>
      <ContentPageUI viewportSize={viewportSize} page={page} elements={elements} {...props} />
    </StateProvider>
  );
};

export const ContentPageUI = ({page, viewportSize = ViewportSizes.MOBILE, elements, ...props}: Props) => {
  const builderContentElements = elements || useContentElementsResolver();
  const {resolve} = useStateResolver();
  const {renderUI} = useBuilderRenderer(builderContentElements);
  const pageProps = resolve(page?.props);
  const resolvedProps = typeof pageProps === 'object' ? pageProps : {};

  if (!page?.children || !Object.keys(builderContentElements).length) return null;

  const content = useMemo(
    () => (
      <ErrorBoundary>
        <StateProvider state={page?.state}>
          <View {...props} {...resolvedProps}>
            {renderUI(page?.children)}
          </View>
        </StateProvider>
      </ErrorBoundary>
    ),
    [page],
  );
  if (viewportSize === ViewportSizes.MOBILE) {
    return (
      <PageContainer {...props} {...resolvedProps}>
        {content}
      </PageContainer>
    );
  }

  return content;
};
