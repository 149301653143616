import React from 'react';

import {useContentElementsResolver} from '../../../context';
import {Block, BlockCategory, isBuilderWidgetElement, isLeafElement} from '../../../types';
import {View} from '../../View';

import {useStateResolver} from './useStateResolver';

export const useBuilderRenderer = (elements: Record<string, any> | undefined, editMode = false) => {
  const builderContentElements = elements || useContentElementsResolver();
  const {resolve} = useStateResolver(editMode);

  const renderUI = (tree: Block[] = []): JSX.Element[] => {
    const renderTree = (children: Block[]): JSX.Element[] => {
      return children.map((child) => {
        const element = builderContentElements[child.definitionId];
        const resolvedProps = resolve({...element?.defaultProps, ...child.props});
        const props = typeof resolvedProps === 'object' ? resolvedProps : {};

        if (isLeafElement(child)) {
          return isBuilderWidgetElement(element) ? (
            <View maxWidth="100%" maxHeight={400} key={child.id} {...props}>
              <element.Component id={child.id} />
            </View>
          ) : (
            <element.Component id={child.id} key={child.id} {...props} />
          );
        }

        if (element?.category === BlockCategory.META) {
          return (
            <element.Component
              id={child.id}
              key={child.id}
              nodes={child.children}
              elements={builderContentElements}
              {...props}
            />
          );
        }

        return (
          <element.Component id={child.id} key={child.id} {...props}>
            {renderTree(child.children || [])}
          </element.Component>
        );
      });
    };

    return renderTree(tree);
  };

  return {renderUI};
};
