import {DesignSpec} from '../../../types';
import {SPEC_VERSION} from '../constants/versions';
import {migratePage} from '../utils';

import {BasicElements} from './basic';
import {LayoutElements} from './layout';
import {MetaElements} from './meta';

export * from './basic';
export * from './layout';

export const CommonElements = {
  ...BasicElements,
  ...LayoutElements,
  ...MetaElements,
} as const;

export const designSpec: DesignSpec = {
  version: SPEC_VERSION,
  blockDefinition: CommonElements,
  migrate: migratePage,
};
