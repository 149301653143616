import React, {useEffect, useState} from 'react';

import {XStack} from 'tamagui';

import {Text} from '../Text';
import {View} from '../View';

export interface StepperProps {
  currentStep: number;
  stepsCount: number;
}

export const Stepper = ({currentStep = 0, stepsCount = 0}: StepperProps) => {
  const [stepsArr, setStepsArr] = useState<number[]>([]);

  useEffect(() => {
    const steps: number[] = [];
    for (let i = 0; i < stepsCount; i++) {
      steps.push(i + 1);
    }
    setStepsArr(steps);
  }, [stepsCount]);

  return (
    <XStack justifyContent="center" alignItems="center">
      {stepsArr.map((step) => {
        return (
          <XStack key={step} justifyContent="center" alignItems="center">
            <View
              justifyContent="center"
              alignItems="center"
              width={30}
              height={30}
              backgroundColor={currentStep > step - 1 ? '$fg-brand-primary' : '$bg-disabled-subtle'}
              borderRadius={50}>
              <Text position="absolute" color={step - 1 < currentStep ? 'white' : '$fg-disabled-subtle'}>
                {step}
              </Text>
            </View>
            {step != stepsCount && <View height={2} backgroundColor="$bg-quaternary" width={50} />}
          </XStack>
        );
      })}
    </XStack>
  );
};
