import {useMutation} from 'react-query';

import {config} from '../../config';
import {useAppBaseAPI} from '../../services';

// TODO: This Sould be public endpoint
export const useGetAPIResponse = (onSuccess: (response: Response) => void, onError?: (err: unknown) => void) => {
  const kyInstance = useAppBaseAPI(config.baseApiUrl, config.tenant);
  const mutation = useMutation(
    (selectedRequestId: number | null) => kyInstance.post(`api-builder/apiBuilder/request/send/${selectedRequestId}`),
    {
      onSuccess,
      onError,
    },
  );
  return mutation;
};
