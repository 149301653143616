import React from 'react';

import {Trans} from 'react-i18next';

import {PropsBuilder} from '../../../../../types';
import {ViewProps} from '../../../../View';
import {EditSectionElement} from '../../EditSectionElement';
import {SelectButtons} from '../../SelectButtons';

import {Container} from './components/Container';

// todo: review icons

export const Alignment: PropsBuilder<ViewProps> = ({value, onChange, onReset}) => {
  const {alignItems, justifyContent, flexDirection} = value;

  return (
    <Container>
      <EditSectionElement
        title={<Trans i18nKey="settings.flex.direction" />}
        onReset={() => onReset(['flexDirection'])}>
        <SelectButtons
          value={flexDirection}
          options={[
            {
              action: () => onChange('flexDirection', 'column'),
              icon: 'AlignHorizontalCenterIcon',
              lable: 'Column',
              optionValue: 'column',
            },
            {
              action: () => onChange('flexDirection', 'row'),
              icon: 'AlignVerticalCenterIcon',
              lable: 'Row',
              optionValue: 'row',
            },
          ]}
        />
      </EditSectionElement>

      <EditSectionElement title={<Trans i18nKey="settings.flex.justify" />} onReset={() => onReset(['justifyContent'])}>
        <SelectButtons
          value={justifyContent}
          options={[
            {
              action: () => onChange('justifyContent', 'flex-start'),
              icon: 'AlignLeftIcon',
              lable: 'Top',
              optionValue: 'flex-start',
            },
            {
              action: () => onChange('justifyContent', 'center'),
              icon: 'AlignVerticalCenterIcon',
              lable: 'Center',
              optionValue: 'center',
            },
            {
              action: () => onChange('justifyContent', 'flex-end'),
              icon: 'AlignRightIcon',
              lable: 'Buttom',
              optionValue: 'flex-end',
            },
            {
              action: () => onChange('justifyContent', 'space-between'),
              icon: 'AlignVerticalCenterIcon',
              lable: 'Space between',
              optionValue: 'space-between',
            },
            {
              action: () => onChange('justifyContent', 'space-around'),
              icon: 'AlignHorizontalCenterIcon',
              lable: 'Space around',
              optionValue: 'space-around',
            },
            {
              action: () => onChange('justifyContent', 'space-evenly'),
              icon: 'AlignHorizontalCenterIcon',
              lable: 'Space evenly',
              optionValue: 'space-evenly',
            },
          ]}
        />
      </EditSectionElement>
      <EditSectionElement title={<Trans i18nKey="settings.flex.align" />} onReset={() => onReset(['alignItems'])}>
        <SelectButtons
          value={alignItems}
          options={[
            {
              action: () => onChange('alignItems', 'flex-start'),
              icon: 'AlignRightIcon',
              lable: 'Top',
              optionValue: 'flex-start',
            },
            {
              action: () => onChange('alignItems', 'center'),
              icon: 'AlignHorizontalCenterIcon',
              lable: 'Center',
              optionValue: 'center',
            },
            {
              action: () => onChange('alignItems', 'flex-end'),
              icon: 'AlignLeftIcon',
              lable: 'Buttom',
              optionValue: 'flex-end',
            },
          ]}
        />
      </EditSectionElement>
    </Container>
  );
};
