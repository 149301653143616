import React, {useState} from 'react';

import {useTranslation} from 'react-i18next';

import {supportedLanguages} from '../../config';
import {LocalizedTextType} from '../../types';
import {Select} from '../Select';
import {View} from '../View';
import {XStack} from '../XStack';

interface Props {
  value?: LocalizedTextType;
  onChange?: (value: LocalizedTextType) => void;
  input: ({localizedValue, onLocalizedValueChange}: any) => any;
}

export const LocalizedInput = ({value, onChange, input}: Props) => {
  const {i18n} = useTranslation();

  const languagesList = supportedLanguages;
  const fallbackLng = i18n.options.fallbackLng as string;

  const [selectedLang, setSelectedLang] = useState(fallbackLng);

  if (value === undefined || value === null) {
    value = '';
  }

  if (typeof value === 'string' || typeof value === 'number') {
    value = {[fallbackLng]: value};
  }

  const localizedValue = value[selectedLang] || value[fallbackLng];

  return (
    <XStack gap="$2">
      <View flex={1}>
        {input({
          localizedValue,
          onLocalizedValueChange: (newValue: any) => {
            onChange && onChange({...(value as any), [selectedLang]: newValue});
          },
        })}
      </View>
      <Select
        width="$8"
        placeholder={selectedLang}
        value={selectedLang}
        onChange={setSelectedLang}
        options={languagesList.map((laung) => ({label: laung, value: laung}))}></Select>
    </XStack>
  );
};
