import React from 'react';

import {BuilderPropsGroup, PropsBuilder} from '../../../../../types';
import {coreScopedKey} from '../../../../../utils';
import {Badge} from '../../../../Badge';
import {Button} from '../../../../Button';
import {IconView} from '../../../../IconView';
import {CoreTrans} from '../../../../ScopedTrans';
import {Typography} from '../../../../Typography';
import {XStack} from '../../../../XStack';
import {YStack} from '../../../../YStack';
import {EditSectionElement} from '../../../components';
import {PropsBuilderContainer} from '../../../components/PropsBuilderContainer';

import {ConditionItem} from './components/ConditionItem';
import {OperatorEditor} from './components/OperatorEditor';
import {useConditionQuery} from './hooks/useConditionQuery';
import {ConditionElementProps, RelationOperators} from './types';

export const _ConditionPropsBuilder: PropsBuilder<ConditionElementProps> = ({value: propsValue, onChange}) => {
  const {query} = propsValue;

  const {
    addQueryGroup,
    deleteQueryGroup,
    addQueryCondition,
    updateQueryCondition,
    deleteQueryCondition,
    updateRelationOperator,
  } = useConditionQuery(query, onChange);

  return (
    <PropsBuilderContainer>
      <EditSectionElement title={<CoreTrans i18nKey="builder.condition.conditionQuery" />} gap="$0">
        {query.map((conditions, index) => {
          if (conditions.type === 'group') {
            return (
              <YStack
                key={conditions.id}
                borderWidth="$0.5"
                borderRadius="$8"
                borderColor="$border"
                padding="$2"
                gap="$2"
                alignItems="center"
                justifyContent="center">
                <XStack alignItems="center" justifyContent="space-between" flex={1} width="100%">
                  <Badge
                    label={
                      <Typography fontWeight="700">
                        <CoreTrans i18nKey="builder.condition.if" />
                      </Typography>
                    }
                    color="brand"
                  />
                  {index !== 0 && (
                    <Button
                      prefix={<IconView color="$error-400" icon="Delete01Icon" size={18} />}
                      hierarchy="link-color"
                      onPress={() => {
                        deleteQueryGroup(conditions.id);
                      }}
                    />
                  )}
                </XStack>
                <YStack width="100%">
                  {conditions.data.map((condition, index) => {
                    if (condition.type === 'condition') {
                      return (
                        <ConditionItem
                          key={condition.id}
                          condition={condition}
                          index={index}
                          onDelete={() => {
                            deleteQueryCondition(conditions.id, condition.id);
                          }}
                          onUpdate={(condition) => {
                            updateQueryCondition(conditions.id, condition.id, condition);
                          }}
                        />
                      );
                    }

                    if (condition.type === 'relation') {
                      return (
                        <OperatorEditor
                          key={condition.id}
                          value={condition.data}
                          values={RelationOperators}
                          onChange={(value) => updateRelationOperator(condition.id, value, conditions.id)}
                          color="$accent"
                        />
                      );
                    }
                  })}
                </YStack>
                <Button
                  prefix={<IconView icon="PlusSignCircleIcon" size={18} />}
                  hierarchy="link-color"
                  onPress={() => {
                    addQueryCondition(conditions.id);
                  }}>
                  <CoreTrans i18nKey="builder.condition.addCondition" />
                </Button>
              </YStack>
            );
          }

          if (conditions.type === 'relation') {
            return (
              <OperatorEditor
                key={conditions.id}
                value={conditions.data}
                values={RelationOperators}
                onChange={(value) => updateRelationOperator(conditions.id, value)}
                color="$accent"
              />
            );
          }
        })}
        <Button
          prefix={<IconView icon="PlusSignCircleIcon" size={18} />}
          hierarchy="link-color"
          onPress={() => {
            addQueryGroup();
          }}>
          <CoreTrans i18nKey="builder.condition.addGroupCondition" />
        </Button>
      </EditSectionElement>
    </PropsBuilderContainer>
  );
};

export const LoopPropsBuilder: BuilderPropsGroup<ConditionElementProps> = {
  title: 'content',
  sections: [
    {
      title: coreScopedKey('blocks.ConditionElement'),
      Content: _ConditionPropsBuilder,
    },
  ],
};
