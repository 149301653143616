import {nanoid} from 'nanoid';

import {deepClone} from '../../../../../../utils';
import {Category, Condition, Group, Query, Relation, RelationOperator} from '../types';

export const useConditionQuery = (query: Query, onChange: (key: string, value: Query) => void) => {
  function findGroupById(query: Query, groupId: string): Group | null {
    return query.find((item) => item.type === Category.group && item.id === groupId) as Group | null;
  }

  function addQueryGroup(relationOperator: RelationOperator = 'AND'): void {
    const newGroup: Group = {
      id: nanoid(),
      type: Category.group,
      data: [
        {
          id: nanoid(),
          type: Category.condition,
          data: {leftInput: '', operator: 'equals', rightInput: ''},
        },
      ],
    };

    const updatedQuery = deepClone(query);

    if (updatedQuery.length > 0) {
      const newRelation: Relation = {
        id: nanoid(),
        type: Category.relation,
        data: relationOperator,
      };

      updatedQuery.push(newRelation);
    }

    updatedQuery.push(newGroup);

    onChange('query', updatedQuery);
  }

  function deleteQueryGroup(groupId: string): void {
    const updatedQuery = deepClone(query);

    const groupIndex = updatedQuery.findIndex((item) => item.type === Category.group && item.id === groupId);

    if (groupIndex === -1) {
      return;
    }

    if (groupIndex > 0 && updatedQuery[groupIndex - 1].type === Category.relation) {
      updatedQuery.splice(groupIndex - 1, 2);
    } else {
      updatedQuery.splice(groupIndex, 1);
    }

    onChange('query', updatedQuery);
  }

  function addQueryCondition(groupId: string): void {
    const updatedQuery = deepClone(query);
    const group = findGroupById(updatedQuery, groupId);
    if (!group) return;

    if (group.data.length > 0) {
      const newRelation: Relation = {
        id: nanoid(),
        type: 'relation',
        data: 'AND',
      };

      group.data.push(newRelation);
    }

    const newCondition: Condition = {
      id: nanoid(),
      type: 'condition',
      data: {leftInput: '', operator: 'equals', rightInput: ''},
    };

    group.data.push(newCondition);
    onChange('query', updatedQuery);
  }

  function updateQueryCondition(groupId: string, conditionId: string, newCondition: Condition): void {
    const updatedQuery = deepClone(query);
    const group = findGroupById(updatedQuery, groupId);

    if (!group) {
      return;
    }

    const conditionIndex = group.data.findIndex(
      (child) => child.type === Category.condition && child.id === conditionId,
    );

    if (conditionIndex === -1) {
      return;
    }

    group.data[conditionIndex] = newCondition;

    onChange('query', updatedQuery);
  }

  function deleteQueryCondition(groupId: string, conditionId: string): void {
    const updatedQuery = deepClone(query);
    const group = findGroupById(updatedQuery, groupId);

    if (!group) {
      return;
    }

    const conditionIndex = group.data.findIndex(
      (child) => child.type === Category.condition && child.id === conditionId,
    );

    if (conditionIndex === -1) {
      return;
    }

    if (conditionIndex > 0 && group.data[conditionIndex - 1].type === Category.relation) {
      group.data.splice(conditionIndex - 1, 2);
    } else {
      group.data.splice(conditionIndex, 1);
    }

    onChange('query', updatedQuery);
  }

  function updateRelationOperator(relationId: string, newOperator: RelationOperator, groupId?: string): void {
    const updatedQuery = deepClone(query);

    if (groupId) {
      const group = findGroupById(updatedQuery, groupId);

      if (!group) {
        return;
      }

      const relation = group.data.find((item) => item.type === Category.relation && item.id === relationId);

      if (!relation) {
        return;
      }

      relation.data = newOperator;
    } else {
      const relation = updatedQuery.find((item) => item.type === Category.relation && item.id === relationId);

      if (!relation) {
        return;
      }

      relation.data = newOperator;
    }

    onChange('query', updatedQuery);
  }

  return {
    addQueryGroup,
    deleteQueryGroup,
    addQueryCondition,
    updateQueryCondition,
    deleteQueryCondition,
    updateRelationOperator,
  };
};
