import React, {forwardRef} from 'react';

import {BlockDefinition, BlockCategory} from '../../../../../types';
import {coreScopedKey} from '../../../../../utils';
import {ProgressBar, ProgressBarProps} from '../../../../ProgressBar';
import {View} from '../../../../View';
import {LayoutPropsBuilder} from '../../../components';

import {defaultProps} from './defaultProps';
import {ProgressBarBuilder} from './PropsBuilder';

export const Component = ({...props}: ProgressBarProps, ref) => {
  return (
    <View alignItems="center" justifyContent="flex-start" ref={ref}>
      <ProgressBar {...props} />
    </View>
  );
};

export const ProgressComponent = forwardRef(Component);

export const ProgressElement: BlockDefinition<ProgressBarProps> = {
  name: coreScopedKey('blocks.ProgressElement'),
  version: 1,
  id: 'ProgressElement',
  props: defaultProps,
  category: BlockCategory.NODE,
  Component: ProgressComponent,
  Icon: 'Loading04Icon',
  propsBuilder: [ProgressBarBuilder, LayoutPropsBuilder],
};
