import React, {useState, useImperativeHandle, forwardRef} from 'react';

import {ScrollView, Platform} from 'react-native';
import {Tabs as BaseTabs} from 'tamagui';

import {useAppDirection} from '../../utils/useAppDirection';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

import {AnimatedYStack} from './components/AnimatedYStack';
import {BaseTabsList} from './components/BaseTabsList';
import {TabsProps, TabsState, TabsTabProps} from './types';

export * from './types';

export const _Tabs = ({tabs, defaultTab, contentProps, tabsProps, onChange, ...props}: TabsProps, ref) => {
  const [tabState, setTabState] = useState<TabsState>({
    activeAt: null,
    currentTab: defaultTab || 0,
  });

  const {direction: appDirection} = useAppDirection();

  const setCurrentTab = (currentTab: number) => setTabState({...tabState, currentTab});
  const setActiveIndicator = (activeAt) => setTabState({...tabState, activeAt});
  const {activeAt, currentTab} = tabState;

  const handleOnInteraction: TabsTabProps['onInteraction'] = (type, layout) => {
    if (type === 'select') {
      setActiveIndicator(layout);
    }
  };

  useImperativeHandle(ref, () => ({
    switchToTab: (tabIndex: number) => {
      setCurrentTab(tabIndex);
    },
  }));

  return (
    <BaseTabs
      value={'' + currentTab || '' + defaultTab}
      onValueChange={(value: string) => {
        setCurrentTab(+value);
        onChange && onChange(value);
      }}
      orientation="horizontal"
      flex={1}
      width="100%"
      marginTop="$2"
      flexDirection="column"
      activationMode="manual"
      borderRadius="$4"
      {...props}>
      <YStack alignItems="center" justifyContent="center">
        {Platform.OS === 'web' ? (
          <XStack alignItems="center" justifyContent="center" flex={1} width="100%" overflow="scroll">
            <BaseTabsList
              tabs={tabs}
              appDirection={appDirection}
              currentTab={currentTab}
              activeAt={activeAt}
              handleOnInteraction={handleOnInteraction}
              {...tabsProps}
            />
          </XStack>
        ) : (
          <ScrollView
            scrollEnabled
            horizontal
            contentContainerStyle={{
              display: 'flex',
            }}>
            <BaseTabsList
              tabs={tabs}
              appDirection={appDirection}
              currentTab={currentTab}
              activeAt={activeAt}
              handleOnInteraction={handleOnInteraction}
              {...tabsProps}
            />
          </ScrollView>
        )}
      </YStack>
      <AnimatedYStack key="animatedYStack" animation="100ms" x={0} opacity={1}>
        <BaseTabs.Content direction={appDirection} value={'' + currentTab} forceMount flex={1} {...contentProps}>
          {tabs?.length && tabs[currentTab]?.content}
        </BaseTabs.Content>
      </AnimatedYStack>
    </BaseTabs>
  );
};

export const Tabs = forwardRef(_Tabs);
