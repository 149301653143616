import {useCallback, useEffect} from 'react';

import * as AuthSession from 'expo-auth-session';
import * as WebBrowser from 'expo-web-browser';
import {Platform} from 'react-native';

import {AuthResponse} from '../../../context';
import {SSOConfig} from '../models';

WebBrowser.maybeCompleteAuthSession();

let processingAction: any;

export function useSSOAuth(config: SSOConfig, onAuthChange, authResponse?: AuthResponse) {
  const discovery = AuthSession.useAutoDiscovery(config.url);
  const redirectUri = AuthSession.makeRedirectUri({path: 'redirect'});

  const authConfig = {...config, redirectUri};

  const [request, response, promptAsync] = AuthSession.useAuthRequest(
    {
      usePKCE: true,
      scopes: ['openid', 'email', 'profile'],
      ...authConfig,
    },
    discovery,
  );

  useEffect(() => {
    if (response?.type === 'success' && !!discovery?.tokenEndpoint) {
      AuthSession.exchangeCodeAsync(
        {
          code: response.params.code,
          ...authConfig,
          extraParams: {
            code_verifier: request?.codeVerifier || '',
          },
        },
        discovery,
      ).then((res) => {
        onAuthChange(res);
      });
    }
  }, [response]);

  const login = useCallback(async () => {
    if (processingAction) return;

    processingAction = 'login';
    try {
      const windowFeatures = {popup: false};
      await promptAsync({windowFeatures});
    } finally {
      processingAction = undefined;
    }
  }, [promptAsync]);

  const logout = useCallback(async () => {
    if (processingAction) return;

    processingAction = 'logout';
    if (discovery?.endSessionEndpoint && authResponse) {
      try {
        const logoutUrl = `${discovery?.endSessionEndpoint}?post_logout_redirect_uri=${redirectUri}&id_token_hint=${authResponse.idToken}`;
        const result = await WebBrowser.openAuthSessionAsync(logoutUrl, redirectUri, {windowFeatures: {popup: false}});

        if (result.type !== 'success') {
          processingAction = undefined;
          return;
        }

        if (Platform.OS === 'ios') {
          AuthSession.dismiss();
        }
      } catch (e) {}
    }

    onAuthChange(undefined);
    processingAction = undefined;
  }, [discovery, authResponse]);

  const refreshToken = useCallback(async () => {
    if (processingAction) return processingAction;

    processingAction = new Promise((resolve) => {
      if (discovery?.tokenEndpoint && authResponse) {
        AuthSession.refreshAsync({...authResponse, ...authConfig}, discovery)
          .then((res) => resolve(res))
          .catch(() => resolve(undefined));
      } else {
        resolve(undefined);
      }
    });

    const res = await processingAction;
    onAuthChange(res);
    processingAction = undefined;

    return res;
  }, [discovery, authResponse]);

  return {authResponse, login, logout, refreshToken, discovery, ready: !!request && !!discovery};
}
