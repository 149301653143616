import React from 'react';

import {Dialog as BaseDialog, XStack, YStack} from 'tamagui';
import {ScrollView} from 'tamagui';

import {Button} from '../../Button';
import {IconView} from '../../IconView';
import {Pressable} from '../../Pressable';
import {CoreTrans} from '../../ScopedTrans';
import {Typography} from '../../Typography';
import {DialogProps} from '../types';

type DialogContentProps = Pick<
  DialogProps,
  'title' | 'contentProps' | 'content' | 'hideFooter' | 'onCancel' | 'onAccept' | 'onClose' | 'header' | 'footer'
>;

export const DialogContent = ({
  title,
  header,
  footer,
  contentProps,
  content,
  hideFooter,
  onCancel,
  onAccept,
  onClose,
}: DialogContentProps) => {
  return (
    <>
      {header ||
        (title && (
          <BaseDialog.Title
            flex={1}
            borderTopRightRadius="$3"
            borderTopLeftRadius="$3"
            maxHeight={50}
            paddingHorizontal="$4"
            paddingVertical="$2"
            position="absolute"
            width="100%"
            backgroundColor="$accent"
            fontSize="$6">
            <XStack alignItems="center" justifyContent="space-between">
              <Typography color="white"> {title}</Typography>
              <BaseDialog.Close asChild onPress={onClose}>
                <Pressable>
                  <IconView color="white" size={19} icon="Cancel01Icon" />
                </Pressable>
              </BaseDialog.Close>
            </XStack>
          </BaseDialog.Title>
        ))}
      <ScrollView flexGrow={1} showsVerticalScrollIndicator marginTop={title ? '$13' : 0}>
        <YStack
          justifyContent="space-between"
          paddingHorizontal="$2"
          $gtMd={{marginVertical: '$4'}}
          minHeight="$14"
          {...contentProps}>
          {content}
        </YStack>
      </ScrollView>
      {footer ||
        (!hideFooter && (
          <XStack
            margin="$0.5"
            paddingHorizontal="$4"
            alignItems="center"
            justifyContent="center"
            width="100%"
            marginTop="$4"
            space>
            {onCancel && (
              <BaseDialog.Close displayWhenAdapted asChild>
                <Button onPress={onCancel} hierarchy="secondary-color" height="$3" flex={1}>
                  <CoreTrans i18nKey="action.cancel" />
                </Button>
              </BaseDialog.Close>
            )}
            {onAccept && (
              <BaseDialog.Close displayWhenAdapted asChild>
                <Button onPress={onAccept} height="$3" flex={1}>
                  <CoreTrans i18nKey="action.ok" />
                </Button>
              </BaseDialog.Close>
            )}
          </XStack>
        ))}
    </>
  );
};
