import React, {RefObject} from 'react';

import FullCalendar from '@fullcalendar/react';
import {Platform} from 'react-native';
import {useReactToPrint} from 'react-to-print';
import {YStack, XStack} from 'tamagui';

import {BidirectionalIcon} from '../../../../components/BidirectionalIcon';
import {Button} from '../../../../components/Button';
import {DateInput} from '../../../../components/DateInput';
import {IconView} from '../../../../components/IconView';
import {Pressable} from '../../../../components/Pressable';
import {CoreTrans} from '../../../../components/ScopedTrans';
import {Select} from '../../../../components/Select';
import {Typography} from '../../../../components/Typography';
import {dayjs} from '../../../../utils/extendedDayjs';
import {coreScopedKey} from '../../../../utils/scopedKeys';
import {CalendarViews, CalendarDateFormat} from '../../types';
import {useCalendarController} from '../../utils/useCalendarController';
type CalendarHeaderProps = {
  calendarRef?: RefObject<FullCalendar>;
  onDateChange?: React.Dispatch<React.SetStateAction<Date>>;
  selectedDate?: Date;
  noTitle?: boolean;
  withPrint: boolean;
};

export function CalendarHeader({calendarRef, onDateChange, selectedDate, noTitle, withPrint}: CalendarHeaderProps) {
  const {changeView, changeDate, date, view} = useCalendarController(calendarRef);
  const isMobile = Platform.OS !== 'web';

  const handelChangeDate = (direction: 'next' | 'prev') => {
    const viewKey = (Object.keys(CalendarViews) as (keyof typeof CalendarViews)[]).find(
      (key) => CalendarViews[key] === view,
    );
    const targetDate = direction === 'next' ? dayjs(date).add(1, viewKey) : dayjs(date).subtract(1, viewKey);
    const newDate = dayjs(targetDate).toDate();
    changeDate(newDate);
    if (onDateChange) {
      onDateChange(newDate);
    }
  };
  const reactToPrintFn = useReactToPrint({
    contentRef: (calendarRef?.current as any)?.elRef,
    pageStyle: `
    @media print {
      @page {size: 15in 30in;}
    }`,
  });

  const dateInpuFormat = CalendarDateFormat[isMobile ? 'timeGridWeek' : view];

  const printHandler = () => {
    reactToPrintFn();
  };
  return (
    <YStack $sm={{marginVertical: '$1'}} gap="$8">
      {!noTitle && (
        <Typography variant="h4" fontWeight="700" $sm={{fontSize: '$24'}}>
          <CoreTrans i18nKey="calendar.title" />
        </Typography>
      )}
      <XStack justifyContent="space-between" $sm={{justifyContent: 'center'}}>
        <XStack alignItems="center" justifyContent="space-between" $sm={{width: '100%', paddingBottom: 2}}>
          <XStack alignItems="center" justifyContent="space-between" $sm={{flex: 1, justifyContent: 'space-between'}}>
            <Button
              borderRadius="$rounded-full"
              padding={8}
              width={33}
              height={33}
              prefix={<BidirectionalIcon icon="ArrowLeft01Icon" color="$gray-600" size={17} type="standard" />}
              onPress={() => handelChangeDate('prev')}
              hierarchy="secondary-color"
              borderColor="$border"
            />
            <DateInput
              value={dayjs(selectedDate || date).format(dateInpuFormat)}
              textAlign="center"
              onChange={(date) => {
                changeDate(dayjs(date).toDate());
                onDateChange?.(dayjs(date).toDate());
              }}
              suffix={<IconView icon="ArrowDown01Icon" type="standard" />}
              format={dateInpuFormat}
              $sm={{width: 190}}
              borderWidth={0}
              backgroundColor="$transparent"
              inline
            />
            <Button
              borderRadius="$rounded-full"
              padding={8}
              width={33}
              height={33}
              prefix={<BidirectionalIcon icon="ArrowRight01Icon" color="$gray-600" size={17} type="standard" />}
              hierarchy="secondary-color"
              borderColor="$border"
              onPress={() => handelChangeDate('next')}
            />
          </XStack>
          <Pressable
            $sm={{display: 'none'}}
            onPress={() => changeDate()}
            width={66}
            gap="$4"
            marginHorizontal="$2"
            justifyContent="space-around"
            alignItems="center">
            <IconView color="$gray-600" icon="Sun03Icon" />
            <Typography>
              <CoreTrans i18nKey="calendar.today" />
            </Typography>
          </Pressable>
        </XStack>
        <XStack $sm={{display: 'none'}} justifyContent="space-between">
          {withPrint && !isMobile && (
            <Pressable
              borderColor="$border"
              borderWidth={1}
              borderRadius={8}
              onPress={printHandler}
              paddingHorizontal="$3"
              cursor="pointer">
              <IconView color="$gray-600" icon="PrinterIcon" size={17} />
            </Pressable>
          )}
          <Select
            width="$12"
            marginHorizontal="$5"
            backgroundColor="$transparent"
            prefix={<IconView color="$gray-600" icon="GridViewIcon" size={17} />}
            value={view}
            inline
            onChange={(value) => changeView(value)}
            options={[
              {label: coreScopedKey('calendar.month'), value: 'dayGridMonth'},
              {label: coreScopedKey('calendar.week'), value: 'timeGridWeek'},
              {label: coreScopedKey('calendar.day'), value: 'timeGridDay'},
            ]}
            triggerProps={{space: 1.5}}
          />
        </XStack>
      </XStack>
    </YStack>
  );
}
