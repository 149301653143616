import React, {useState} from 'react';

import {Badge, Button, Dropdown} from 'antd';
import {useTranslation} from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroller';

import {DeleteIcon, IconView, useIsAuthenticated} from '@medad-sep/core';

import {Loader} from '../../Loader';

import styles from './styles.module.less';

const notificationsSource = [
  // {
  //     id: 0,
  //     content: '',
  //     read: false,
  //     date: '',
  // },
];
const totalNotificationsCount = notificationsSource.length;
const pageSize = 10;
const _notifications: any = notificationsSource.slice(0, pageSize);

export function Notifications() {
  const {t} = useTranslation();
  const isAuthenticated = useIsAuthenticated();
  const [notifications, setNotifications] = useState(_notifications);

  if (!isAuthenticated) {
    return null;
  }

  const loadMoreItems = () => {
    setTimeout(() => {
      setNotifications((oldNotifications) => {
        const start = oldNotifications.length;
        return [...oldNotifications, ...notificationsSource.slice(start, start + pageSize)];
      });
    }, 1000);
  };

  const notificationsMenu = (
    <div className={styles.notificationsMenu}>
      <div className={styles.notificationsHeader}>{t('notifications')}</div>
      <div className={styles.notificationsList}>
        <InfiniteScroll
          useWindow={false}
          loadMore={loadMoreItems}
          hasMore={notifications.length != totalNotificationsCount}
          loader={
            <div className="width-fill flex center-content section-padding-2">
              <Loader />
            </div>
          }>
          {notifications.map((notification) => (
            <div
              key={notification.id}
              className={`${styles.notification} ${!notification.read ? styles.newNotification : ''}`}>
              {notification.content}
              <div className={styles.notificationActions}>
                {!notification.read ? (
                  <Button type="text" icon={<IconView icon="NotificationCircleIcon" fill />} />
                ) : null}

                <Button danger type="text" icon={<DeleteIcon fill />} />
              </div>
            </div>
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );

  const unreadCount = 0;

  return (
    <Dropdown menu={{items: [{key: 'notifications', label: notificationsMenu}]}}>
      <Badge count={unreadCount} size="small">
        <Button type="text" className="no-margin" icon={<IconView size={18} icon="NotificationSquareIcon" fill />} />
      </Badge>
    </Dropdown>
  );
}
