import React from 'react';

import {Platform} from 'react-native';
import {ScrollView} from 'tamagui';

import {Button} from '../Button';
import {Dialog} from '../Dialog';
import {IconView} from '../IconView';
import {Typography} from '../Typography';
import {XStack} from '../XStack';
import {YStack} from '../YStack';

import {SideBarProps} from './types';

export function SideBar({
  title,
  onClose,
  open,
  header,
  content,
  // todo: localize
  footer = <Button>Apply Filters</Button>,
  contentProps,
  ScrollViewProps,
  ...props
}: SideBarProps) {
  const sideBarHeader = (
    <XStack alignItems="center" justifyContent="space-between" height="$5" padding="$4" backgroundColor="$accentLight">
      <XStack alignItems="center" gap="$1">
        <Typography fontWeight="600">{title}</Typography>
      </XStack>
      {onClose && (
        <Button hierarchy="tertiary-gray" prefix={<IconView icon="Cancel01Icon" />} onPress={() => onClose()} />
      )}
    </XStack>
  );

  if (Platform.OS !== 'web') {
    return (
      <Dialog
        modelHeight="full"
        open={open}
        contentProps={{padding: '$16'}}
        onClose={() => onClose?.()}
        header={header || sideBarHeader}
        content={content}
        footer={<YStack padding="$4">{footer}</YStack>}
      />
    );
  }

  return (
    <YStack
      display={open ? 'flex' : 'none'}
      width={328}
      borderRadius={15}
      overflow="hidden"
      margin="$2"
      borderWidth="$0.5"
      borderColor="$border"
      {...props}>
      {header || sideBarHeader}
      <ScrollView {...ScrollViewProps}>
        <YStack flex={1} padding="$4" overflow="scroll" {...contentProps} borderRadius={15}>
          {content}
        </YStack>
      </ScrollView>
      {footer && <YStack padding="$4">{footer}</YStack>}
    </YStack>
  );
}
