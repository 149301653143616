import React, {useEffect, useState} from 'react';

import {FontSizeTokens, YStack} from 'tamagui';

import {Option} from '../../types';
import {Checkbox} from '../Checkbox';
import {WithLabel} from '../WithLabel';

export type CheckboxGroupProps = {
  name?: string;
  initialValue?: any[];
  value?: any[];
  onChange?: (values: any[]) => void;
  options?: Option[];
  required?: boolean;
  size?: FontSizeTokens;
};

export function CheckboxGroup({initialValue = [], value = [], size, options, onChange, ...rest}: CheckboxGroupProps) {
  const [selected, setSelected] = useState(value || initialValue);

  useEffect(() => {
    if (selected !== value) {
      setSelected(value);
    }
  }, [value]);

  const onItemCheckedChange = (value, checked) => {
    const newSelected = checked ? [...selected, value] : selected.filter((v) => v !== value);
    setSelected(newSelected);
    onChange?.(newSelected);
  };

  return (
    <YStack space="$0.5" {...rest}>
      {options?.map(({value, label}) => (
        <WithLabel key={`${value}`} label={label} size={size}>
          <Checkbox
            backgroundColor="$bg-secondary"
            size={size as any}
            checked={selected.includes(value)}
            onCheckedChange={(checked) => onItemCheckedChange(value, checked)}
          />
        </WithLabel>
      ))}
    </YStack>
  );
}
