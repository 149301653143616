import React from 'react';

import {Typography} from '../../Typography';
import {XStack} from '../../XStack';
import {DatepickerContext} from '../context/DatepickerProvider';
import {getColor} from '../helpers/getColors';
import {useDay} from '../hooks';

type Props = {
  date: Date;
  dayLabel: string;
  datepickerContext: DatepickerContext;
};

export function Day({dayLabel, date, datepickerContext}: Props) {
  const {isSelected, isSelectedStartOrEnd, isWithinHoverRange, disabledDate, onClick, onMouseEnter} = useDay({
    date,
    ...datepickerContext,
  });

  if (!dayLabel) {
    return <XStack backgroundColor="$accentLight" display="flex" flex={1} />;
  }

  const getColorFn = getColor(isSelected, isSelectedStartOrEnd, isWithinHoverRange, disabledDate);

  return (
    <Typography
      onPress={onClick as any}
      onHoverIn={onMouseEnter as any}
      color={disabledDate ? '$gray-200' : '$textColor'}
      disabled={disabledDate}
      width={`${100 / 7}%`}
      textAlign="center"
      paddingVertical="$2"
      cursor="pointer"
      borderRadius="$rounded-md"
      backgroundColor={getColorFn({
        selectedFirstOrLastColor: '$fg-brand-primary',
        normalColor: '$background',
        selectedColor: '$accentLight',
        rangeHoverColor: '$accentLight',
        disabledColor: '#FFFFFF',
      })}>
      {dayLabel}
    </Typography>
  );
}
