import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {XStackProps, YStack} from 'tamagui';

import {CoreTrans} from '../ScopedTrans';
import {Select} from '../Select';
import {Typography} from '../Typography';
import {XStack} from '../XStack';

/*
allowed times: { start: '09:00', end: '18:00' },
exclude times: ['12:00', '15:30']
*/

type TimeRange = {
  start: string;
  end: string;
};

export type TimePickerProps = {
  allowedTimes?: TimeRange[];
  excludeTimes?: TimeRange[];
  isDisabled?: boolean;
  label?: string | React.ReactNode;
  onChange?: (selectedHour: string, selectedMinute: string) => void;
  minutesIncreaments?: number;
  hour: string | number;
  minute: string | number;
  containerStyle?: XStackProps;
};

/**
 * A component that allows users to select a time from a list of available times.
 *
 * @example
 * ```tsx
 * <TimePicker
 *   allowedTimes={[{ start: '09:00', end: '18:00' }]}
 *   excludeTimes={['12:00', '15:30']}
 *   onChange={(selectedHour, selectedMinute) => console.log(`Selected time: ${selectedHour}:${selectedMinute}`)}
 *   minutesIncreaments={15}
 *   containerStyle={{ gap: '$6' }}
 * />
 * ```
 */

export const TimePicker: React.FC<TimePickerProps> = ({
  allowedTimes = [{start: '00:00', end: '23:59'}],
  excludeTimes = [],
  onChange,
  isDisabled,
  label,
  minutesIncreaments = 5,
  hour = '',
  minute = '',
  containerStyle,
}) => {
  const [hours, setHours] = useState<string[]>([]);
  const [minutes, setMinutes] = useState<string[]>([]);
  const [selectedHour, setSelectedHour] = useState(String(hour).padStart(2, '0'));
  const [selectedMinute, setSelectedMinute] = useState(String(minute).padStart(2, '0'));

  useEffect(() => {
    let allTimes: string[] = [];
    allowedTimes.forEach((timeRange) => {
      allTimes = [...allTimes, ...getTimes(timeRange.start, timeRange.end, minutesIncreaments)];
    });
    const filteredTimes = filterexcludeTimes(allTimes, excludeTimes);
    setHours([...new Set(filteredTimes.map((time) => time.split(':')[0]))]);
    if (selectedHour) {
      setMinutes(filteredTimes.filter((time) => time.startsWith(selectedHour)).map((time) => time.split(':')[1]));
    }
  }, [selectedHour]);

  const handleHourChange = useCallback(
    (value) => {
      setSelectedHour(value);
      selectedMinute && onChange?.(value, selectedMinute);
    },
    [selectedMinute],
  );
  const handleMinuteChange = useCallback(
    (value) => {
      setSelectedMinute(value);
      onChange?.(selectedHour, value);
    },
    [selectedHour],
  );

  const hoursOptions = useMemo(() => hours?.map((hour) => ({label: hour, value: hour})), [hours]);
  const minutesOptions = useMemo(() => minutes?.map((minute) => ({label: minute, value: minute})), [minutes]);

  return (
    <YStack>
      {typeof label === 'string' ? <Typography>{label}</Typography> : label}
      <XStack width={300} gap="$2" {...containerStyle}>
        <Select
          prefix={
            <Typography>
              <CoreTrans i18nKey="calendar.h" />
            </Typography>
          }
          disabled={isDisabled}
          flex={1}
          onChange={handleHourChange}
          value={selectedHour}
          options={hoursOptions}
        />
        <Select
          disabled={isDisabled}
          prefix={
            <Typography>
              <CoreTrans i18nKey="calendar.m" />
            </Typography>
          }
          flex={1}
          onChange={handleMinuteChange}
          value={selectedMinute || minutes[0]}
          options={minutesOptions}
        />
      </XStack>
    </YStack>
  );
};

const getTimes = (start: string, end: string, minutesIncreaments: number) => {
  const result: string[] = [];
  const [startHour, startMinutes] = start.split(':').map(Number);
  const [endHour, endMinutes] = end.split(':').map(Number);

  for (let i = startHour; i <= endHour; i++) {
    for (let j = i === startHour ? startMinutes : 0; j <= (i === endHour ? endMinutes : 59); j += minutesIncreaments) {
      result.push(`${i.toString().padStart(2, '0')}:${j.toString().padStart(2, '0')}`);
    }
  }

  return result;
};

const filterexcludeTimes = (times: string[], excludeTimes: TimeRange[]) => {
  let disabled: string[] = [];
  excludeTimes.forEach((timeRange) => {
    disabled = [...disabled, ...getTimes(timeRange.start, timeRange.end, 1)];
  });
  return times.filter((time) => !disabled.includes(time));
};
