import React from 'react';

import {
  Progress as BaseProgress,
  ProgressProps as BaseProgressProps,
  ColorTokens,
  ProgressIndicatorProps,
} from 'tamagui';

export type ProgressBarProps = BaseProgressProps & {
  color?: ColorTokens;
  animation?: ProgressIndicatorProps['animation'];
};

export const ProgressBar = ({color = '$bg-secondary-solid', animation = 'bouncy', ...props}: ProgressBarProps) => {
  return (
    <BaseProgress backgroundColor="$bg-quaternary" {...props}>
      <BaseProgress.Indicator animation={animation} backgroundColor={color} />
    </BaseProgress>
  );
};
