import React, {useEffect, useState} from 'react';

import WebView from 'react-native-webview';

import {useAppConfig} from '../../context';
import {useAppDirection} from '../../utils';

import {FormIORendererProps} from './types';
import {formIoPage} from './utils/formIoPage';

/**
 * FormIORenderer component renders a Form.io form inside a WebView.
 *
 * @component
 * @example
 * const form = {display: 'form', components: []};
 * const formSubmission = {};
 * const isReadOnly = false;
 * const token = 'your-auth-token';
 * const style = '';
 * const handleChange = (payload) => { console.log(payload); };
 * const handleSubmit = (payload) => { console.log('Form submitted:', payload); };
 * const handleMessage = (event) => { console.log(event); };
 * return (
 *   <FormIORenderer
 *     form={form}
 *     formSubmission={formSubmission}
 *     isReadOnly={isReadOnly}
 *     token={token}
 *     style={style}
 *     onChange={handleChange}
 *     onSubmit={handleSubmit}
 *     onMessage={handleMessage}
 *   />
 * );
 */

export const FormIORenderer = ({
  form = {display: 'form', components: []},
  formSubmission = {},
  isReadOnly,
  token,
  style = '',
  onChange,
  onMessage,
  onSubmit,
  onValidate,
  refresher,
}: FormIORendererProps) => {
  const appConfig = useAppConfig();
  const {direction} = useAppDirection();
  const [html, setHtml] = useState('');

  useEffect(() => {
    setHtml(
      formIoPage({
        appConfig,
        data: {form, isReadOnly, formSubmission: {data: formSubmission}},
        direction,
        style,
        authToken: token,
        refresher,
      }),
    );
  }, [refresher]);

  return (
    <WebView
      scalesPageToFit={false}
      source={{
        baseUrl: 'https://neededForCookieAcccesNeededByFormio.com',
        html,
      }}
      onMessage={(e) => {
        let data;
        try {
          data = JSON.parse(e?.nativeEvent?.data);
        } catch (e) {}

        switch (data?.eventName) {
          case 'submit':
            onSubmit?.(data.payload);
            break;
          case 'change':
            onChange?.(data.payload);
            onValidate?.(data.validation?.isValid);
            break;
          default:
            onMessage?.(e);
        }
      }}
    />
  );
};

/**
 * FormIORendererProps type defines the props for the FormIORenderer component.
 *
 * @typedef {Object} FormIORendererProps
 * @property {Object} form - The form definition object.
 * @property {Object} formSubmission - The submission data for the form.
 * @property {boolean} [isReadOnly] - If true, the form is read-only.
 * @property {string} [token] - The authentication token.
 * @property {string} [style] - Additional styles for the form.
 * @property {function} [onChange] - Callback function triggered on form change.
 * @property {function} [onMessage] - Callback function triggered on message.
 * @property {function} [onSubmit] - Callback function triggered on form submit.
 */
