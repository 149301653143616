import {ColorTokens} from 'tamagui';

import {ViewProps} from '../../View';
import {BadgeColor, BadgeSizes, BadgeStylesProps, BadgeType} from '../types';

export type BadgeColorsStyles = {
  [color in BadgeColor]: BadgeStylesProps & {
    iconColor?: ColorTokens;
  };
};
export type BadgeSizeStyles = {
  [size in BadgeSizes]: BadgeStylesProps;
};
export type BadgeStyle = {
  container: BadgeStylesProps;
  text: BadgeStylesProps;
  icon: {
    color: ColorTokens;
  };
};

export const getBadgeStyles = (type: BadgeType, color: BadgeColor, size: BadgeSizes): BadgeStyle => {
  const _pillOutlineBorderColor: BadgeStylesProps = type === 'pill-outline' ? getPillOutlineBorderColors(color) : {};
  const _stylesBaseOnSize = getStylesBaseOnSize(size);
  const _stylesBasedOnColor = getStylesBasedOnColor(color);

  const _textStyles = {
    fontSize: _stylesBaseOnSize.fontSize,
    color: _stylesBasedOnColor.color,
  };

  delete _stylesBaseOnSize.fontSize;
  delete _stylesBasedOnColor.color;

  return {
    container: {
      width: 'max-content',
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: 1,
      ..._stylesBasedOnColor,
      ..._stylesBaseOnSize,
      ...getBorderRadius(type, size),
      ..._pillOutlineBorderColor,
      ...(type === 'badge-modern' && {
        backgroundColor: '$bg-primary',
        borderColor: '$border-primary',
      }),
    },
    text: {
      fontWeight: '500',
      ..._textStyles,
      ...(type === 'badge-modern' && {
        color: '$text-secondary',
      }),
    },
    icon: {
      color: getIconColorBasedOnColor(color) as ColorTokens,
    },
  };
};

function getStylesBasedOnColor(color: BadgeColor): BadgeStylesProps {
  const _colorsStyles: BadgeColorsStyles = {
    gray: {
      borderColor: '$utility-gray-200',
      backgroundColor: '$utility-gray-50',
      color: '$utility-gray-700',
      iconColor: '$utility-gray-500',
    },
    brand: {
      borderColor: '$utility-brand-200',
      backgroundColor: '$utility-brand-50',
      color: '$utility-brand-700',
      iconColor: '$utility-brand-500',
    },
    error: {
      borderColor: '$utility-error-200',
      backgroundColor: '$utility-error-50',
      color: '$utility-error-700',
      iconColor: '$utility-error-500',
    },
    warning: {
      borderColor: '$utility-warning-200',
      backgroundColor: '$utility-warning-50',
      color: '$utility-warning-700',
      iconColor: '$utility-warning-500',
    },
    success: {
      borderColor: '$utility-success-200',
      backgroundColor: '$utility-success-50',
      color: '$utility-success-700',
      iconColor: '$utility-success-500',
    },
    'gray-blue': {
      borderColor: '$utility-gray-blue-200',
      backgroundColor: '$utility-gray-blue-50',
      color: '$utility-gray-blue-700',
      iconColor: '$utility-gray-blue-500',
    },
    'blue-light': {
      borderColor: '$utility-blue-light-200',
      backgroundColor: '$utility-blue-light-50',
      color: '$utility-blue-light-700',
      iconColor: '$utility-blue-light-500',
    },
    blue: {
      borderColor: '$utility-blue-200',
      backgroundColor: '$utility-blue-50',
      color: '$utility-blue-700',
      iconColor: '$utility-blue-500',
    },
    indigo: {
      borderColor: '$utility-indigo-200',
      backgroundColor: '$utility-indigo-50',
      color: '$utility-indigo-700',
      iconColor: '$utility-indigo-500',
    },
    purple: {
      borderColor: '$utility-purple-200',
      backgroundColor: '$utility-purple-50',
      color: '$utility-purple-700',
      iconColor: '$utility-purple-500',
    },
    pink: {
      borderColor: '$utility-pink-200',
      backgroundColor: '$utility-pink-50',
      color: '$utility-pink-700',
      iconColor: '$utility-pink-500',
    },
    orange: {
      borderColor: '$utility-orange-200',
      backgroundColor: '$utility-orange-50',
      color: '$utility-orange-700',
      iconColor: '$utility-orange-500',
    },
  };

  return _colorsStyles[color];
}

function getIconColorBasedOnColor(color: BadgeColor): ColorTokens {
  const _colorsStyles: BadgeColorsStyles = {
    gray: {
      iconColor: '$utility-gray-500',
    },
    brand: {
      iconColor: '$utility-brand-500',
    },
    error: {
      iconColor: '$utility-error-500',
    },
    warning: {
      iconColor: '$utility-warning-500',
    },
    success: {
      iconColor: '$utility-success-500',
    },
    'gray-blue': {
      iconColor: '$utility-gray-blue-500',
    },
    'blue-light': {
      iconColor: '$utility-blue-light-500',
    },
    blue: {
      iconColor: '$utility-blue-500',
    },
    indigo: {
      iconColor: '$utility-indigo-500',
    },
    purple: {
      iconColor: '$utility-purple-500',
    },
    pink: {
      iconColor: '$utility-pink-500',
    },
    orange: {
      iconColor: '$utility-orange-500',
    },
  };

  return _colorsStyles?.[color]?.iconColor || '$utility-gray-500';
}

function getPillOutlineBorderColors(color: BadgeColor): BadgeStylesProps {
  const _pillOutlinetypeBorderColors: BadgeColorsStyles = {
    gray: {
      borderColor: '$utility-gray-600',
    },
    brand: {
      borderColor: '$utility-brand-600',
    },
    error: {
      borderColor: '$utility-error-600',
    },
    warning: {
      borderColor: '$utility-warning-600',
    },
    success: {
      borderColor: '$utility-success-600',
    },
    'gray-blue': {
      borderColor: '$utility-gray-blue-600',
    },
    'blue-light': {
      borderColor: '$utility-blue-light-600',
    },
    blue: {
      borderColor: '$utility-blue-600',
    },
    indigo: {
      borderColor: '$utility-indigo-600',
    },
    purple: {
      borderColor: '$utility-purple-600',
    },
    pink: {
      borderColor: '$utility-pink-600',
    },
    orange: {
      borderColor: '$utility-orange-600',
    },
  };

  return _pillOutlinetypeBorderColors[color];
}

function getStylesBaseOnSize(size: BadgeSizes): BadgeStylesProps {
  const _sizeStyles: BadgeSizeStyles = {
    sm: {
      paddingVertical: '$spacing-xxs',
      paddingHorizontal: '$spacing-md',
      gap: '$spacing-xs',
      fontSize: '$text-xs',
    },
    md: {
      paddingVertical: '$spacing-xxs',
      paddingHorizontal: 10,
      gap: '$spacing-sm',
      fontSize: '$text-sm',
    },
    lg: {
      paddingVertical: '$spacing-xs',
      paddingHorizontal: '$spacing-lg',
      gap: '$spacing-sm',
      fontSize: '$text-sm',
    },
  };
  return _sizeStyles[size];
}

function getBorderRadius(type: BadgeType, size: BadgeSizes): BadgeStylesProps {
  const _typesBorderRadius: {
    [type in BadgeType]?: {
      [size in BadgeSizes]?: ViewProps;
    };
  } = {
    'badge-color': {
      sm: {
        borderRadius: '$rounded-sm',
      },
      md: {
        borderRadius: '$rounded-sm',
      },
      lg: {
        borderRadius: '$rounded-md',
      },
    },
    'badge-modern': {
      sm: {
        borderRadius: '$rounded-sm',
      },
      md: {
        borderRadius: '$rounded-sm',
      },
      lg: {
        borderRadius: '$rounded-md',
      },
    },
  };

  return {borderRadius: '$rounded-full', ..._typesBorderRadius[type]?.[size]};
}
