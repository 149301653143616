import {Condition, Operators} from '../types';

export function evaluateCondition(condition: Condition['data']): boolean {
  const {leftInput, operator, rightInput} = condition;
  const jsOperator = Operators[operator];

  switch (jsOperator) {
    case '==':
      return leftInput == rightInput;
    case '===':
      return leftInput === rightInput;
    case '!=':
      return leftInput != rightInput;
    case '!==':
      return leftInput !== rightInput;
    case '>':
      return leftInput > rightInput;
    case '>=':
      return leftInput >= rightInput;
    case '<':
      return leftInput < rightInput;
    case '<=':
      return leftInput <= rightInput;
    case 'includes':
      if (Array.isArray(leftInput) || typeof leftInput === 'string') {
        return leftInput.includes(rightInput);
      }
      throw new Error(`Operator "contains" is only valid for arrays or strings.`);
    default:
      throw new Error(`Unsupported operator: ${operator}`);
  }
}
