import React from 'react';

import {Checkbox as BaseCheckbox, SizeTokens, CheckboxProps as BasCheckBoxProps} from 'tamagui';

import {IconView} from '../IconView';

export type CheckboxProps = BasCheckBoxProps & {
  name?: string;
  checked?: boolean;
  onCheckedChange?: (checked: boolean) => void;
  required?: boolean;

  size?: SizeTokens;
};

export function Checkbox(props: CheckboxProps) {
  return (
    <BaseCheckbox
      backgroundColor={props.checked ? '$bg-active' : '$bg-secondary'}
      {...props}
      size="$3.5"
      focusStyle={{outlineColor: 'transparent'}}>
      <BaseCheckbox.Indicator>
        <IconView icon="Tick02Icon" color="$bg-secondary" size={14} />
      </BaseCheckbox.Indicator>
    </BaseCheckbox>
  );
}
