import React from 'react';

import {SpaceTokens, XStack, ColorTokens, FontSizeTokens} from 'tamagui';

import {LocalizedTextType} from '../../types';
import {LocalizedText} from '../LocalizedText';
import {Typography} from '../Typography';

export type WithLabelProps = {
  label?: LocalizedTextType;
  size?: FontSizeTokens;
  space?: SpaceTokens;
  color?: ColorTokens;
  children?: any;
};

export function WithLabel({label, size, space = '$2', color = '$text-primary', children}: WithLabelProps) {
  return (
    <XStack alignItems="center" space={space}>
      {children}

      <Typography fontSize={size} color={color}>
        <LocalizedText value={label} />
      </Typography>
    </XStack>
  );
}
