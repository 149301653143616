import {SPACE_PRIMITIVES} from './spacePrimitives';

export const RADIUS_TOKENS = {
  'rounded-none': SPACE_PRIMITIVES.$0,
  'rounded-xxs': SPACE_PRIMITIVES['$0.5'],
  'rounded-xs': SPACE_PRIMITIVES.$1,
  'rounded-sm': SPACE_PRIMITIVES['$1.5'],
  'rounded-md': SPACE_PRIMITIVES.$2,
  'rounded-lg': SPACE_PRIMITIVES['$2.5'],
  'rounded-xl': SPACE_PRIMITIVES.$3,
  'rounded-2xl': SPACE_PRIMITIVES.$4,
  'rounded-3xl': SPACE_PRIMITIVES.$5,
  'rounded-4xl': SPACE_PRIMITIVES.$6,
  'rounded-full': 9999,

  // OLD Tokens
  rounded: 8,
  true: 7,
  0: 0,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  8: 8,
  10: 10,
  12: 12,
  14: 14,
  16: 16,
  18: 18,
  20: 20,
  24: 24,
  28: 28,
  32: 32,
  36: 36,
  38: 36,
  40: 48,
  42: 48,
  44: 48,
  48: 48,
  50: 50,
};
