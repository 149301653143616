import React from 'react';

import {Slider} from 'antd';
import {Trans} from 'react-i18next';

import {PropsBuilder} from '../../../../../types';
import {Typography} from '../../../../Typography';
import {ViewProps} from '../../../../View';
import {XStack} from '../../../../XStack';
import {YStack} from '../../../../YStack';
import {EditSectionElement} from '../../EditSectionElement';
import {SideBarInput} from '../../SideBarInput';
import {SliderInputValuePicker} from '../../SliderInputValuePicker';

import {Container} from './components/Container';
import {prioritizeNumberValues} from './utils/prioritizeNumberValues';

export const Spacing: PropsBuilder<ViewProps> = ({value, onChange, onReset}) => {
  const {
    margin,
    padding,
    marginLeft,
    marginRight,
    marginTop,
    marginBottom,
    paddingLeft,
    paddingRight,
    paddingTop,
    paddingBottom,
    gap,
  } = value;

  return (
    <Container>
      <EditSectionElement
        title={<Trans i18nKey="settings.layout.margin" />}
        onReset={() => onReset(['margin', 'marginLeft', 'marginRight', 'marginTop', 'marginBottom'])}>
        <>
          <Slider value={margin as number} defaultValue={30} onChange={(value) => onChange('margin', value)} />
          <XStack gap="$1.5" alignItems="center" justifyContent="center">
            <YStack width="$6" alignItems="center" gap="$1.5">
              <SideBarInput
                value={prioritizeNumberValues(marginLeft, margin)}
                onChange={(value) => onChange('marginLeft', +value.target.value)}
              />
              <Typography fontWeight="600" fontSize="$14">
                {<Trans i18nKey="settings.layout.left" />}
              </Typography>
            </YStack>
            <YStack width="$6" alignItems="center" gap="$1.5">
              <SideBarInput
                value={prioritizeNumberValues(marginRight, margin)}
                onChange={(value) => onChange('marginRight', +value.target.value)}
              />
              <Typography fontWeight="600" fontSize="$14">
                {<Trans i18nKey="settings.layout.right" />}
              </Typography>
            </YStack>
            <YStack width="$6" alignItems="center" gap="$1.5">
              <SideBarInput
                value={prioritizeNumberValues(marginTop, margin)}
                onChange={(value) => onChange('marginTop', +value.target.value)}
              />
              <Typography fontWeight="600" fontSize="$14">
                {<Trans i18nKey="settings.layout.top" />}
              </Typography>
            </YStack>

            <YStack width="$6" alignItems="center" gap="$1.5">
              <SideBarInput
                value={prioritizeNumberValues(marginBottom, margin)}
                onChange={(value) => onChange('marginBottom', +value.target.value)}
              />
              <Typography fontWeight="600" fontSize="$14">
                {<Trans i18nKey="settings.layout.bottom" />}
              </Typography>
            </YStack>
          </XStack>
        </>
      </EditSectionElement>
      <EditSectionElement
        title={<Trans i18nKey="settings.layout.padding" />}
        onReset={() => onReset(['padding', 'paddingLeft', 'paddingRight', 'paddingTop', 'paddingBottom'])}>
        <Slider value={padding as number} onChange={(value) => onChange('padding', value)} />
        <XStack gap="$1.5" alignItems="center" justifyContent="center">
          <YStack width="$6" alignItems="center" gap="$1.5">
            <SideBarInput
              value={prioritizeNumberValues(paddingLeft, padding)}
              onChange={(value) => onChange('paddingLeft', +value.target.value)}
            />
            <Typography fontWeight="600" fontSize="$14">
              <Trans i18nKey="settings.layout.left" />
            </Typography>
          </YStack>
          <YStack width="$6" alignItems="center" gap="$1.5">
            <SideBarInput
              value={prioritizeNumberValues(paddingRight, padding)}
              onChange={(value) => onChange('paddingRight', +value.target.value)}
            />
            <Typography fontWeight="600" fontSize="$14">
              <Trans i18nKey="settings.layout.right" />
            </Typography>
          </YStack>
          <YStack width="$6" alignItems="center" gap="$1.5">
            <SideBarInput
              value={prioritizeNumberValues(paddingTop, padding)}
              onChange={(value) => onChange('paddingTop', +value.target.value)}
            />
            <Typography fontWeight="600" fontSize="$14">
              <Trans i18nKey="settings.layout.top" />
            </Typography>
          </YStack>
          <YStack width="$6" alignItems="center" gap="$1.5">
            <SideBarInput
              value={prioritizeNumberValues(paddingBottom, padding)}
              onChange={(value) => onChange('paddingBottom', +value.target.value)}
            />
            <Typography fontWeight="600" fontSize="$14">
              <Trans i18nKey="settings.layout.bottom" />
            </Typography>
          </YStack>
        </XStack>
      </EditSectionElement>
      <EditSectionElement title={<Trans i18nKey="settings.layout.gap" />} onReset={() => onReset(['gap'])}>
        <SliderInputValuePicker value={+prioritizeNumberValues(gap)} propName="gap" onUpdate={onChange} />
      </EditSectionElement>
    </Container>
  );
};
