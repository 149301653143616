import {Block} from '../../../types';
import {MigrationFunction} from '../models';

export function migrateElement<T extends Block>(migrations: MigrationFunction<T>[], node: T, targetVersion: number): T {
  let migratedBlock = {...node};

  if (node.definitionVersion <= targetVersion) {
    // Forward migration
    for (let i = node.definitionVersion; i <= targetVersion; i++) {
      const migration = migrations[i - 1];
      if (migration) {
        migratedBlock = migration(migratedBlock, i);
      }
    }
    migratedBlock.definitionVersion = targetVersion;
    return migratedBlock;
  } else if (node.definitionVersion > targetVersion) {
    // Backward migration
    for (let i = node.definitionVersion; i > targetVersion; i--) {
      const migration = migrations[i - 1];
      if (migration) {
        migratedBlock = migration(migratedBlock, i - 1);
      }
    }
    migratedBlock.definitionVersion = targetVersion;
    return migratedBlock;
  }

  return migratedBlock;
}
