import React, {forwardRef} from 'react';

import {BlockDefinition, BlockCategory} from '../../../../../types';
import {coreScopedKey} from '../../../../../utils';
import {useStateValue} from '../../../../StateProvider';
import {View} from '../../../../View';
import {LayoutPropsBuilder} from '../../../components';
import {useBuilderRenderer} from '../../../hooks/useBuilderRenderer';
import {resolvePredefinedKeys} from '../../../utils/resolvePredifinedKeys';

import {defaultProps} from './defaultProps';
import {LoopPropsBuilder} from './PropsBuilder';
import {LoopElementProps} from './types';

export const Component = (
  {children, stateScopeId, stateId, editMode, elements, nodes, ...props}: LoopElementProps,
  ref,
) => {
  const state = useStateValue(stateId, stateScopeId);
  const {renderUI} = useBuilderRenderer(elements, editMode);

  return (
    <View minHeight="$12" flexDirection="row" flexWrap="wrap" {...props} ref={ref}>
      {!editMode && state && Array.isArray(state.value)
        ? state.value.map((item, index) => {
            const resolvedNodes = resolvePredefinedKeys(item, nodes);
            return <React.Fragment key={index}>{renderUI(resolvedNodes)}</React.Fragment>;
          })
        : children}
    </View>
  );
};

export const LoopComponent = forwardRef(Component);

export const LoopElement: BlockDefinition<LoopElementProps> = {
  name: coreScopedKey('blocks.LoopElement'),
  version: 1,
  id: 'LoopElement',
  props: defaultProps,
  children: [],
  Component: LoopComponent,
  category: BlockCategory.META,
  Icon: 'RepeatIcon',
  propsBuilder: [LoopPropsBuilder, LayoutPropsBuilder],
};
