import Constants from 'expo-constants';

import {EnvironmentProfile} from '../types/EnvironmentProfile';

let configOverride = (window as any)?.configOverride ?? {keycloakConfigs: {}};

if (Constants.expoConfig?.extra?.configOverride) {
  try {
    configOverride = JSON.parse(Constants.expoConfig?.extra?.configOverride);
  } catch (e) {}
}

const keycloakConfigsOverride = configOverride.keycloakConfigs ?? {};

const baseApiUrl = 'https://api-sep.medadstg.com';
const baseKeycloakUrl = 'https://sso.medadstg.com';

export const config = {
  baseApiUrl: baseApiUrl,
  defaultAppLang: 'en',
  tenant: 'medad',
  environmentProfile: EnvironmentProfile.PRODUCTION,
  ...configOverride,
  keycloakConfigs: {
    baseUrl: baseKeycloakUrl,
    url: keycloakConfigsOverride.baseUrl || baseKeycloakUrl,
    realm: 'medad',
    clientId: 'sep-client',
    ...keycloakConfigsOverride,
  },
};
