import React from 'react';

import {Form} from 'antd';
import {XStack} from 'tamagui';

import {ApiInstance} from '../../../services';
import {Button} from '../../Button';
import {IconView} from '../../IconView';
import {CoreTrans} from '../../ScopedTrans';
import {Typography} from '../../Typography';

import {useTestConnection} from './useTestConnection';

export type TestConnectionFunction = (values: any, API: ApiInstance) => Promise<boolean>;

interface Props {
  valueKeyPath?: string[];
  test: TestConnectionFunction;
  title?: string | React.ReactNode;
}

export const AppletConnectionTest = ({test, valueKeyPath = ['settings'], title}: Props) => {
  const {isTestingConnection, testConnection} = useTestConnection(test);

  return (
    <Form.Item dependencies={valueKeyPath}>
      {({getFieldValue}) => (
        <XStack justifyContent="space-between" alignContent="center">
          <Typography variant="h6" color="$text-primary">
            {title}
          </Typography>
          <Button
            hierarchy="secondary-color"
            height="$3"
            loading={isTestingConnection}
            prefix={<IconView icon="PlugSocketIcon" />}
            onPress={(e) => {
              e.preventDefault();
              testConnection(getFieldValue(valueKeyPath));
            }}>
            <CoreTrans i18nKey="testConnection" />
          </Button>
        </XStack>
      )}
    </Form.Item>
  );
};
