import React, {ReactNode} from 'react';

import {State} from '../../types';

import {Provider} from './Provider';

export type StateElementProps = {
  state?: Record<string, State[]> | null;
  children?: ReactNode;
};

export * from './Provider';

export const StateProvider = ({children, state}: StateElementProps) => {
  return <Provider state={state || {}}>{children}</Provider>;
};
