import React from 'react';

import {BuilderPropsGroup, PropsBuilder} from '../../../../../types';
import {coreScopedKey, usePropsByViewPortSize} from '../../../../../utils';
import {CardProps} from '../../../../Card';
import {CoreTrans} from '../../../../ScopedTrans';
import {EditSectionElement, StateAwareInput} from '../../../components';
import {PropsBuilderContainer} from '../../../components/PropsBuilderContainer';

export const _CardPropsBuilder: PropsBuilder<CardProps> = ({value: propsValue, onChange, onReset, options}) => {
  const {header, content, footer} = usePropsByViewPortSize(propsValue);

  const onUpdateProps = (key: string, value: any) => {
    onChange(key, value);
  };

  const onResetProps = (keys: string[]) => {
    onReset?.(keys);
  };

  return (
    <PropsBuilderContainer>
      <EditSectionElement title={<CoreTrans i18nKey="card.header" />} onReset={() => onResetProps(['header'])}>
        <StateAwareInput
          types={['string', 'number']}
          value={header as string}
          onChangeText={(value) => {
            onUpdateProps('header', value);
          }}
          textArea
          options={options}
        />
      </EditSectionElement>

      <EditSectionElement title={<CoreTrans i18nKey="card.content" />} onReset={() => onResetProps(['content'])}>
        <StateAwareInput
          types={['string', 'number']}
          value={content as string}
          onChangeText={(value) => {
            onUpdateProps('content', value);
          }}
          textArea
          options={options}
        />
      </EditSectionElement>
      <EditSectionElement title={<CoreTrans i18nKey="card.footer" />} onReset={() => onResetProps(['footer'])}>
        <StateAwareInput
          types={['string', 'number']}
          value={footer as string}
          onChangeText={(value) => {
            onUpdateProps('footer', value);
          }}
          textArea
          options={options}
        />
      </EditSectionElement>
    </PropsBuilderContainer>
  );
};

export const CardPropsBuilder: BuilderPropsGroup<CardProps> = {
  title: 'content',
  sections: [
    {
      title: coreScopedKey('blocks.CardElement'),
      Content: _CardPropsBuilder,
    },
  ],
};
