import React, {forwardRef} from 'react';

import {BlockDefinition, BlockCategory} from '../../../../../types';
import {coreScopedKey} from '../../../../../utils';
import {Accordion} from '../../../../Accordion';
import {View} from '../../../../View';
import {LayoutPropsBuilder} from '../../../components';

import {defaultProps} from './defaultProps';
import {AccordionPropsBuilder} from './PropsBuilder';
import {AccordionElementProps} from './types';

export * from './types';

export const Component = ({sections, ...props}: AccordionElementProps, ref) => {
  return (
    <View {...props} ref={ref}>
      <Accordion defaultActiveSections={[0, 1]} variant="outlined" sections={sections} />
    </View>
  );
};

export const AccordionComponent = forwardRef(Component);

export const AccordionElement: BlockDefinition<AccordionElementProps> = {
  name: coreScopedKey('blocks.AccordionElement'),
  version: 1,
  id: 'AccordionElement',
  props: defaultProps,
  category: BlockCategory.NODE,
  Component: AccordionComponent,
  Icon: 'ListViewIcon',
  propsBuilder: [AccordionPropsBuilder, LayoutPropsBuilder],
};
