export const supportedLanguages: string[] = ['en', 'ar'];

//TODO: decrease it once the api latency issue resolved in applets
export const appletInitTimeout = 8 * 1000;

export const syncCalendarServicesKey = 'SYNC_CALENDAR_SERVICES';
export const calendarSyncBackgroundTask = 'CALENDAR_SYNC_BACKGROUND_TASK';

export const authCacheKey = '@AUTH_CACHE';
export * from './appConfig';
export * from './pagebuilder';
