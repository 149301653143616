import React from 'react';

import {YStack} from '../../../YStack';

export const PropsBuilderContainer = ({children}) => {
  return (
    <YStack gap="$4" paddingTop="$2">
      {children}
    </YStack>
  );
};
