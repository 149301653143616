import React from 'react';

import {Text, TextProps} from '../../Text';

export const HeaderText = ({children, ...props}: TextProps) => {
  return (
    <Text color="$text-primary" fontWeight="600" {...props}>
      {children}
    </Text>
  );
};
