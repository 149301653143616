import {ReactNode} from 'react';

import {Block} from '../../../../../types';

export const Operators = {
  equals: '==',
  'strict equals': '===',
  'not equals': '!=',
  'strict not equals': '!==',
  'greater than': '>',
  'greater than or equal': '>=',
  'less than': '<',
  'less than or equal': '<=',
  contains: 'includes',
} as const;

export const OperatorsByType: Record<string, (keyof typeof Operators)[]> = {
  number: [
    'equals',
    'strict equals',
    'not equals',
    'strict not equals',
    'greater than',
    'greater than or equal',
    'less than',
    'less than or equal',
  ],
  string: ['equals', 'strict equals', 'not equals', 'strict not equals', 'contains'],
  array: ['contains'],
};

export const RelationOperators = ['OR', 'AND'];

export type RelationOperator = typeof RelationOperators[number];

export type ConditionData = {
  leftInput: string;
  operator: keyof typeof Operators;
  rightInput: string;
};

export const Category = {
  condition: 'condition',
  relation: 'relation',
  group: 'group',
} as const;

export type Condition = {id: string; type: typeof Category.condition; data: ConditionData};

export type Relation = {id: string; type: typeof Category.relation; data: RelationOperator};

export type Group = {id: string; type: typeof Category.group; data: (Condition | Relation)[]};

export type QueryItem = Condition | Relation | Group;

export type Query = QueryItem[];

export function isConditionData(data?: RelationOperator | ConditionData): data is ConditionData {
  return typeof data === 'object' && data !== null && 'leftInput' in data;
}

export type ConditionElementProps = {
  query: Query;
  children: ReactNode;
  editMode?: boolean;
  nodes?: Block[] | undefined;
  elements?: Record<string, any>;
};
