import React from 'react';

import {Slider} from 'antd';

import {convertPercentageToNumber} from '../../../../utils/convertPercentageToNumber';
import {XStack} from '../../../XStack';
import {SideBarInput} from '../SideBarInput';

type Props = {
  onUpdate: (key: string, value: number | string) => void;
  propName: string;
  value?: number;
  defaultValue?: number;
  max?: number;
  step?: number;
};

export const SliderInputValuePicker = ({propName, defaultValue = 30, value: propValue, onUpdate, max, step}: Props) => {
  const value = convertPercentageToNumber(propValue, max);
  return (
    <XStack gap="$2">
      <Slider
        value={value}
        defaultValue={defaultValue}
        max={max}
        onChange={(value) => onUpdate(propName, value)}
        style={{flex: 1}}
        step={step}
      />
      <SideBarInput
        style={{flex: 0, minWidth: 70}}
        value={value ?? defaultValue}
        onChange={(value) => onUpdate(propName, +value.target.value)}
      />
    </XStack>
  );
};
