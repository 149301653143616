import {
  COLORS,
  BLUE,
  GREEN,
  BRAND,
  BLUE_DARK,
  BLUE_LIGHT,
  ERROR,
  FUCHSIA,
  GRAY_BLUE,
  INDIGO,
  ORANGE,
  ORANGE_DARK,
  PINK,
  PURPLE,
  SUCCESS,
  WARNING,
  YELLOW,
} from './colors';

export const LIGHT_GRAY = {
  'gray-25': '#FDFDFD',
  'gray-50': '#FAFAFA',
  'gray-100': '#F5F5F5',
  'gray-200': '#E9EAEB',
  'gray-300': '#D5D7DA',
  'gray-400': '#A4A7AE',
  'gray-500': '#717680',
  'gray-600': '#535862',
  'gray-700': '#414651',
  'gray-800': '#252B37',
  'gray-900': '#181D27',
  'gray-950': '#0A0D12',
};

export const LIGHT_COLORS = {
  highlightColor: COLORS.white,
  placeholder: '#475569',
  background: COLORS.white,
  textColor: COLORS.black,
  neutral: '#DDE4EE',
  border: '#C5D0DF',
  accentLight: '',
  'tooltip-supporting-text': LIGHT_GRAY['gray-300'],
  'screen-mockup-border': LIGHT_GRAY['gray-900'],
  'app-store-badge-border': '#a6a6a6',
};

export const LIGHT_ALPHA = {
  'alpha-black-10': '#0000001a',
  'alpha-black-100': '#000000',
  'alpha-black-20': '#00000033',
  'alpha-black-30': '#0000004d',
  'alpha-black-40': '#00000066',
  'alpha-black-50': '#00000080',
  'alpha-black-60': '#00000099',
  'alpha-black-70': '#000000b2',
  'alpha-black-80': '#000000cc',
  'alpha-black-90': '#000000e5',
  'alpha-white-10': '#ffffff1a',
  'alpha-white-100': '#ffffff',
  'alpha-white-20': '#ffffff33',
  'alpha-white-30': '#ffffff4d',
  'alpha-white-40': '#ffffff66',
  'alpha-white-50': '#ffffff80',
  'alpha-white-60': '#ffffff99',
  'alpha-white-70': '#ffffffb2',
  'alpha-white-80': '#ffffffcc',
  'alpha-white-90': '#ffffffe5',
};

export const LIGHT_TEXT = {
  'text-brand-primary': BRAND['brand-900'],
  'text-brand-secondary': BRAND['brand-700'],
  'text-brand-tertiary': BRAND['brand-600'],
  'text-brand-tertiary-alt': BRAND['brand-600'],
  'text-disabled': LIGHT_GRAY['gray-500'],
  'text-error-primary': ERROR['error-600'],
  'text-placeholder': LIGHT_GRAY['gray-500'],
  'text-placeholder-subtle': LIGHT_GRAY['gray-300'],
  'text-primary': LIGHT_GRAY['gray-900'],
  'text-primary-on-brand': COLORS.white,
  'text-quaternary': LIGHT_GRAY['gray-500'],
  'text-quaternary-on-brand': BRAND['brand-300'],
  'text-secondary': LIGHT_GRAY['gray-700'],
  'text-secondary-hover': LIGHT_GRAY['gray-800'],
  'text-secondary-on-brand': BRAND['brand-200'],
  'text-success-primary': SUCCESS['success-600'],
  'text-tertiary': LIGHT_GRAY['gray-600'],
  'text-tertiary-hover': LIGHT_GRAY['gray-700'],
  'text-tertiary-on-brand': BRAND['brand-200'],
  'text-warning-primary': WARNING['warning-600'],
  'text-white': COLORS.white,
};

export const LIGHT_FOREGROUND = {
  'fg-brand-primary': BRAND['brand-600'],
  'fg-brand-primary-alt': BRAND['brand-600'],
  'fg-brand-secondary': BRAND['brand-500'],
  'fg-disabled': LIGHT_GRAY['gray-400'],
  'fg-disabled-subtle': LIGHT_GRAY['gray-300'],
  'fg-error-primary': ERROR['error-600'],
  'fg-error-secondary': ERROR['error-500'],
  'fg-primary': LIGHT_GRAY['gray-900'],
  'fg-quaternary': LIGHT_GRAY['gray-500'],
  'fg-quaternary-hover': LIGHT_GRAY['gray-600'],
  'fg-quinary': LIGHT_GRAY['gray-400'],
  'fg-quinary-hover': LIGHT_GRAY['gray-500'],
  'fg-secondary': LIGHT_GRAY['gray-700'],
  'fg-secondary-hover': LIGHT_GRAY['gray-800'],
  'fg-senary': LIGHT_GRAY['gray-300'],
  'fg-success-primary': SUCCESS['success-600'],
  'fg-success-secondary': SUCCESS['success-500'],
  'fg-tertiary': LIGHT_GRAY['gray-600'],
  'fg-tertiary-hover': LIGHT_GRAY['gray-700'],
  'fg-warning-primary': WARNING['warning-600'],
  'fg-warning-secondary': WARNING['warning-500'],
  'fg-white': COLORS.white,
};

export const LIGHT_BORDER = {
  'border-brand': BRAND['brand-500'],
  'border-brand-alt': BRAND['brand-600'],
  'border-disabled': LIGHT_GRAY['gray-300'],
  'border-disabled-subtle': LIGHT_GRAY['gray-200'],
  'border-error': ERROR['error-500'],
  'border-error-subtle': ERROR['error-300'],
  'border-primary': LIGHT_GRAY['gray-300'],
  'border-secondary': LIGHT_GRAY['gray-200'],
  'border-tertiary': LIGHT_GRAY['gray-100'],
};

export const LIGHT_BACKGROUND = {
  'bg-active': LIGHT_GRAY['gray-25'],
  'bg-brand-primary': BRAND['brand-50'],
  'bg-brand-primary-alt': BRAND['brand-50'],
  'bg-brand-secondary': BRAND['brand-100'],
  'bg-brand-section': BRAND['brand-800'],
  'bg-brand-section-subtle': BRAND['brand-700'],
  'bg-brand-solid': BRAND['brand-600'],
  'bg-brand-solid-hover': BRAND['brand-700'],
  'bg-disabled': LIGHT_GRAY['gray-100'],
  'bg-disabled-subtle': LIGHT_GRAY['gray-50'],
  'bg-error-primary': ERROR['error-50'],
  'bg-error-secondary': ERROR['error-100'],
  'bg-error-solid': ERROR['error-600'],
  'bg-overlay': LIGHT_GRAY['gray-950'],
  'bg-primary': COLORS.white,
  'bg-primary-solid': LIGHT_GRAY['gray-950'],
  'bg-primary-alt': COLORS.white,
  'bg-primary-hover': LIGHT_GRAY['gray-50'],
  'bg-quaternary': LIGHT_GRAY['gray-200'],
  'bg-secondary': LIGHT_GRAY['gray-50'],
  'bg-secondary-solid': LIGHT_GRAY['gray-600'],
  'bg-secondary-alt': LIGHT_GRAY['gray-50'],
  'bg-secondary-hover': LIGHT_GRAY['gray-100'],
  'bg-secondary-subtle': LIGHT_GRAY['gray-25'],
  'bg-success-primary': SUCCESS['success-50'],
  'bg-success-secondary': SUCCESS['success-100'],
  'bg-success-solid': SUCCESS['success-600'],
  'bg-tertiary': LIGHT_GRAY['gray-100'],
  'bg-warning-primary': WARNING['warning-50'],
  'bg-warning-secondary': WARNING['warning-100'],
  'bg-warning-solid': WARNING['warning-600'],
};

export const LIGHT_NAV = {
  'nav-item-bg': COLORS.white,
  'nav-item-bg-primary-active': BRAND['brand-500'],
  'nav-item-bg-primary-active-hover': BRAND['brand-600'],
  'nav-item-bg-secondary-active': BRAND['brand-50'],
  'nav-item-bg-secondary-active-hover': BRAND['brand-100'],
  'nav-item-bg-hover': LIGHT_GRAY['gray-50'],
  'nav-item-button-primary-icon-fg': LIGHT_GRAY['gray-500'],
  'nav-item-button-primary-icon-fg-active': COLORS.white,
  'nav-item-icon-fg': LIGHT_GRAY['gray-700'],
  'nav-item-icon-fg-primary-active': COLORS.white,
  'nav-item-icon-fg-primary-active-hover': LIGHT_GRAY['gray-500'],
  'nav-item-icon-fg-hover': LIGHT_GRAY['gray-700'],
  'nav-item-text': LIGHT_GRAY['gray-700'],
  'nav-item-text-primary-active': COLORS.white,
  'nav-item-text-primary-active-hover': COLORS.white,
  'nav-item-text-secondary-active': BRAND['brand-700'],
  'nav-item-text-secondary-active-hover': BRAND['brand-600'],
  'nav-item-text-hover': LIGHT_GRAY['gray-800'],
};

export const LIGHT_AVATAR = {
  'avatar-bg': LIGHT_GRAY['gray-100'],
  'avatar-contrast-border': '#00000014',
  'avatar-profile-photo-border': COLORS.white,
  'avatar-styles-bg-neutral': '#e0e0e0',
};

export const LIGHT_BREADCRUMB = {
  'breadcrumb-bg-hover': LIGHT_GRAY['gray-50'],
  'breadcrumb-brand-bg-hover': BRAND['brand-50'],
  'breadcrumb-brand-fg-hover': BRAND['brand-700'],
  'breadcrumb-brand-icon-fg-hover': BRAND['brand-700'],
  'breadcrumb-fg': LIGHT_GRAY['gray-600'],
  'breadcrumb-fg-hover': LIGHT_GRAY['gray-700'],
  'breadcrumb-icon-fg': LIGHT_GRAY['gray-500'],
  'breadcrumb-icon-fg-hover': LIGHT_GRAY['gray-700'],
};

export const LIGHT_BUTTON_PRIMARY = {
  'button-primary-bg': BRAND['brand-600'],
  'button-primary-bg-hover': BRAND['brand-700'],
  'button-primary-fg': COLORS.white,
  'button-primary-fg-hover': COLORS.white,
  'button-primary-error-bg': ERROR['error-600'],
  'button-primary-error-bg-hover': ERROR['error-700'],
  'button-primary-error-fg': COLORS.white,
  'button-primary-error-fg-hover': COLORS.white,
};

export const LIGHT_BUTTON_SECONDARY = {
  'button-secondary-bg': COLORS.white,
  'button-secondary-bg-hover': LIGHT_GRAY['gray-50'],
  'button-secondary-border': LIGHT_GRAY['gray-300'],
  'button-secondary-border-hover': LIGHT_GRAY['gray-300'],
  'button-secondary-fg': LIGHT_GRAY['gray-700'],
  'button-secondary-fg-hover': LIGHT_GRAY['gray-800'],
  'button-secondary-color-bg': COLORS.white,
  'button-secondary-color-bg-hover': BRAND['brand-50'],
  'button-secondary-color-border': BRAND['brand-300'],
  'button-secondary-color-border-hover': BRAND['brand-300'],
  'button-secondary-color-fg': BRAND['brand-700'],
  'button-secondary-color-fg-hover': BRAND['brand-800'],
  'button-secondary-error-bg': COLORS.white,
  'button-secondary-error-bg-hover': ERROR['error-50'],
  'button-secondary-error-border': ERROR['error-300'],
  'button-secondary-error-border-hover': ERROR['error-300'],
  'button-secondary-error-fg': ERROR['error-700'],
  'button-secondary-error-fg-hover': ERROR['error-800'],
};

export const LIGHT_BUTTON_TERTIARY = {
  'button-tertiary-color-bg-hover': BRAND['brand-50'],
  'button-tertiary-color-fg': BRAND['brand-700'],
  'button-tertiary-color-fg-hover': BRAND['brand-800'],
  'button-tertiary-error-bg-hover': ERROR['error-50'],
  'button-tertiary-error-fg': ERROR['error-700'],
  'button-tertiary-error-fg-hover': ERROR['error-800'],
  'button-tertiary-bg-hover': LIGHT_GRAY['gray-50'],
  'button-tertiary-fg': LIGHT_GRAY['gray-600'],
  'button-tertiary-fg-hover': LIGHT_GRAY['gray-700'],
};

export const LIGHT_FOOTER_BADGE = {
  'footer-badge-bg': SUCCESS['success-50'],
  'footer-badge-border': SUCCESS['success-200'],
  'footer-badge-fg': SUCCESS['success-700'],
  'footer-button-fg': BRAND['brand-200'],
  'footer-button-fg-hover': COLORS.white,
};

export const LIGHT_HEADER_ABSTRACT = {
  'header-abstract-100-bg': BRAND['brand-100'],
  'header-abstract-200-bg': BRAND['brand-200'],
  'header-abstract-300-bg': BRAND['brand-300'],
  'header-abstract-50-bg': BRAND['brand-50'],
};

export const LIGHT_ICON = {
  'icon-dark-fg-brand': COLORS.white,
  'icon-dark-fg-error': COLORS.white,
  'icon-dark-fg-gray': COLORS.white,
  'icon-dark-fg-success': COLORS.white,
  'icon-dark-fg-warning': COLORS.white,
  'icon-light-fg-brand': BRAND['brand-600'],
  'icon-light-fg-error': ERROR['error-600'],
  'icon-light-fg-gray': LIGHT_GRAY['gray-500'],
  'icon-light-fg-success': SUCCESS['success-600'],
  'icon-light-fg-warning': WARNING['warning-600'],
  'icon-modern-border': LIGHT_GRAY['gray-200'],
  'icon-fg-brand': BRAND['brand-600'],
  'icon-fg-brand-on-brand': BRAND['brand-200'],
};

export const LIGHT_TOGGLE = {
  'toggle-border': LIGHT_GRAY['gray-300'],
  'toggle-button-fg-disabled': LIGHT_GRAY['gray-50'],
  'toggle-slim-border-pressed': BRAND['brand-600'],
  'toggle-slim-border-pressed-hover': BRAND['brand-700'],
};

export const LIGHT_SOCIAL_ICON = {
  'social-icon-fg-angellist': COLORS.black,
  'social-icon-fg-apple': COLORS.black,
  'social-icon-fg-github': COLORS.black,
  'social-icon-fg-instagram': '#000100',
  'social-icon-fg-tumblr': '#001935',
  'social-icon-fg-x': '#242e36',
};
export const LIGHT_SLIDER = {
  'slider-handle-bg': COLORS.white,
  'slider-handle-border': BRAND['brand-600'],
};

export const LIGHT_THUMBNAIL = {
  'thumbnail-badge-brand-fg': BRAND['brand-700'],
  'thumbnail-badge-success-fg': SUCCESS['success-700'],
};

export const LIGHT_EDITOR = {
  'editor-wysiwyg-editor-icon-fg': LIGHT_GRAY['gray-400'],
  'editor-wysiwyg-editor-icon-fg-active': LIGHT_GRAY['gray-500'],
};

export const LIGHT_UTILITY_YELLOW = {
  'utility-yellow-100': YELLOW['yellow-100'],
  'utility-yellow-200': YELLOW['yellow-200'],
  'utility-yellow-300': YELLOW['yellow-300'],
  'utility-yellow-400': YELLOW['yellow-400'],
  'utility-yellow-50': YELLOW['yellow-50'],
  'utility-yellow-500': YELLOW['yellow-500'],
  'utility-yellow-600': YELLOW['yellow-600'],
  'utility-yellow-700': YELLOW['yellow-700'],
};

export const LIGHT_UTILITY_BRAND = {
  'utility-brand-100': BRAND['brand-100'],
  'utility-brand-100-alt': BRAND['brand-100'],
  'utility-brand-200': BRAND['brand-200'],
  'utility-brand-200-alt': BRAND['brand-200'],
  'utility-brand-300': BRAND['brand-300'],
  'utility-brand-300-alt': BRAND['brand-300'],
  'utility-brand-400': BRAND['brand-400'],
  'utility-brand-400-alt': BRAND['brand-400'],
  'utility-brand-50': BRAND['brand-50'],
  'utility-brand-500': BRAND['brand-500'],
  'utility-brand-500-alt': BRAND['brand-500'],
  'utility-brand-50-alt': BRAND['brand-50'],
  'utility-brand-600': BRAND['brand-600'],
  'utility-brand-600-alt': BRAND['brand-600'],
  'utility-brand-700': BRAND['brand-700'],
  'utility-brand-700-alt': BRAND['brand-700'],
  'utility-brand-800': BRAND['brand-800'],
  'utility-brand-800-alt': BRAND['brand-800'],
  'utility-brand-900': BRAND['brand-900'],
  'utility-brand-900-alt': BRAND['brand-900'],
};

export const LIGHT_UTILITY_BLUE_LIGHT = {
  'utility-blue-light-100': BLUE_LIGHT['blue-light-100'],
  'utility-blue-light-200': BLUE_LIGHT['blue-light-200'],
  'utility-blue-light-300': BLUE_LIGHT['blue-light-300'],
  'utility-blue-light-400': BLUE_LIGHT['blue-light-400'],
  'utility-blue-light-50': BLUE_LIGHT['blue-light-50'],
  'utility-blue-light-500': BLUE_LIGHT['blue-light-500'],
  'utility-blue-light-600': BLUE_LIGHT['blue-light-600'],
  'utility-blue-light-700': BLUE_LIGHT['blue-light-700'],
};

export const LIGHT_UTILITY_BLUE = {
  'utility-blue-100': BLUE['blue-100'],
  'utility-blue-200': BLUE['blue-200'],
  'utility-blue-300': BLUE['blue-300'],
  'utility-blue-400': BLUE['blue-400'],
  'utility-blue-50': BLUE['blue-50'],
  'utility-blue-500': BLUE['blue-500'],
  'utility-blue-600': BLUE['blue-600'],
  'utility-blue-700': BLUE['blue-700'],
};

export const LIGHT_UTILITY_BLUE_DARK = {
  'utility-blue-dark-100': BLUE_DARK['blue-dark-100'],
  'utility-blue-dark-200': BLUE_DARK['blue-dark-200'],
  'utility-blue-dark-300': BLUE_DARK['blue-dark-300'],
  'utility-blue-dark-400': BLUE_DARK['blue-dark-400'],
  'utility-blue-dark-50': BLUE_DARK['blue-dark-50'],
  'utility-blue-dark-500': BLUE_DARK['blue-dark-500'],
  'utility-blue-dark-600': BLUE_DARK['blue-dark-600'],
  'utility-blue-dark-700': BLUE_DARK['blue-dark-700'],
};

export const LIGHT_UTILITY_ERROR = {
  'utility-error-100': ERROR['error-100'],
  'utility-error-200': ERROR['error-200'],
  'utility-error-300': ERROR['error-300'],
  'utility-error-400': ERROR['error-400'],
  'utility-error-50': ERROR['error-50'],
  'utility-error-500': ERROR['error-500'],
  'utility-error-600': ERROR['error-600'],
  'utility-error-700': ERROR['error-700'],
};

export const LIGHT_UTILITY_FUCHSIA = {
  'utility-fuchsia-100': FUCHSIA['fuchsia-100'],
  'utility-fuchsia-200': FUCHSIA['fuchsia-200'],
  'utility-fuchsia-300': FUCHSIA['fuchsia-300'],
  'utility-fuchsia-400': FUCHSIA['fuchsia-400'],
  'utility-fuchsia-50': FUCHSIA['fuchsia-50'],
  'utility-fuchsia-500': FUCHSIA['fuchsia-500'],
  'utility-fuchsia-600': FUCHSIA['fuchsia-600'],
  'utility-fuchsia-700': FUCHSIA['fuchsia-700'],
};

export const LIGHT_UTILITY_GRAY_BLUE = {
  'utility-gray-blue-100': GRAY_BLUE['gray-blue-100'],
  'utility-gray-blue-200': GRAY_BLUE['gray-blue-200'],
  'utility-gray-blue-300': GRAY_BLUE['gray-blue-300'],
  'utility-gray-blue-400': GRAY_BLUE['gray-blue-400'],
  'utility-gray-blue-50': GRAY_BLUE['gray-blue-50'],
  'utility-gray-blue-500': GRAY_BLUE['gray-blue-500'],
  'utility-gray-blue-600': GRAY_BLUE['gray-blue-600'],
  'utility-gray-blue-700': GRAY_BLUE['gray-blue-700'],
};

export const LIGHT_UTILITY_LIGHT_GRAY = {
  'utility-gray-100': LIGHT_GRAY['gray-100'],
  'utility-gray-200': LIGHT_GRAY['gray-200'],
  'utility-gray-300': LIGHT_GRAY['gray-300'],
  'utility-gray-400': LIGHT_GRAY['gray-400'],
  'utility-gray-50': LIGHT_GRAY['gray-50'],
  'utility-gray-500': LIGHT_GRAY['gray-500'],
  'utility-gray-600': LIGHT_GRAY['gray-600'],
  'utility-gray-700': LIGHT_GRAY['gray-700'],
  'utility-gray-800': LIGHT_GRAY['gray-800'],
  'utility-gray-900': LIGHT_GRAY['gray-900'],
};

export const LIGHT_UTILITY_GREEN = {
  'utility-green-100': GREEN['green-100'],
  'utility-green-200': GREEN['green-200'],
  'utility-green-300': GREEN['green-300'],
  'utility-green-400': GREEN['green-400'],
  'utility-green-50': GREEN['green-50'],
  'utility-green-500': GREEN['green-500'],
  'utility-green-600': GREEN['green-600'],
  'utility-green-700': GREEN['green-700'],
};

export const LIGHT_UTILITY_INDIGO = {
  'utility-indigo-100': INDIGO['indigo-100'],
  'utility-indigo-200': INDIGO['indigo-200'],
  'utility-indigo-300': INDIGO['indigo-300'],
  'utility-indigo-400': INDIGO['indigo-400'],
  'utility-indigo-50': INDIGO['indigo-50'],
  'utility-indigo-500': INDIGO['indigo-500'],
  'utility-indigo-600': INDIGO['indigo-600'],
  'utility-indigo-700': INDIGO['indigo-700'],
};

export const LIGHT_UTILITY_ORANGE = {
  'utility-orange-100': ORANGE['orange-100'],
  'utility-orange-200': ORANGE['orange-200'],
  'utility-orange-300': ORANGE['orange-300'],
  'utility-orange-400': ORANGE['orange-400'],
  'utility-orange-50': ORANGE['orange-50'],
  'utility-orange-500': ORANGE['orange-500'],
  'utility-orange-600': ORANGE['orange-600'],
  'utility-orange-700': ORANGE['orange-700'],
};

export const LIGHT_UTILITY_ORANGE_DARK = {
  'utility-orange-dark-100': ORANGE_DARK['orange-dark-100'],
  'utility-orange-dark-200': ORANGE_DARK['orange-dark-200'],
  'utility-orange-dark-300': ORANGE_DARK['orange-dark-300'],
  'utility-orange-dark-400': ORANGE_DARK['orange-dark-400'],
  'utility-orange-dark-50': ORANGE_DARK['orange-dark-50'],
  'utility-orange-dark-500': ORANGE_DARK['orange-dark-500'],
  'utility-orange-dark-600': ORANGE_DARK['orange-dark-600'],
  'utility-orange-dark-700': ORANGE_DARK['orange-dark-700'],
};

export const LIGHT_UTILITY_PINK = {
  'utility-pink-100': PINK['pink-100'],
  'utility-pink-200': PINK['pink-200'],
  'utility-pink-300': PINK['pink-300'],
  'utility-pink-400': PINK['pink-400'],
  'utility-pink-50': PINK['pink-50'],
  'utility-pink-500': PINK['pink-500'],
  'utility-pink-600': PINK['pink-600'],
  'utility-pink-700': PINK['pink-700'],
};

export const LIGHT_UTILITY_PURPLE = {
  'utility-purple-100': PURPLE['purple-100'],
  'utility-purple-200': PURPLE['purple-200'],
  'utility-purple-300': PURPLE['purple-300'],
  'utility-purple-400': PURPLE['purple-400'],
  'utility-purple-50': PURPLE['purple-50'],
  'utility-purple-500': PURPLE['purple-500'],
  'utility-purple-600': PURPLE['purple-600'],
  'utility-purple-700': PURPLE['purple-700'],
};
export const LIGHT_UTILITY_WARNING = {
  'utility-warning-100': WARNING['warning-100'],
  'utility-warning-200': WARNING['warning-200'],
  'utility-warning-300': WARNING['warning-300'],
  'utility-warning-400': WARNING['warning-400'],
  'utility-warning-50': WARNING['warning-50'],
  'utility-warning-500': WARNING['warning-500'],
  'utility-warning-600': WARNING['warning-600'],
  'utility-warning-700': WARNING['warning-700'],
};

export const LIGHT_UTILITY_SUCCESS = {
  'utility-success-100': SUCCESS['success-100'],
  'utility-success-200': SUCCESS['success-200'],
  'utility-success-300': SUCCESS['success-300'],
  'utility-success-400': SUCCESS['success-400'],
  'utility-success-50': SUCCESS['success-50'],
  'utility-success-500': SUCCESS['success-500'],
  'utility-success-600': SUCCESS['success-600'],
  'utility-success-700': SUCCESS['success-700'],
};
