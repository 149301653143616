import React from 'react';

import {Option} from '../../../types';
import {IconView} from '../../IconView';
import {Input} from '../../Input';
import {MultiSelectProps} from '../types';

import {BadgesContainer} from './BadgesContainer';

export type TriggerProps = MultiSelectProps & {
  showSelectList: boolean;
  selectedValues: any[];
  onChangeSelection: (value: Option) => void;
  onReset: () => void;
  setShowSelectList: React.Dispatch<React.SetStateAction<boolean>>;
  setTriggerWidth: React.Dispatch<React.SetStateAction<number | null>>;
};

export function Trigger({
  showSelectList,
  selectedValues,
  width,
  placeholder,
  value,
  prefix,
  subtext,
  label,
  badgesMode,
  triggerInputStyles,
  onReset,
  onChangeSelection,
  setShowSelectList,
  setTriggerWidth,
}: TriggerProps) {
  if (badgesMode) {
    return (
      <BadgesContainer
        label={label}
        width={width}
        selectedValues={selectedValues}
        onChangeSelection={onChangeSelection}
        onReset={onReset}
        setTriggerWidth={setTriggerWidth}
        triggerInputStyles={triggerInputStyles}
      />
    );
  }

  return (
    <Input
      width={width}
      suffix={<IconView size={20} icon="ArrowDown01Icon" type="standard" />}
      placeholder={placeholder}
      value={value}
      flex={1}
      prefix={prefix}
      onPress={() => setShowSelectList(!showSelectList)}
      selectTextOnFocus={false}
      editable={false}
      subtext={subtext}
      cursor="pointer"
      onLayout={(e) => setTriggerWidth(e.nativeEvent.layout.width)}
      style={triggerInputStyles}
    />
  );
}
