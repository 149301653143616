import {daysToMillisecond} from '../../utils/daysToMillisecond';

interface Props {
  dueDate: any;
  overdueHighlightEnabled?: boolean;
  noOfOverdueDaysToWarn?: number;
  noOfOverdueDaysToError?: number;
}

export function getOverdueHighlightColor({
  dueDate,
  overdueHighlightEnabled,
  noOfOverdueDaysToWarn,
  noOfOverdueDaysToError,
}: Props) {
  if (overdueHighlightEnabled && dueDate) {
    const now = new Date().getTime();
    const _dueDate = new Date(dueDate).getTime();

    if (now > _dueDate) {
      return 'error.500';
    }

    if (noOfOverdueDaysToError !== undefined) {
      const errorLimit = daysToMillisecond(noOfOverdueDaysToError);
      if (_dueDate - now < errorLimit) {
        return 'error.500';
      }
    }

    if (noOfOverdueDaysToWarn !== undefined) {
      const warnLimit = daysToMillisecond(noOfOverdueDaysToWarn);
      if (_dueDate - now < warnLimit) {
        return 'orange.500';
      }
    }
  }

  return 'green.500';
}
