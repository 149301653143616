import {AlertStatusConfig} from '../types';

export const AlertTypeConfig: AlertStatusConfig = {
  info: {
    color: '$text-brand-tertiary',
    icon: 'InformationCircleIcon',
    backgroundColor: '$bg-tertiary',
    lightColor: '$blue-50',
  },
  success: {
    color: '$text-success-primary',
    icon: 'Tick02Icon',
    backgroundColor: '$bg-success-secondary',
    lightColor: '$bg-success-primary',
  },
  danger: {
    color: '$text-error-primary',
    icon: 'Alert02Icon',
    backgroundColor: '$bg-error-secondary',
    lightColor: '$bg-error-primary',
  },
  warning: {
    color: '$text-warning-primary',
    icon: 'Alert01Icon',
    backgroundColor: '$bg-warning-secondary',
    lightColor: '$bg-warning-primary',
  },
};
