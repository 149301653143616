import React from 'react';

import {Switch as BasSwitch, SizeTokens} from 'tamagui';

import {Spinner} from '../Spinner';

export type SwitchProps = {
  name?: string;
  checked?: boolean;
  onCheckedChange?: (checked: boolean) => void;
  required?: boolean;
  isLoading?: boolean;
  size?: SizeTokens;
};

export function Switch({isLoading, ...props}: SwitchProps) {
  return (
    <BasSwitch size="$1.5" {...props} direction="ltr" backgroundColor={props.checked ? '$accent' : '$bg-quaternary'}>
      {isLoading ? (
        <Spinner color={props.checked ? '$white' : '$accent'} />
      ) : (
        <BasSwitch.Thumb
          direction="ltr"
          animation="quick"
          backgroundColor={props.checked ? '$bg-quaternary' : '$fg-quaternary'}
        />
      )}
    </BasSwitch>
  );
}
