import {useQuery} from 'react-query';

import {ScopeLevel, State} from '../../../types';
import {parseValues} from '../../ContentElements/elements/meta/Condition/utils/parseValues';

import {useAPI} from './../../../services/api/useAPI';

export const useGetGlobalVariables = (search = '') => {
  const api = useAPI();
  const {data, ...rest} = useQuery(['api-builder/global-variables/variables', search], () =>
    api.get(`api-builder/global-variables/variables?name=${search}`).json<{items: State[]; totalRecords: number}>(),
  );

  const states: Record<string, State[]> = {};

  data?.items.forEach((v) => {
    const variable = {...v, level: ScopeLevel.app};
    states[variable.scopeId] = states[variable.scopeId] || [];
    states[variable.scopeId].push(parseValues(variable));
  });

  return {states, ...rest};
};
