import {AppContentPage, Block, isAppContentPage} from '../../../types';
import {designSpec} from '../elements';

function migrateBlock(block: Block): Block {
  const blockDef = designSpec.blockDefinition[block.definitionId];

  if (block.children && block.children.length > 0) {
    const updatedBlock = {
      ...block,
      children: block.children.map(migrateBlock),
    };

    return updatedBlock;
  }

  if (blockDef && blockDef.migrate && block.definitionVersion < blockDef.version) {
    return blockDef.migrate(block) || block;
  }

  return block;
}

export function migratePage(page: AppContentPage): AppContentPage {
  const blockDef = designSpec.blockDefinition[page.definitionId];
  const targetPage = blockDef?.migrate?.(page);
  const migratedPage = targetPage && isAppContentPage(targetPage) ? targetPage : page;

  const updatedPage = {
    ...migratedPage,
    children: migratedPage.children?.map?.(migrateBlock),
  };

  return updatedPage;
}
