import React, {ReactNode} from 'react';

import {Button} from '../../../Button';
import {IconView} from '../../../IconView';
import {Typography} from '../../../Typography';
import {ViewProps} from '../../../View';
import {XStack} from '../../../XStack';
import {YStack} from '../../../YStack';

type EditElementSectionProps = ViewProps & {
  title: ReactNode;
  onReset?: () => void;
  children?: ReactNode;
  action?: ReactNode;
};

export const EditSectionElement = ({title, onReset, children, action, ...props}: EditElementSectionProps) => {
  return (
    <YStack gap="$1" {...props} padding={0}>
      <XStack alignItems="center" justifyContent="space-between" flex={1} minHeight="$4">
        <Typography fontWeight="600">{title}</Typography>
        <XStack alignItems="center" gap="$1">
          {action}
          {onReset && <Button hierarchy="tertiary-gray" onPress={onReset} prefix={<IconView icon="ReloadIcon" />} />}
        </XStack>
      </XStack>
      {children}
    </YStack>
  );
};
