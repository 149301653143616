import React, {ReactNode} from 'react';

import {IconView} from '../IconView';
import {CoreTrans} from '../ScopedTrans';
import {Typography} from '../Typography';
import {View} from '../View';

interface state {
  hasError: boolean;
}

interface Props {
  fallback?: ReactNode;
  children: ReactNode;
}

export class ErrorBoundary extends React.Component<Props, state> {
  constructor(props: Props) {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  componentDidCatch(error, info) {
    // eslint-disable-next-line no-console
    console.log(error, info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback || <DefaultFallback />;
    }

    return this.props.children;
  }
}

const DefaultFallback = () => (
  <View width="100%" height="100%" backgroundColor="$bg-secondary" alignItems="center" justifyContent="center">
    <IconView icon="TorriGateIcon" size={44} color="$bg-error-solid" />
    <Typography margin="$0.5" color="$bg-error-solid" fontWeight="700">
      <CoreTrans i18nKey="generalErrorMsg" />
    </Typography>
  </View>
);
