import {GLOBAL_SCOPE_KEY, STATE_PATTERN, STATE_SYNTAX} from '../../../config';
import {State} from '../../../types';
import {useStateContext} from '../../StateProvider';

export const useStateResolver = (editMode = false) => {
  const context = useStateContext();
  const allVariables = context?.state;
  const getVariableValue = (scopeId: string, varName: string): State['value'] => {
    const effectiveScopeId = scopeId || GLOBAL_SCOPE_KEY;
    const scopeVariables = allVariables?.[effectiveScopeId];
    const notFoundText = `${STATE_SYNTAX.PREFIX}${
      effectiveScopeId === GLOBAL_SCOPE_KEY ? '' : effectiveScopeId + '.'
    }${varName}${STATE_SYNTAX.SUFFIX}`;

    if (!scopeVariables) return notFoundText;

    const variable = scopeVariables.find((v) => v.name === varName);

    return variable ? variable.value : notFoundText;
  };

  const resolveValue = (value: unknown): unknown => {
    if (typeof value === 'string') {
      if (isValidStateSyntax(value.trim())) {
        const content = value.slice(STATE_SYNTAX.PREFIX.length, -STATE_SYNTAX.SUFFIX.length).trim();

        const parts = content.split('.');
        const variableName = parts.pop() || '';
        const scopeId = parts.length > 0 ? parts.join('.') : GLOBAL_SCOPE_KEY;

        return getVariableValue(scopeId, variableName);
      }

      return value.replace(STATE_PATTERN, (_, scopeIdOrVarName, varName) => {
        const scopeId = varName ? scopeIdOrVarName : GLOBAL_SCOPE_KEY;
        const variableName = varName || scopeIdOrVarName;
        if (!editMode) {
          return String(getVariableValue(scopeId, variableName));
        } else {
          return `${STATE_SYNTAX.PREFIX}${scopeId}.${variableName}${STATE_SYNTAX.SUFFIX}`;
        }
      });
    } else if (Array.isArray(value)) {
      return value.map(resolveValue);
    } else if (typeof value === 'object' && value !== null) {
      return Object.keys(value).reduce((acc, key) => {
        acc[key] = resolveValue(value[key]);
        return acc;
      }, {} as Record<string, unknown>);
    }
    return value;
  };

  const resolve = <T>(props: T): T => {
    if (typeof props === 'string' || Array.isArray(props)) {
      return resolveValue(props) as T;
    } else if (typeof props === 'object' && props !== null) {
      return Object.keys(props).reduce((acc, key) => {
        acc[key] = resolveValue(props[key]);
        return acc;
      }, {} as T);
    }
    return props;
  };

  return {resolve};
};

const isValidStateSyntax = (value: string): boolean => {
  const firstTwoChars = value.slice(0, 2);
  const lastTwoChars = value.slice(-2);
  return firstTwoChars === STATE_SYNTAX.PREFIX && lastTwoChars === STATE_SYNTAX.SUFFIX;
};
